import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Slide, ThemeProvider } from "@mui/material";
import App from "./App";
import BettermapsSnackbarProvider from "./components/common/BettermapsSnackbarProvider";
import Background from "./images/alyani-yang-wajet.jpg";
import { MapProvider } from "react-map-gl";
import { Store } from "./redux/store";
import { Provider } from "react-redux";
import { Auth } from "aws-amplify";
import { countInfo } from "./services/StripeService";
import { useEffect, useState } from "react";
import { getIPAddress } from "./helpers/getIPAddress";
import { useDispatch } from "react-redux";

export default function AppWrapper(props) {
	const { route } = useAuthenticator((context) => [context.route]);
	const components = {
		Header() {
			return (
				<div
					style={{
						paddingTop: "20px",
						display: "flex",
						justifyContent: "center",
						backgroundColor: "white",
						borderRadius: "10px 10px 0 0",
					}}>
					<img
						src="https://bettermaps-branding.s3.eu-central-1.amazonaws.com/bettermapGreen%403x.png"
						alt="Bettermaps Logo"
						style={{ width: "300px" }}
					/>
				</div>
			);
		},
	};

	route ??
		useEffect(() => {
			const authenticatedUser = async () => {
				try {
					const user = await Auth.currentAuthenticatedUser();
					if (user.attributes.email) {
						const c = await countInfo(user.attributes.email);
						const ip = await getIPAddress();
						sendUserData(user, c, ip);
					}
				} catch (error) {
					console.log("Error:", error);
				}
			};

			const sendUserData = (user, c, ip) => {
				try {
					if (user.username && user.attributes.email && c.plan) {
						analytics.identify("user info", {
							id: user.username,
							ip: ip,
							email: user.attributes.email,
							subscription: c.plan,
						});
					}
				} catch (error) {
					console.log("Error:", error);
				}
			};
			authenticatedUser();
		}, [route]);


	return route === "authenticated" ? (
		<ThemeProvider theme={props.theme}>
			<BettermapsSnackbarProvider TransitionComponent={Slide} maxSnack={5}>
				<MapProvider>
					<Provider store={Store}>
						<App />
					</Provider>
				</MapProvider>
			</BettermapsSnackbarProvider>
		</ThemeProvider>
	) : (
		<div
			data-testid="bettermaps_id"
			style={{
				width: "100vw",
				height: "100vh",
				backgroundImage: `url(${Background})`,
				backgroundSize: "cover",
			}}>
			<Authenticator
				variation="modal"
				components={components}
				hideSignUp={process.env.REACT_APP_ENV !== "prod"}
			/>
		</div>
	);
}
