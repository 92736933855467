import { Box, Container, Grid, Typography } from "@mui/material";
import MainContainer from "../components/common/Grid/MainContainer";
import ToolsetCard from "../components/Toolset/ToolsetCard";

export default function ToolsetPage() {
  const toolsProps = [
    {
      name: "Extract bounding box from image",
      onClick: "/analyse-paper-map-image",
    },
    { name: "Create maps using AI", onClick: "ai-creator" },
    { name: "Get admin unit", onClick: "/admin-unit" },
    { name: "This is a experimental tool", onClick: "" },
    { name: "This is a experimental tool", onClick: "" },
    { name: "This is a experimental tool", onClick: "" },
  ];

  return (
    <MainContainer
      content={
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={3}>
            <Box sx={{ py: 2, width: "100%", alignItems: "center" }}>
              <Typography variant="h2">Experimental tools</Typography>
            </Box>
          </Grid>
          <Container sx={{ py: 2 }} maxWidth="lg">
            <Grid container spacing={2}>
              {toolsProps.map((tool, index) => (
                <Grid item key={index} xs={2} sm={3} md={3}>
                  <ToolsetCard toolName={tool.name} onClick={tool.onClick} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Grid>
      }
    />
  );
}
