import MapIcon from '@mui/icons-material/Map';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pages as cards } from '../lib/pages';
import BettermapsTextField from '../components/common/BettermapsTextField';
import ConfirmMapIntentModal from '../components/common/CreateMap/modals/ConfirmMapIntentModal';
import MainContainer from '../components/common/Grid/MainContainer';
import DashBoardCard from '../components/Dashboard/DashboardCard';
import {
	sendLogForAi,
	createMapIntentFromDescription,
	createMapFromIntent,
} from '../services/AIMapsService';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as randomID } from 'uuid';
import { setBoundingBox } from '../redux/slices/boundingBox';
import { getStyleId, setStyleName, setMapStyle } from '../redux/slices/style';
import { resetLayers, setReverseLayer, addLayer } from '../redux/slices/layers';
import { getLayerFeatures } from '../services/DatasourcesService';
import MapBoxSource from '../components/common/MapBox/MapBoxSource';
import { LayerType } from '../lib/LayerType';
import { LayerFileType } from '../lib/LayerFileType';
import { getUserStyle } from '../services/DatasourcesService';
import { setPlanInfo } from '../redux/slices/user';
import { countInfo } from '../services/StripeService';

export default function DashboardPage() {
	const dispatch = useDispatch();
	const { email } = useSelector((state) => state.user);
	const [mapDescription, setMapDescription] = useState("");
	const history = useHistory();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState(false);
	const [showMapIntentModal, setShowMapIntentModal] = useState(false);
	const [mapIntent, setMapIntent] = useState();
	const { subscription } = useSelector((state) => state.user);
	// const [boundingBox, setBoundingBox] = useState();
	// const [styleId, setStyleId] = useState();
	const [ownerId, setOwnerId] = useState();
	const [generatedName, setGeneratedName] = useState("");
	// const [url, setUrl] = useState("");

	const userData = useSelector((state) => state.user);

	useEffect(() => {
		const checkSubscription = async () => {
			if (subscription) {
				if (
					subscription !== "standard" &&
					subscription !== "explorer" &&
					subscription !== "pro"
				) {
					enqueueSnackbar(
						"To use Bettermaps you need to choose a subscription plan",
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "error",
						}
					);
					history.push("/subscriptions");
				}
			} else {
				try {
					const user = await Auth.currentAuthenticatedUser();
					const count = await countInfo(user.attributes.email);
					if (
						count.plan === "standard" ||
						count.plan === "explorer" ||
						count.plan === "pro"
					) {
						dispatch(setPlanInfo(count));
						// history.push('/dashboard');
					} else {
						history.push("/subscriptions");
					}
				} catch (e) {
					console.error(e);
					enqueueSnackbar("Error occurred. Please try again later.", {
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					});
				}
			}
		};
		checkSubscription();
	}, []);

const handleCreateIntent = async () => {
	kindOfSearch();
	setIsLoading(true);

	try {
		if (!ownerId) {
			const ownerId = (await Auth.currentAuthenticatedUser()).username;
			setOwnerId(ownerId);
		} else {
			setOwnerId(ownerId);
		}

		const d = await createMapIntentFromDescription(
			mapDescription,
			ownerId,
			email
		);

		if (d.mapIntent) {
			setMapIntent(JSON.parse(d.mapIntent));
			window.analytics.track("sentIntent.click", { mapIntent });
			setGeneratedName(
				"Map of " + JSON.parse(d.mapIntent).CREATE + " AI generated"
			);
			setShowMapIntentModal(true);
		} else if (d == 403) {
			enqueueSnackbar(
				"You have run out of intents with Cass. Change the subscription to get more intents today.",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		} else if (d == 500) {
			enqueueSnackbar(
				"You have no subscription plan, please choose one of the subscription plans",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
			history.push("/subscriptions");
		} else {
			enqueueSnackbar("There was an error with our AI. Please, try later.", {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			});
			window.analytics.track("sentIntent.error");
		}
	} catch (error) {
		console.error(error);
		enqueueSnackbar("There was an error with our AI. Please, try later.", {
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
			variant: "error",
		});
		window.analytics.track("sentIntent.error");
	} finally {
		setIsLoading(false);
	}
};

const handleCreateMap = async () => {
	try {
		setIsLoading(true);
		dispatch(resetLayers());
		const ownerId = (await Auth.currentAuthenticatedUser()).username;
		setOwnerId(ownerId);
		const d = await createMapFromIntent(mapIntent, ownerId);
		dispatch(setBoundingBox(d.boundingBox));
		dispatch(getStyleId(d.styleId));
		const layerID = `bettermaps-${randomID()}`;
		var reverseLayer = {};
		reverseLayer.id = layerID;
		reverseLayer.name = mapIntent.CREATE;
		reverseLayer.fileType = LayerFileType.GeoJSON;
		reverseLayer.type = LayerType.Invert;
		reverseLayer.show = true;
		reverseLayer.fillColor = "rgba(255,255,255,0.7)";
		reverseLayer.lineColor = "rgba(0,0,0,1)";
		reverseLayer.boundingbox = d.boundingBox;
		reverseLayer.opacity = 1;
		reverseLayer.source = {};
		reverseLayer.source.id = d.idLayerReverseGeojson;
		reverseLayer.source.type = "geojson";
		reverseLayer.source.data = d.urlReverseGeojson;
		reverseLayer.urlSource = d.urlReverseGeojson;
		dispatch(addLayer(reverseLayer));
		const s = await getUserStyle(d.styleId);
		dispatch(setMapStyle(s.baseStyleURL));
		createMap();
	} catch (e) {
		setIsLoading(false);
		console.error(e);
		enqueueSnackbar(
			"There was an error when saving your style. Please, try later.",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	}
};

	const createLogForAi = (result) => {
		sendLogForAi(mapDescription, mapIntent, result, ownerId);
	};

	const kindOfSearch = () => {
		analytics.identify(`Search:${mapDescription}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	function createMap() {
		// dispatch(getBoundingBox(box));
		// dispatch(getStyleId(styleId));
		dispatch(setStyleName(generatedName));
		// dispatch(setReverseLayer(u));

		history.push(`create-map/`);
		setIsLoading(false);
	}

	return (
		<MainContainer
			content={
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					data-testid="dashboard-container">
					<Grid
						item
						xs={12}
						sm={12}
						md={8}
						lg={5}
						xl={5}
						style={{ marginTop: "20vh" }}>
						<Box
							style={{
								marginBottom: "20px",
							}}>
							<img
								src="https://bettermaps-branding.s3.eu-central-1.amazonaws.com/bettermapGreen%403x.png"
								alt="Bettermaps logo"
								width="80%"
							/>
						</Box>
						<BettermapsTextField
							style={{
								marginBottom: "20px",
							}}
							startAdornment={
								<MapIcon sx={{ color: "secondary.main" }}></MapIcon>
							}
							className="extra-rounded"
							placeholder="Create a map of Spain..."
							value={mapDescription}
							onChange={(e) => setMapDescription(e.target.value)}
						/>
						<Box>
							<Button
								variant="contained"
								title={"Create map"}
								disabled={!mapDescription || isLoading}
								onClick={handleCreateIntent}
								sx={{ borderRadius: 1, width: "150px", mr: "10px" }}>
								<Typography variant="button">Create map</Typography>
							</Button>
							<Button
								variant="contained"
								title={"I'm feeling lucky"}
								disabled
								sx={{ borderRadius: 1, width: "180px" }}>
								<Typography variant="button">I'm feeling lucky</Typography>
							</Button>
						</Box>
						<Box style={{ marginTop: "20px", height: "60px" }}>
							<CircularProgress
								color="primary"
								style={{
									display: isLoading ? "inline-block" : "none",
								}}
							/>
						</Box>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						style={{
							marginTop: "140px",
							display: "flex",
							flexDirection: "row",
							flexWrap: "wrap",
							justifyContent: "center",
						}}>
						{cards.map(
							(card, index) =>
								card[0] !== "Divider" &&
								card[0] !== "Dashboard" &&
								(process.env.REACT_APP_ENV !== "prod" || card[3] !== "dev") && (
									<DashBoardCard
										key={index}
										style={{ marginLeft: "20px", marginBottom: "20px" }}
										cardText={card[0]}
										to={card[1]}
										cardIcon={card[4]}
									/>
								)
						)}
					</Grid>
					{showMapIntentModal && (
						<ConfirmMapIntentModal
							createLogForAi={createLogForAi}
							show={showMapIntentModal}
							setShow={setShowMapIntentModal}
							mapIntent={mapIntent}
							createMap={handleCreateMap}
						/>
					)}
				</Box>
			}
		/>
	);
}
