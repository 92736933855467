import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import plus from "../../images/noun_plus button.png";
import ScienceIcon from '@mui/icons-material/Science';
import { width } from "@mui/system";
import { useTheme } from "@emotion/react";


export default function ToolsetCard(props) {
  const theme = useTheme();
  return (
    <CardActionArea href={props.onClick}>
    <Card
      raised
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.primary.main,
        borderRadius:"8px",
      }}
    >
      <CardMedia>
      <ScienceIcon style={{ fontSize: 100 , color:"white"}}/>
      </CardMedia>
      <CardContent>
        <Typography variant="h6" style={{color:"white"}}>
          {props.toolName}
        </Typography>
      </CardContent>
    </Card>
    </CardActionArea>
  );
}
