import { useSelector } from "react-redux";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import { deleteUserStyle } from "../../../../services/DatasourcesService";
import { useSnackbar } from "notistack";

export default function DeleteStyleModal(props) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const userData = useSelector((state) => state.user);

const handleDeleteStyle = async () => {
	try {
		await deleteUserStyle(props.styleToDeleteId);
		props.removeStyleFromTable();
		enqueueSnackbar("Style deleted", {
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
			variant: "success",
		});
		props.setShow(false);
		analytics.identify("Your Styles - delete style - confirm ", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	} catch (e) {
		console.error(e);
		enqueueSnackbar(
			"There was an error when deleting your style. Please try later.",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	}
};


	const handleCancel = () => {
		props.setShow(false);
		analytics.identify("Your Styles - delete style - cancel ", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	return (
		<Dialog
			open={props.show}
			onClose={() => props.setShow(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">
				{"Are you sure you want to delete the style?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Upon confirming, the style will be deleted. This action cannot be
					undo.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} autoFocus>
					Cancel
				</Button>
				<Button color="error" onClick={handleDeleteStyle}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
