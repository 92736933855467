import { LayerType } from "./LayerType";
import generateColorRandom from "../helpers/generateColorRandom";
export default function toLayer(mapboxLayer) {
	if (mapboxLayer.type.value == LayerType.Fill.value) {
		return toFillLayer(mapboxLayer);
	} else if (mapboxLayer.type.value == LayerType.Line.value) {
		return toLineLayer(mapboxLayer);
	} else if (mapboxLayer.type.value == LayerType.Circle.value) {
		return toCircleLayer(mapboxLayer);
	} else if (mapboxLayer.type.value == LayerType.Raster.value) {
		return toRasterLayer(mapboxLayer);
	} else if (mapboxLayer.type.value == LayerType.Invert.value) {
		return toFillReverseLayer(mapboxLayer);
	}
}

function toFillLayer(mapboxLayer) {
	return {
		id: mapboxLayer.id,
		source: mapboxLayer.source.id,
		type: LayerType.Fill.value,
		before: mapboxLayer.beforeId,
		layout: {
			visibility: mapboxLayer.show ? "visible" : "none",
		},
		paint: {
			"fill-outline-color":
				`rgba(${mapboxLayer.lineColor.r},${mapboxLayer.lineColor.g},${mapboxLayer.lineColor.b},${mapboxLayer.lineColor.a}) ` ||

				generateColorRandom(),
			"fill-color": ["get", "color"] || generateColorRandom(),

			"fill-opacity": 1 || 0,
		},
	};
}

function toLineLayer(mapboxLayer) {
	return {
		id: mapboxLayer.id,
		source: mapboxLayer.source.id,
		type: LayerType.Line.value,
		before: mapboxLayer.beforeId,
		layout: {
			visibility: mapboxLayer.show ? "visible" : "none",
		},
		paint: {
			"line-width": 2 || 0,
			"line-color": ["get", "color"] || generateColorRandom(),
			"line-opacity": mapboxLayer.opacity || 0,
		},
	};
}

function toCircleLayer(mapboxLayer) {
	return {
		id: mapboxLayer.id,
		source: mapboxLayer.source.id,
		type: LayerType.Circle.value,
		before: mapboxLayer.beforeId,
		layout: {
			visibility: mapboxLayer.show ? "visible" : "none",
		},
		paint: {
			"circle-color": ["get", "color"] || generateColorRandom(),
			"circle-stroke-color":
				`rgba(${mapboxLayer.lineColor.r},${mapboxLayer.lineColor.g},${mapboxLayer.lineColor.b},${mapboxLayer.lineColor.a})` ||
				generateColorRandom(),

			"circle-stroke-width": 2 || 0,
			"circle-opacity": mapboxLayer.opacity || 0,
		},
	};
}

function toRasterLayer(mapboxLayer) {
	return {
		id: mapboxLayer.id,
		type: LayerType.Raster.value,
		beforeId: mapboxLayer.beforeId,
		layout: {
			visibility: mapboxLayer.show ? "visible" : "none",
		},
		source: mapboxLayer.source.id,
		paint: {
			"raster-opacity": mapboxLayer.opacity || 0,
		},
	};
}

export function toSource(source) {
	if (source.type == "geojson") {
		return {
			id: source.id,
			type: source.type,
			data: source.data,
		};
	} else if (source.type == "raster") {
		return {
			id: source.id,
			type: source.type,
			tiles: source.tiles,
			tileSize: source.tileSize,
		};
	}
}

function toFillReverseLayer(layer) {
	return {
		id: layer.id,
		source: layer.source.id,
		type: "fill",
		before: layer.beforeId,
		layout: {
			visibility: layer.show ? "visible" : "none",
		},
		paint: {
			"fill-outline-color": layer.lineColor || generateColorRandom(),
			"fill-color": layer.fillColor || generateColorRandom(),
			"fill-opacity": layer.opacity || 0,
		},
	};
}


export function toReverseSource(source) {
  return {
    id: source.id,
    type: source.type,
    data: source.data,
  };
}
