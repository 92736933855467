import { API, Auth, graphqlOperation } from "aws-amplify";
import S3 from "aws-sdk/clients/s3";
import post from "../clients/HttpClient";
import {
  createStyle,
  deleteStyle,
  updateStyle,
  createLayer,
  updateLayer,
  deleteLayer,
} from "../graphql/mutations";
import { getLayer, getStyle, listLayers, listStyles } from "../graphql/queries";
import { useSelector } from "react-redux";

let s3 = new S3({
  apiVersion: "2006-03-01",
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  params: { Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME },
});

// export async function canPrintPaperMap(email) {
//   let headers = {
//     authorizationToken: email,
//   };

//   var isDev = "";
//   if (process.env.REACT_APP_ENV == "prod") {
//     isDev = "_prod";
//   }

//   let response = await fetch(
//     `${process.env.REACT_APP_STRIPE_CONNECTION}/dev/paper_print_blank${isDev}`,
//     {
//       method: "GET",
//       headers: headers,
//     }
//   );

//   return response.status;
// }

// Refactored Code

export async function canPrintPaperMap(email) {
	let headers = {
		authorizationToken: email,
	};

	var isDev = "";
	if (process.env.REACT_APP_ENV == "prod") {
		isDev = "_prod";
	}

	try {
		let response = await fetch(
			`${process.env.REACT_APP_STRIPE_CONNECTION}/dev/paper_print_blank${isDev}`,
			{
				method: "GET",
				headers: headers,
			}
		);

		return response.status;
	} catch (error) {
		console.error("Error:", error);
	}
}

export async function canPrintHtml(email) {
	let headers = {
		authorizationToken: email,
	};

	// let response = await fetch(
	//   `${process.env.REACT_APP_STRIPE_CONNECTION}/${process.env.REACT_APP_ENV}/standalonewebsite-print-${process.env.REACT_APP_ENV}`,
	//   {
	//     method: "GET",
	//     headers: headers,
	//   }
	// );
	try {
		const response = await fetch(
			`${process.env.REACT_APP_STRIPE_CONNECTION}/${process.env.REACT_APP_ENV}/standalonewebsite-print-${process.env.REACT_APP_ENV}`,
			{
				method: "GET",
				headers: headers,
			}
		);
		// handle response as needed
	} catch (error) {
		console.error("Error:", error);
	}

	return response.status;
}

export async function countInfo(email) {
	let headers = {
		email: email,
	};
	var isDev = "";
	if (process.env.REACT_APP_ENV == "prod") {
		isDev = "_pr";
	}
	const response = await fetch(
		`${process.env.REACT_APP_STRIPE_CONNECTION}/${process.env.REACT_APP_ENV}/count_usage_by_user${isDev}`,
		{
			method: "POST",
			body: JSON.stringify(headers),
		}
	);

	const data = await response.json();
	return data;
}

// export async function cancelSubscription(email) {
// 	let headers = {
// 		email: email,
// 	};
// 	var isDev = "";
// 	if (process.env.REACT_APP_ENV == "prod") {
// 		isDev = "_prod";
// 	}
// 	// const response = await fetch(
// 	//   `${process.env.REACT_APP_STRIPE_CONNECTION}/${process.env.REACT_APP_ENV}/delete_plan${isDev}`,
// 	//   {
// 	//     method: "POST",
// 	//     body: JSON.stringify(headers),
// 	//   }
// 	// );
// 	try {
// 		const response = await fetch(
// 			`${process.env.REACT_APP_STRIPE_CONNECTION}/${process.env.REACT_APP_ENV}/delete_plan${isDev}`,
// 			{
// 				method: "POST",
// 				body: JSON.stringify(headers),
// 			}
// 		);
// 		// handle response as needed
// 	} catch (error) {
// 		console.error("Error:", error);
// 	}
// 	return response;
// }

// Refactored Code

export async function cancelSubscription(email) {
	const headers = {
		email,
	};
	const isProd = process.env.REACT_APP_ENV === "prod";
	const apiUrl = `${process.env.REACT_APP_STRIPE_CONNECTION}/${
		process.env.REACT_APP_ENV
	}/delete_plan${isProd ? "_prod" : ""}`;

	try {
		const response = await fetch(apiUrl, {
			method: "POST",
			body: JSON.stringify(headers),
		});

		// handle response as needed

		return response;
	} catch (error) {
		console.error("Error:", error);
		return null;
	}
}