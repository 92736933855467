import { Grid, Stack } from "@mui/material";
import { LayerType } from "../../../lib/LayerType";
import DataLayerTile from "./DataLayerTile";
import { useSelector } from "react-redux";
// import DataReverseLayerTile from "./DataReverseLayerTile";

export default function AddedLayersDisplay(props) {
	const { layers, backgroundLayer } = useSelector((state) => state.layers);
	const uniqueNameAndLocation = {};

	const LayerCollectionResult = [];

	layers.forEach((layer) => {
		const { name, location } = layer;
		const nameAndLocation = `${name}_${location}`;

		if (!uniqueNameAndLocation[nameAndLocation] && name !== location) {
			uniqueNameAndLocation[nameAndLocation] = true;
			LayerCollectionResult.push(layer);
		}
	});

	return (
		<Grid
			hidden={!props.showLayerTiles}
			item
			xl={3}
			lg={3}
			md={4}
			sm={5}
			xs={5}>
			<Stack>
				<DataLayerTile
					layer={backgroundLayer}
					map={props.mapId}
					canEdit={true}
					onEdit={() => props.editBackground()}></DataLayerTile>

				{LayerCollectionResult
					// .sort((a, b) => b - a)
					.map((layer, index) => (
						<DataLayerTile
							layer={layer}
							map={props.mapId}
							// setLayers={props.setLayers}
							key={layer.id}
							canDelete={layer.type.value !== LayerType.Background.value}
							onEdit={() => props.editLayer(layer)}
							onMoveUp={() => props.handleMoveUpLayer(layer.position)}
							onMoveDown={() => props.handleMoveDownLayer(layer.position)}
							setLayerVisibility={props.setLayerVisibility}
							onDelete={() =>
								props.setRemoveLayerModal({ show: true, layer: layer })
							}
							canEdit={true}></DataLayerTile>
					))}
			</Stack>
		</Grid>
	);
}
