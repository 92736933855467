import "@fontsource/roboto";
import { StrictMode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import FeedbackManager from "./components/common/MinimalFeedback/FeedbackManager";
import Navigation from "./components/Navbar/Navigation";
import { useMediaQuery } from "./lib/hooks";
import AccountPage from "./pages/AccountPage";
import AnalyseMapImagePage from "./pages/AnalyseMapImagePage.js";
import AnalyseWebMapStylePage from "./pages/AnalyseWebMapStylePage.js";
import CreateDigitalMapPage from "./pages/CreateDigitalMapPage";
import CreateMapWithAI from "./pages/CreateMapWithAI";
import CreatePaperMapPage from "./pages/CreatePaperMapPage.js";
import DashboardPage from "./pages/DashboardPage";
import LayersPage from "./pages/DataLayersPage";
import SourcesPage from "./pages/DataSourcesPage";
import { StylesPage } from "./pages/DataStylesPage";
import EditStylePage from "./pages/EditStylePage.js";
import ErrorNotFoundPage from "./pages/ErrorNotFoundPage";
import ExploreMapPage from "./pages/ExploreMapPage";
import GetAdminUnitPage from "./pages/GetAdminUnitPage";
import StripePage from "./pages/StripePage";
import StripeLandingPage from "./pages/StripeLandingPage";
import SmallDeviceNotificationPage from "./pages/SmallDeviceNotificationPage";
import ToolsPage from "./pages/ToolsetPage";
import "./styles/main.scss";
import { useTheme } from "@mui/material/styles";
import user, { getUser, setPlanInfo } from "./redux/slices/user";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageData } from "./redux/slices/localStorage";
import { countInfo } from "./services/StripeService";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getIPAddress } from "./helpers/getIPAddress";
import { setCountry, setIp, setLanguage } from "./redux/slices/user";

function usePageViews() {
	const location = useLocation();
	useEffect(() => {
		analytics.page(location.pathname);
	}, [location]);
}

function App() {
	const dispatch = useDispatch();
	const history = useHistory();

	async function checkUserSubscription() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const c = await countInfo(user.attributes.email);
			if (c.plan == "standard" || c.plan == "explorer" || c.plan == "pro") {
				dispatch(setPlanInfo(c));
				// if(history){
				//   history.push("/dashboard");
				// }
			} else {
				// if (history) {
				//   history.push("/subscriptions");
				// }
				console.log("No subscription plan found");
			}
		} catch (error) {
			console.log(error);
		}
	}

	checkUserSubscription();

	useEffect(() => {
		const getUserIP = async () => {
			const ip = await getIPAddress();
			dispatch(setIp(ip));
		};
		dispatch(getUser());
		dispatch(getLocalStorageData());

		const country = navigator.language.split("-")[1];
		dispatch(setCountry(country));
		const language = navigator.language.split("-")[0];
		dispatch(setLanguage(language));
		getUserIP();
	}, []);

	const matches = useMediaQuery("(max-width: 620px)");
	const theme = useTheme();

	return (
		<StrictMode>
			<div className="better_maps">
				<Helmet>
					<meta charSet="utf-8" />
					<title>Bettermaps</title>
				</Helmet>
				{matches ? (
					<SmallDeviceNotificationPage />
				) : (
					<BrowserRouter>
						<Navigation>
							<Switch>
								<Route exact path="/" render={() => <DashboardPage />} />
								<Route
									path="/create-map"
									render={() => <CreateDigitalMapPage />}
								/>
								<Route
									path="/analyse-web-map-style"
									render={() => <AnalyseWebMapStylePage />}
								/>
								<Route path="/edit-style" render={() => <EditStylePage />} />
								<Route
									path="/export-paper-map"
									render={() => <CreatePaperMapPage />}
								/>
								<Route
									path="/analyse-paper-map-image"
									render={() => <AnalyseMapImagePage />}
								/>
								<Route path="/account" render={() => <AccountPage />} />
								{process.env.REACT_APP_ENV !== "prod" && (
									<Route path="/toolset" render={() => <ToolsPage />} />
								)}
								<Route path="/explore-map" render={() => <ExploreMapPage />} />
								<Route path="/dashboard" render={() => <DashboardPage />} />
								<Route path="/sources" render={() => <SourcesPage />} />
								<Route path="/styles" render={() => <StylesPage />} />
								<Route path="/layers" render={() => <LayersPage />} />
								<Route path="/admin-unit" render={() => <GetAdminUnitPage />} />
								<Route path="/ai-creator" render={() => <CreateMapWithAI />} />
								<Route path="/subscriptions" render={() => <StripePage />} />
								<Route
									path="/stripelanding"
									render={() => <StripeLandingPage />}
								/>

								<Route component={ErrorNotFoundPage} />
							</Switch>
						</Navigation>
					</BrowserRouter>
				)}
				<FeedbackManager
					bottom={40}
					right={15}
					issueColor={theme.palette.primary.main}
					ideaColor={theme.palette.primary.main}
					otherColor={theme.palette.primary.main}
				/>
			</div>
		</StrictMode>
	);
}

export default App;
