import { Button, TextField, Box } from "@mui/material";
import React, { useContext, useState, useRef } from "react";
import {
  AttributionControl,
  NavigationControl,
  ScaleControl,
  MapRef,
} from "react-map-gl";
import MAP_STYLE from "../data/map-style-basic-v8.json";
import ButtonMapControl from "../components/ExploreMap/ButtonMapControl";
import MapboxMap from "../components/common/MapboxMap";
import BettermapsOverlay from "../components/ExploreMap/BettermapsOverlay";
import CurrentZoomLevel from "../components/common/MapBox/CurrentZoomLevel";
import SendIcon from "@mui/icons-material/Send";
import generateColorRandom from "../helpers/generateColorRandom";

export default function ExploreMapPage() {
	const mapRef = useRef();
	const mapStyleInit = {
		...MAP_STYLE,
		sources: { ...MAP_STYLE.sources },
		layers: MAP_STYLE.layers.slice(),
	};
	const [answer, setAnswer] = React.useState("");
	const [request, setRequest] = React.useState("request");
	const handleChange = (event) => {
		setRequest(event.target.value);
	};

	const [mapstyle, setMapstyle] = useState(mapStyleInit);

	const [geoJson, setGeoJson] = useState(null);
	const mapId = "explore-map";

	function addLayer(geojsonData) {
		mapStyleInit.sources["data-geojson"] = {
			type: "geojson",
			data: geojsonData,
		};

		mapStyleInit.layers.push(
			{
				id: "geojson-fill",
				source: "data-geojson",
				type: "fill",
				paint: {
					"fill-outline-color": "#0040c8" || generateColorRandom(),
					"fill-color": "#fff" || generateColorRandom(),
					"fill-opacity": 0.5 || 1,
				},
			},
			{
				id: "geojson-outline",
				source: "data-geojson",
				type: "line",
				paint: {
					"line-width": 2,
					"line-color": "#0080ef" || generateColorRandom(),
				},
			}
		);
		setMapstyle(mapStyleInit);
		return mapStyleInit;
	}

	const navControlStyle = {
		right: 10,
		top: 10,
	};

	const attributionStyle = {
		left: 0,
		top: 0,
	};

	const scaleControlStyle = {
		right: 20,
		bottom: 10,
	};

	function ControlPanel() {
		return (
			<div className="control-panel">
				<h3>Download a Geojson file of any administrative unit of the world</h3>
				<p>Write a name and click on the button to download a geojson file.</p>
			</div>
		);
	}

	async function sendRequest() {
		try {
			setAnswer("");
			const name = request;
			const url =
				"https://obly6qupc3.execute-api.eu-central-1.amazonaws.com/" +
				process.env.REACT_APP_ENV +
				"/";
			const data = { name: name };
			const response = await fetch(url, {
				method: "POST",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json",
				},
			});
			const json = await response.json();
			setAnswer(json.body[0].display_name);
			addLayer(json.body[0].geojson);
			fitToBounds(json.body[0].boundingbox);
		} catch (error) {
			console.error("Error:", error);
		}
	}

	function fitToBounds(feature) {
		const [minLat, maxLat, minLng, maxLng] = [
			feature[0],
			feature[1],
			feature[2],
			feature[3],
		];
		// mapRef.current.fitBounds(
		//   [
		//     [1, 2],
		//     [1, 2]
		//   ]);
	}

	return (
		<React.Fragment>
			<MapboxMap
				ref={mapRef}
				id={mapId}
				latitude="40.0"
				longitude="-3"
				zoom="5"
				height="calc(100vh - 50px)"
				mapStyle={mapstyle}
				content={
					<React.Fragment>
						<CurrentZoomLevel mapId={mapId} right={60} top={20} />
						<NavigationControl style={navControlStyle} />
						<AttributionControl compact={true} style={attributionStyle} />
						<ScaleControl
							maxWidth={100}
							unit="metric"
							style={scaleControlStyle}
						/>
					</React.Fragment>
				}
			/>
			<ControlPanel />
			<Box
				sx={{
					width: "60%",
					maxWidth: "80%",
					position: "relative",
					zIndex: "99",
					bottom: "160px",
					left: "200px",
					// right: '200px',
				}}>
				<TextField
					fullWidth
					label="What administrative unit do you want to download?"
					id="fullWidth"
					value={request}
					onChange={handleChange}
					InputProps={{
						endAdornment: (
							<Button
								variant="contained"
								endIcon={<SendIcon />}
								onClick={sendRequest}>
								Send
							</Button>
						),
					}}
					sx={{
						backgroundColor: "white",
						bottom: "10px",
					}}></TextField>
				<TextField
					fullWidth
					id="outlined-multiline-flexible"
					//   label="Cass understood:"
					multiline
					maxRows={4}
					value={answer}
					sx={{
						backgroundColor: "white",
						top: "10px",
					}}
				/>
			</Box>
		</React.Fragment>
	);
}
