import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../../../lib/hooks";
import {
  exportStandAloneWebsite,
  exportStyle,
  exportHostedWebsite,
} from "../../../../lib/MapExporter";
import {
  createUserLayer,
  createUserStyle,
  updateUserLayer,
  updateUserStyle,
} from "../../../../services/DatasourcesService";
import BettermapsModal from "../../BettermapsModal";
import { Auth } from "aws-amplify";
import CheckAPIKeysModal from "./CheckAPIKeysModal";
import HostedMapInfoModal from "./HostedMapInfoModal";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStyleId } from "../../../../redux/slices/style";
import { saveLayer } from "../../../../redux/slices/layers";
import { canPrintHtml } from "../../../../services/StripeService";

export default function ExportModal(props) {
	const dispatch = useDispatch();
	const { styleId } = useSelector((state) => state.style);
	const { ownerId } = useSelector((state) => state.user);
	const { layers } = useSelector((state) => state.layers);
	const { styleName } = useSelector((state) => state.style);
	const { mapStyle } = useSelector((state) => state.style);
	const { mapId } = useSelector((state) => state.maps);
	const { boundingBox } = useSelector((state) => state.boundingBox);
	const { email } = useSelector((state) => state.user);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const history = useHistory();
	const queryParams = useQueryParams();
	const [showAPIKeysDialog, setShowAPIKeysDialog] = useState(false);
	const [showHostedInfoModal, setShowHostedInfoModal] = useState(false);
	const [hostedMapInfo, setHostedMapInfo] = useState({});

	const userData = useSelector((state) => state.user);

	useEffect(() => {
		setHostedMapInfo({});
	}, []);

	const handleStyleSave = async (exportMethod) => {
		try {
			const savePromises = layers.map(async (l) => {
				if (l.dbLayerId) {
					await updateUserLayer(l, ownerId);
				} else {
					const id = await createUserLayer(l, ownerId);
					dispatch(saveLayer([l.id, id]));
				}
			});

			await Promise.all(savePromises);

			modiftyLayersToSave(exportMethod);
		} catch (error) {
			setIsLoading(false);
			enqueueSnackbar(`Error saving style: ${error.message}`, {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			});
		}
	};

	const modiftyLayersToSave = (exportMethod) => {
		var modifyLayers = [];
		var modifyWMSLayers = [];
		var cameraSettings = {};
		cameraSettings.long = props.map.getCenter().lng;
		cameraSettings.lat = props.map.getCenter().lat;
		cameraSettings.zoom = props.map.getZoom();
		cameraSettings.pitch = props.map.getPitch();
		cameraSettings.bearing = props.map.getBearing();

		// modifyLayers.push(cameraSettings)

		layers.forEach((l) => {
			if (l.type.value == "raster") {
				var modifyLayer = {};
				modifyLayer.id = l.id;
				modifyLayer.name = l.name;
				modifyLayer.fileType = l.fileType;
				modifyLayer.type = l.type;
				modifyLayer.show = l.show;
				modifyLayer.opacity = l.opacity;
				modifyLayer.boundingbox = l.boundingbox;
				modifyLayer.source = {};
				modifyLayer.source.id = l.source.id;
				modifyLayer.source.type = l.source.type;
				modifyLayer.source.tiles = l.source.tiles;
				modifyLayer.source.tileSize = l.source.tileSize;
				modifyLayer.dbLayerId = l.dbLayerId;
				modifyWMSLayers.push(modifyLayer);
			} else {
				var modifyLayer = {};
				modifyLayer.id = l.id;
				modifyLayer.name = l.name;
				modifyLayer.fileType = l.fileType;
				modifyLayer.type = l.type;
				modifyLayer.show = l.show;
				modifyLayer.fillColor = l.fillColor;
				modifyLayer.lineColor = l.lineColor;
				if (l.type != "invert") {
					modifyLayer.selectColor = l.selectColor;
					modifyLayer.featureColors = l.featureColors;
					modifyLayer.showOnHover = l.showOnHover;
					modifyLayer.hoverProperties = l.hoverProperties;
				}
				modifyLayer.boundingbox = l.boundingbox;
				modifyLayer.urlSource = l.urlSource;
				modifyLayer.opacity = l.opacity;
				modifyLayer.source = {};
				modifyLayer.source.id = l.source.id;
				modifyLayer.source.type = l.source.type;
				modifyLayer.source.data = [];
				modifyLayer.dbLayerId = l.dbLayerId;
				modifyLayers.push(modifyLayer);
			}
		});

		saveAndExport(modifyLayers, modifyWMSLayers, cameraSettings, exportMethod);
	};

	const handleUpdateStyle = (cameraSettings, modifyLayers, modifyWMSLayers) =>
		updateUserStyle(
			styleId,
			props.map.getStyle(),
			styleName,
			modifyLayers,
			mapStyle,
			ownerId,
			cameraSettings
			// modifyWMSLayers
		);

	const handleCreateStyle = (cameraSettings, modifyLayers, modifyWMSLayers) =>
		createUserStyle(
			props.map.getStyle(),
			styleName,
			modifyLayers,
			mapStyle,
			ownerId,
			cameraSettings,
			modifyWMSLayers
		);

const saveAndExport = async (
	modifyLayers,
	modifyWMSLayers,
	cameraSettings,
	exportMethod
) => {
	try {
		const saveFunction = styleId ? handleUpdateStyle : handleCreateStyle;
		const id = await saveFunction(
			cameraSettings,
			modifyLayers,
			modifyWMSLayers
		);
		dispatch(getStyleId(id));
		await exportMethod();
		enqueueSnackbar("Style exported successfully", {
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
			variant: "success",
		});
	} catch (e) {
		console.error(e);
		enqueueSnackbar(
			"There was an error when exporting your style. Please, try later.",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	}
};

	const handleExportStyle = () =>
		exportStyle(props.map.getStyle(), props.styleName);

	const beforeHandleExportStandAloneWebsite = async () => {
		try {
			const c = await canPrintHtml(email);
			if (c === 200) {
				handleExportStandAloneWebsite();
			} else if (c === 403) {
				enqueueSnackbar(
					"You have run out of Html exports. Change the subscription to get more Html exports today.",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
			} else if (c === 500) {
				enqueueSnackbar(
					"You have no subscription plan, please choose one of the subscription plans",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
				history.push("/subscriptions");
			} else {
				enqueueSnackbar(
					"There was an error when exporting a Standalone Website. Please, try later.",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
			}
		} catch (e) {
			console.error(e);
			enqueueSnackbar(
				"There was an error when exporting a Standalone Website. Please, try later.",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
	};

const handleExportStandAloneWebsite = async () => {
	try {
		const currentUser = await Auth.currentAuthenticatedUser();
		const mapboxAPIKey = currentUser.attributes["custom:mapboxAPIkey"];
		const maptilerAPIKey = currentUser.attributes["custom:maptilerAPIkey"];
		await exportStandAloneWebsite(
			props.map.getStyle(),
			props.map.getZoom(),
			props.map.getCenter(),
			{ mapboxAPIKey, maptilerAPIKey }
		);
	} catch (error) {
		console.error(error);
		enqueueSnackbar(
			"There was an error when exporting a Standalone Website. Please, try later.",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	}
};

const handleExportHostedWebsite = async () => {
	try {
		setShowHostedInfoModal(true);
		const user = await Auth.currentAuthenticatedUser();
		const mapStyle = props.map.getStyle();
		const mapZoom = props.map.getZoom();
		const mapCenter = props.map.getCenter();
		const apiKey = {
			mapboxAPIKey: user.attributes["custom:mapboxAPIkey"],
			maptilerAPIKey: user.attributes["custom:maptilerAPIkey"],
		};
		const url = await exportHostedWebsite(
			mapStyle,
			mapZoom,
			mapCenter,
			apiKey,
			ownerId
		);
		setHostedMapInfo(url);
	} catch (error) {
		console.error(error);
	}
};


	useEffect(() => {
		if (hostedMapInfo.urlS3 && hostedMapInfo.htmlTag) {
			setShowHostedInfoModal(true);
		}
	}, [hostedMapInfo]);

	return (
		<BettermapsModal
			sx={{ width: "600px" }}
			title="Export Map as..."
			showModal={props.show}
			setShowModal={props.setShow}>
			<Typography>
				Note that before exporting, we will save your style
			</Typography>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					mt: 2,
				}}>
				<Button
					variant="contained"
					title="Paper Map"
					onClick={() => {
						handleStyleSave(() => history.push("/export-paper-map?")),
							analytics.identify("click on Paper Map", {
								id: userData.ownerId,
								subscription: userData.subscription,
							});
					}}
					sx={{ borderRadius: 1, mr: 2 }}>
					<Typography variant="button">Paper Map</Typography>
				</Button>
				<Button
					variant="contained"
					title="Standalone Website"
					onClick={() => {
						setShowAPIKeysDialog(true),
							analytics.identify("click on Standalone Website", {
								id: userData.ownerId,
								subscription: userData.subscription,
							});
					}}
					sx={{ borderRadius: 1, mr: 2 }}>
					<Typography variant="button">Standalone Website</Typography>
				</Button>
				<Button
					variant="contained"
					title="Hosted Website"
					onClick={() => {
						handleStyleSave(handleExportHostedWebsite),
							analytics.identify("click on Hosted Website", {
								id: userData.ownerId,
								subscription: userData.subscription,
							});
					}}
					sx={{ borderRadius: 1, mr: 2 }}>
					<Typography variant="button">Hosted Website</Typography>
				</Button>
				{/* <Button
          variant="contained"
          title="Map Style"
          onClick={() => handleStyleSave(handleExportStyle)}
          sx={{ borderRadius: 1, mr: 2 }}
        >
          <Typography variant="button">Map Style</Typography>
        </Button> */}
			</Box>
			<CheckAPIKeysModal
				show={showAPIKeysDialog}
				setShow={setShowAPIKeysDialog}
				onConfirm={() =>
					handleStyleSave(beforeHandleExportStandAloneWebsite)
				}></CheckAPIKeysModal>
			<HostedMapInfoModal
				show={showHostedInfoModal}
				setShow={setShowHostedInfoModal}
				hostedMapInfo={hostedMapInfo}
				setShowMainModal={props.setShow}
				setHostedMapInfo={setHostedMapInfo}></HostedMapInfoModal>
		</BettermapsModal>
	);
}
