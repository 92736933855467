import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { cancelSubscription, countInfo } from "../../services/StripeService";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { setPlanInfo } from "../../redux/slices/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function CancelSubscriptionModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { email } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

const handleCancelSubscription = async () => {
	try {
		setIsLoading(true);
		const response = await cancelSubscription(email);
		if (response.status === 200) {
			enqueueSnackbar("Your subscription has been canceled", {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "success",
			});
			const count = await countInfo(email);
			dispatch(setPlanInfo(count));
			setIsLoading(false);
			handleClose();
		} else {
			throw new Error(
				"There was a problem canceling your subscription, please try again"
			);
		}
	} catch (error) {
		enqueueSnackbar(error.message, {
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
			variant: "error",
		});
		setIsLoading(false);
		handleClose();
	}
};


  return (
    <div>
      <Button onClick={handleOpen} variant="contained" sx={{ mt: 2 }}>
        Cancel Subscription
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box x={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h5">
              Are you sure you want to cancel your subscription?
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "center", mt: 2 }}
            >
              Note that your subscription will be changed to Standard that is
              free
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button
              variant="outlined"
              size="large"
              color="error"
              onClick={handleClose}
              sx={{ mr: 10 }}
            >
              No
            </Button>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                size="large"
                color="success"
                onClick={() => {
                  handleCancelSubscription();
                }}
              >
                YES
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
