import { API, Auth, graphqlOperation } from "aws-amplify";
import S3 from "aws-sdk/clients/s3";
import post from "../clients/HttpClient";
import {
  createStyle,
  deleteStyle,
  updateStyle,
  createLayer,
  updateLayer,
  deleteLayer,
} from "../graphql/mutations";
import { getLayer, getStyle, listLayers, listStyles } from "../graphql/queries";
import { useSelector } from "react-redux";
import { fileToBase64, htmlToBase64 } from "../lib/fileConversions";
import generateColorRandom from "../helpers/generateColorRandom";

let s3 = new S3({
	apiVersion: "2006-03-01",
	accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
	region: process.env.REACT_APP_AWS_REGION,
	params: { Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME },
});

export async function getGeoJSONs(groups, showHistorical, filters) {
	var activegroups;
	if (groups) {
		activegroups = Object.keys(groups).filter((group) => groups[group]);
		activegroups.map((active, key) => {
			activegroups[key] = activegroups[key].replace(/\s+/g, "");
		});
	}
	var showHistoryLayer;
	if (showHistorical == true) {
		showHistoryLayer = "true";
	} else {
		showHistoryLayer = "false";
	}

	var data = {};
	if (activegroups) {
		data = {
			filteredSearch: {
				name: filters.name,
				location: filters.location,
				geometry: filters.geometry,
			},
			tags: activegroups,
			show: showHistoryLayer,
		};
	} else {
		data = {
			filteredSearch: {
				name: filters.name,
				location: filters.location,
				geometry: filters.geometry,
			},
			tags: [],
			show: showHistoryLayer,
		};
	}

	// Authentification
	const idToken = (await Auth.currentAuthenticatedUser())["signInUserSession"][
		"idToken"
	]["jwtToken"];
	//('idToken', idToken)
	let headers = {
		Authorization: idToken,
		"Content-Type": "application/json;",
		"Access-Control-Allow-Origin": "*",
		"Access-Control-Allow-Headers": "*",
	};

	//('headers',headers)
	// let response = await fetch(
	//   `${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}/search`,
	//   {
	//     method: "POST",
	//     headers: headers,
	//     body: JSON.stringify(data),
	//   }
	// );
	// if (response.status == 200) {
	//   response = await response.json();
	// } else {
	//   ("error");
	//   response = [];
	// }
	// Refactored Code

	try {
		const response = await fetch(
			`${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}/search`,
			{
				method: "POST",
				headers: headers,
				body: JSON.stringify(data),
			}
		);

		if (response.status === 200) {
			const data = await response.json();
			return data;
		} else {
			throw new Error("Failed to search data sources");
		}
	} catch (error) {
		console.error("Error:", error);
	}

	return response;
}

export async function getWMSs(showHistorical) {
	let query = `SELECT name_english_ as name_, access_url, location_, ST_AsGeoJSON(geom) as geometry
  FROM datasources
  WHERE datasources.access_type_of_file = 'WMS'
  AND datasources.temporality_ = '${showHistorical}'`;

	const response = await post(
		`${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}`,
		JSON.stringify({
			sql: query,
		})
	);

	if (response.data.statusCode === 200) {
		return JSON.parse(response.data.body);
	} else {
		console.error(response.data.body);
		return [{ name_: "No WMSs Found", access_url: "" }];
	}
}

export async function getAllTags() {
	const response = await post(
		`${process.env.REACT_APP_DATASOURCES_DB_LAMBDA}/${process.env.REACT_APP_ENV}`,
		JSON.stringify({
			sql: `SELECT DISTINCT group_
          FROM datasources
          WHERE datasources.access_type_of_file = 'GeoJSON'`,
		})
	);

	if (response.data.statusCode === 200) {
		const tags = {};
		JSON.parse(response.data.body).forEach((t) => (tags[t.group_] = false));
		return tags;
	} else {
		console.error(response.data.body);
		return {};
	}
}

export async function createUserStyle(
	style,
	styleName,
	layers,
	baseStyleURL,
	ownerId,
	camera
) {
	return API.graphql(
		graphqlOperation(createStyle, {
			input: {
				name: styleName,
			},
		})
	)
		.then((d) =>
			s3
				.upload({
					Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME,
					Key: ownerId + "/styles/" + d.data.createStyle.id + ".json",
					Body: JSON.stringify(style),
				})
				.promise()
		)
		.catch((err) => {
			console.log(err, "Error in upload");
		})

		.catch((err) => {
			console.log(err, "Error in upload");
		})

		.then((d) => {
			const styleId = d.key.split("/")[2].split(".")[0];
			return API.graphql({
				query: updateStyle,
				variables: {
					input: {
						id: styleId,
						name: styleName,
						ownerId: ownerId,
						s3URL: d.Location,
						layers: JSON.stringify({
							layers: layers,
							camera: camera,
						}),
						baseStyleURL: baseStyleURL,
					},
				},
			}).catch((err) => {
				console.log(err, "Error in styleId");
			});
		})
		.catch((err) => {
			console.log(err, "Error in styleId");
		})
		.then((d) => d.data.updateStyle.id)
		.catch((err) => {
			console.log(err, "Error in createUserStyle");
		});
}

	export async function updateUserStyle(
		styleId,
		style,
		styleName,
		layers,
		baseStyleURL,
		ownerId,
		camera
	) {
		try {
			await s3
				.upload({
					Bucket: process.env.REACT_APP_USER_DATA_BUCKET_NAME,
					Key: `${ownerId}/styles/${styleId}.json`,
					Body: JSON.stringify(style),
				})
				.promise();

			const result = await API.graphql({
				query: updateStyle,
				variables: {
					input: {
						id: styleId,
						name: styleName,
						layers: JSON.stringify({ layers, camera }),
						baseStyleURL,
					},
				},
			});

			return result.data.updateStyle.id;
		} catch (err) {
			console.log(err);
		}
	}


export const getUserStyle = async (styleId) => {
	try {
		const response = await API.graphql({
			query: getStyle,
			variables: { id: styleId },
		});
		if (response.data.getStyle.id) {
			return {
				name: response.data.getStyle.name,
				baseStyleURL: response.data.getStyle.baseStyleURL,
				layers: JSON.parse(response.data.getStyle.layers).layers,
				id: response.data.getStyle.id,
			};
		} else {
			return "error";
		}
	} catch (error) {
		console.error(error);
	}
};

export async function listUserStyles(ownerId) {
	const newStyles = [];
	const limit = 200;
	const getStyles = (limit, nextToken) =>
		API.graphql({
			query: listStyles,
			variables: {
				filter: { ownerId: { eq: ownerId } },
				limit: limit,
				nextToken: nextToken,
			},
		});

	const response = await getStyles();
	newStyles.push.apply(newStyles, response.data.listStyles.items);
	var newNextToken = response.data.listStyles.nextToken;

	while (newStyles.length < limit && newNextToken) {
		const response = await getStyles(limit, newNextToken);
		newStyles.push.apply(newStyles, response.data.listStyles.items);
		newNextToken = response.data.listStyles.nextToken;
	}
	return {
		nextToken: newNextToken,
		styles: newStyles,
	};
}

export async function deleteUserStyle(styleId) {
	return API.graphql({
		query: deleteStyle,
		variables: {
			input: { id: styleId },
		},
	});
}

export const getUserLayer = async (layerId) => {
	try {
		const response = await API.graphql({
			query: getLayer,
			variables: { id: layerId },
		});
		return response.data.getLayer;
	} catch (error) {
		console.error(error);
	}
};
export const createUserLayer = async (layer, ownerId) => {
	try {
		let modifyLayer = {};
		if (layer.type.value == "raster") {
			modifyLayer.id = layer.id;
			modifyLayer.name = layer.name || "Layer Name";
			modifyLayer.location = layer.location;

			modifyLayer.fileType = layer.fileType;
			modifyLayer.type = layer.type;

			modifyLayer.show = layer.show || true;

			modifyLayer.opacity = layer.opacity || 1;
			modifyLayer.boundingbox = layer.boundingbox;
			modifyLayer.source = {};
			modifyLayer.source.id = layer.source.id;
			modifyLayer.source.type = layer.source.type;
			modifyLayer.source.tiles = layer.source.tiles || [];
			modifyLayer.source.tileSize = layer.source.tileSize;
		} else {
			modifyLayer.id = layer.id;

			modifyLayer.name = layer.name || "Layer Name";
			modifyLayer.location = layer.location || "Layer Location";

			modifyLayer.fileType = layer.fileType;
			modifyLayer.type = layer.type;
			modifyLayer.show = layer.show || true;
			if (layer.type.value != "line") {
				modifyLayer.fillColor = layer.fillColor || generateColorRandom();
			}
			modifyLayer.lineColor = layer.lineColor || generateColorRandom();
			if (layer.type.value != "invert") {
				modifyLayer.selectColor = layer.selectColor || generateColorRandom();
				modifyLayer.featureColors = layer.featureColors;
			}
			modifyLayer.boundingbox = layer.boundingbox;
			modifyLayer.urlSource = layer.urlSource;
			modifyLayer.opacity = layer.opacity || 1;
			modifyLayer.source = {};
			modifyLayer.source.id = layer.source.id;
			modifyLayer.source.type = layer.source.type;
			modifyLayer.source.data = [];
		}
		const createResponse = await API.graphql({
			query: createLayer,
			variables: {
				input: {
					ownerId: ownerId,
					properties: JSON.stringify(modifyLayer),
				},
			},
		});

		const dbLayerId = createResponse.data.createLayer.id;
		const updateResponse = await API.graphql({
			query: updateLayer,
			variables: {
				input: {
					id: dbLayerId,
					ownerId: ownerId,
					properties: JSON.stringify({
						...modifyLayer,
						dbLayerId: dbLayerId,
					}),
				},
			},
		});
		return updateResponse.data.updateLayer.id;
	} catch (error) {
		console.error(error);
	}
};

export const updateUserLayer = async (layer, ownerId) => {
	try {
		let modifyLayer = {};
		if (layer.type.value == "raster") {
			modifyLayer.id = layer.id;

			modifyLayer.name = layer.name || "Layer Name";
			modifyLayer.location = layer.location || "Layer Location";

			modifyLayer.fileType = layer.fileType;
			modifyLayer.type = layer.type;
			modifyLayer.show = layer.show || true;

			modifyLayer.opacity = layer.opacity || 1;
			modifyLayer.boundingbox = layer.boundingbox;
			modifyLayer.source = {};
			modifyLayer.source.id = layer.source.id;
			modifyLayer.source.type = layer.source.type;
			modifyLayer.source.tiles = layer.source.tiles;
			modifyLayer.source.tileSize = layer.source.tileSize || 256;
			modifyLayer.dbLayerId = layer.dbLayerId;
		} else {
			modifyLayer.id = layer.id;

			modifyLayer.name = layer.name || "Layer Name";

			modifyLayer.fileType = layer.fileType;
			modifyLayer.type = layer.type;
			modifyLayer.show = layer.show || true;
			if (layer.type.value != "line") {
				modifyLayer.fillColor = layer.fillColor || generateColorRandom();
			}
			modifyLayer.lineColor = layer.lineColor || generateColorRandom();
			if (layer.type.value != "invert") {
				modifyLayer.selectColor = layer.selectColor || generateColorRandom();
				modifyLayer.featureColors = layer.featureColors;
			}
			modifyLayer.boundingbox = layer.boundingbox;
			modifyLayer.urlSource = layer.urlSource;
			modifyLayer.opacity = layer.opacity || 1;
			modifyLayer.source = {};
			modifyLayer.source.id = layer.source.id;
			modifyLayer.source.type = layer.source.type;
			modifyLayer.source.data = [];
			modifyLayer.dbLayerId = layer.dbLayerId;
		}
		const response = await API.graphql({
			query: updateLayer,
			variables: {
				input: {
					id: modifyLayer.dbLayerId,
					ownerId: ownerId,
					properties: JSON.stringify(modifyLayer),
				},
			},
		});
		return response.data.updateLayer.id;
	} catch (error) {
		console.error(error);
	}
};

export async function listUserLayers(ownerId, nextToken) {
	const newLayers = [];
	// let newNextToken = nextToken;
	var limit = 200;

	const getLayers = (limit, nextToken) =>
		API.graphql({
			query: listLayers,
			variables: {
				filter: { ownerId: { eq: ownerId } },
				limit: limit,
				nextToken: nextToken,
			},
		});

	const response = await getLayers();
	newLayers.push.apply(newLayers, response.data.listLayers.items);
	var newNextToken = response.data.listLayers.nextToken;

	while (newLayers.length < limit && newNextToken) {
		const response = await getLayers(limit, newNextToken);
		newLayers.push.apply(newLayers, response.data.listLayers.items);
		newNextToken = response.data.listLayers.nextToken;
	}
	return {
		nextToken: newNextToken,
		layers: newLayers,
	};
}

export async function deleteUserLayer(layerId) {
	return API.graphql({
		query: deleteLayer,
		variables: {
			input: { id: layerId },
		},
	});
}

// export async function getLayerFeatures(url) {
// 	let response = await fetch(`${url}`);

// 	if (response.status == 200) {
// 		response = await response.json();
// 	}
// 	return response;
// }

// Refactored Code

export async function getLayerFeatures(url) {
	try {
		const response = await fetch(url);
		if (response.status === 200) {
			const data = await response.json();
			return data;
		} else {
			throw new Error(`Response status ${response.status}`);
		}
	} catch (error) {
		console.error(error);
		throw new Error("Failed to fetch layer features");
	}
}


export async function getHostedMap(html, ownerId) {
const base64 = btoa(encodeURIComponent(html));
	const body = { file: base64 };
	let data = null;

	try {
		const response = await fetch(
			`${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/host_static_webmap`,
			{
				method: "POST",
				body: JSON.stringify(body),
			}
		);
		data = await response.json();
	} catch (error) {
		console.error("Error:", error);
	}

	return data || {};
}