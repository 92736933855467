import styles from "./styles.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField, Typography } from "@mui/material";

export default function Issue(props) {


	const maxLength = 200;

	const submitFeedback = () => {
		props.save();
		props.toggle("done");
		props.message.feedback = "";
	};

	const cleanInput = () => {
		props.message.feedback = "";
	};
	const actualinputLenght = maxLength - props.message.feedback.length;


	const actualinputLength = maxLength - props.message.feedback.length;

	return (
		<div className={styles.feedback_wrapper_issues_main}>
			<div className={styles.feedback_wrapper_issues}>
				<ArrowBackIcon
					style={{ color: "grey", cursor: "pointer" }}
					onClick={() => {
						props.toggle("menu"), cleanInput();
					}}
				/>
				<Typography
					variant="h6"
					className={styles.feedback_title}
					style={{ fontWeight: "bold" }}
					sx={{ color: "primary.main" }}>
					{props.icon}
					{props.title}
				</Typography>
				<CloseIcon
					style={{ color: "grey", cursor: "pointer" }}
					onClick={() => {
						props.toggle("menu");
						cleanInput();
					}}
				/>
			</div>
			<TextField
				onChange={({ target: { value } }) =>
					props.onChange({ feedback: value, type: props.type })
				}
				className="inputRounded"
				placeholder={props.placeholder}
				autoFocus
				style={{ width: "280px", borderWidth: "10px" }}
				id="outlined-basic"
				variant="outlined"
				multiline
				rows={2}
				value={props.message.feedback}
				name="issues"
				inputProps={{ maxLength: 200 }}
			/>

			<Typography
				variant="body1"
				sx={{ color: actualinputLength <= 15 ? "error.main" : "text.primary" }}>
				Max number of characters: {`${actualinputLength}`}
			</Typography>

			<Button
				disabled={props.message.feedback.length >= 1 ? false : true}
				style={{ boxShadow: "none", marginTop: "9px", borderRadius: "20px" }}
				variant="contained"
				color="primary"
				onClick={submitFeedback}>
				Send Feedback
			</Button>
		</div>
	);

}

