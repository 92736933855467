import {
  Box,
  Button,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useState, useEffect } from "react";
import Map, { useControl } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { CreateGeojsonFromDraw } from "../../MapBox/MapBoxSource";
import mapBoxSource from "../../MapBox/MapBoxSource";
import { LayerType } from "../../../../lib/LayerType";
import { addLayer } from "../../../../redux/slices/layers";
import { useColor } from "../../../../lib/hooks";
import BettermapsModal from "../../BettermapsModal";
import { v4 as randomID } from "uuid";
import { UploadDrawToS3 } from "../../../../services/DigitalMapService";
import DrawLayerDetailsModal from "./DrawLayerDetailsModal";
import generateColorRandom from "../../../../helpers/generateColorRandom";

export default function DrawLayerModal(props) {
	const state = useSelector((state) => state.maps);
	const userData = useSelector((state) => state.user);
	const { mapStyle } = useSelector((state) => state.style);
	const { ownerId } = useSelector((state) => state.user);
	const myDrawMap = useMap()["Draw-map"];
	const dispatch = useDispatch();
	var lat = parseFloat(state.mapCenter.lat) || 40;
	var long = parseFloat(state.mapCenter.long) || -3;
	var zoom = parseFloat(state.mapZoom) || 2;
	var bearing = parseFloat(state.mapBearing) || 0;
	var pitch = parseFloat(state.mapPitch) || 0;
	const [layerType, setLayerType] = useState(LayerType.Circle);
	const [lineColor, setLineColor] = useColor("#02c100");
	const [fillColor, setFillColor] = useColor("#cd0000");
	const [line, setLine] = useState(false);
	const [polygon, setPolygon] = useState(false);
	const [marker, setMarker] = useState(true);
	const [layerName, setLayerName] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [features, setFeatures] = useState([]);
	const [openModalDetails, setOpenModalDetails] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	var newFeatures = [];
	var url = "";
	var boundingBox = [];

	function DrawControl(props) {
		useControl(() => new MapboxDraw(props), {
			position: props.position,
		});

		return null;
	}
	//   const moveEnd = () => {
	//     dispatch(
	//       getMapData(
	//         myMap.getZoom(),
	//         myMap.getCenter(),
	//         myMap.getPitch(),
	//         myMap.getBearing()
	//       )
	//     );
	//   };
	const onDrawCreate = ({ features }) => {
		var newFeature = {
			id: features[0].id,
			type: features[0].geometry.type,
			coordinates: features[0].geometry.coordinates,
		};
		newFeatures.push(newFeature);
	};

	const onDrawUpdate = ({ features }) => {
		var objIndex = newFeatures.findIndex((obj) => obj.id == features[0].id);
		newFeatures[objIndex].coordinates = features[0].geometry.coordinates;
	};
	const onDrawDelete = ({ features }) => {
		newFeatures = newFeatures.filter((obj) => obj.id == features[0].id);
	};

	useEffect(() => {
		if (!myDrawMap) {
		} else {
			myDrawMap.on("draw.create", onDrawCreate);
			myDrawMap.on("draw.delete", onDrawDelete);
			myDrawMap.on("draw.update", onDrawUpdate);
		}
	}, [myDrawMap]);

	const handleSaveLayer = () => {
		setIsLoading(true);
		setFeatures(newFeatures);
		setOpenModalDetails(true);
		analytics.identify("click on Save as Layer in Draw your layer", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const createGeojson = async () => {
		if (features.length > 0) {
			const d = await CreateGeojsonFromDraw(features);
			if (d) {
				createUrl(d);
			} else {
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
			enqueueSnackbar(`You need to draw some layer`, {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			});
			handleClose();
		}
	};

	const createUrl = async (d) => {
		var name = "";
		if (layerName == "") {
			name = "Custom Layer";
		} else {
			name = "layerName";
		}
		try {
			const g = await UploadDrawToS3(d, ownerId, name);
			if (g) {
				boundingBox = g.data.body.boundingBox;
				url = g.data.body.url;
				createSourceData(d);
			} else {
				setIsLoading(false);
				enqueueSnackbar(
					`There was a problem creating your layer, please try again`,
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
				handleClose();
			}
		} catch (e) {
			console.error(e);
			setIsLoading(false);
			enqueueSnackbar(
				`There was a problem creating your layer, please try again`,
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
			handleClose();
		}
	};

	const createSourceData = async (s) => {
		try {
			const data = await mapBoxSource(s, "random");
			if (data) {
				createLayer(data);
			} else {
				setIsLoading(false);
				enqueueSnackbar(
					`There was a problem creating your layer, please try again`,
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
				handleClose();
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
			enqueueSnackbar(
				`There was a problem creating your layer, please try again`,
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
			handleClose();
		}
	};

	const reopen = () => {
		props.reopenDrawModal();
	};

	const createLayer = (d) => {
		const layerID = `bettermaps-layer-${randomID()}`;
		const sourceID = randomID();
		const newLayer = {
			id: layerID,
			name: layerName,
			fileType: "GEOJSON",
			type: layerType,
			show: true,

			fillColor: fillColor.rgb || generateColorRandom(),
			lineColor: lineColor.rgb || generateColorRandom(),

			selectColor: "random",
			featureColors: d.featureColors || [],
			boundingbox: boundingBox,
			urlSource: url,
			opacity: 1 || d.opacity,
			source: {
				id: sourceID,
				type: "geojson",
				data: d.data,
			},
			showOnHover: false,
			hoverProperties: [],
		};
		(newFeatures = []), (url = "");
		boundingBox = [];
		dispatch(addLayer(newLayer));
		setLayerName("");
		setFeatures([]);
		handleClose();
	};

	const handleClose = () => {
		setIsLoading(false);
		props.setShow(false);
		analytics.identify("click on Close Modal in Draw your layer", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const handleLayerType = (type) => {
		if (type == "circle") {
			setLayerType(LayerType.Circle);
			analytics.identify("click on MAKER", {
				id: userData.ownerId,
				subscription: userData.subscription,
			});
		} else if (type == "polygon") {
			setLayerType(LayerType.Fill);
			analytics.identify("click on POLYGON", {
				id: userData.ownerId,
				subscription: userData.subscription,
			});
		} else {
			setLayerType(LayerType.Line);
			analytics.identify("click on LINE", {
				id: userData.ownerId,
				subscription: userData.subscription,
			});
		}
		reopen();
	};

	useEffect(() => {
		if (layerType.value == "line") {
			setLine(true);
			setMarker(false);
			setPolygon(false);
		} else if (layerType.value == "circle") {
			setLine(false);
			setMarker(true);
			setPolygon(false);
		} else if (layerType.value == "fill") {
			setLine(false);
			setMarker(false);
			setPolygon(true);
		}
	}, [layerType]);

	return (
		<BettermapsModal
			sx={{ width: "1000px" }}
			title="DRAW YOUR LAYER"
			showModal={props.show}
			setShowModal={props.setShow}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Box
					sx={{
						width: "60%",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						mb: "30px",
					}}>
					<Box>
						<Button
							onClick={() => handleLayerType("circle")}
							variant={marker == true ? "contained" : "outlined"}
							// size="small"
							sx={{
								mr: "10px",
							}}>
							Marker
						</Button>
						<Button
							variant={line == true ? "contained" : "outlined"}
							// size="small"
							sx={{
								mr: "10px",
							}}>
							Line
						</Button>
						<Button
							onClick={() => handleLayerType("polygon")}
							variant={polygon == true ? "contained" : "outlined"}
							// size="small"
						>
							Polygon
						</Button>
					</Box>
					{/* <Box sx={{ ml: '15px' }}>
						<TextField
							label="Layer name"
							value={layerName}
							size="small"
							onChange={(e) => setLayerName(e.target.value)}></TextField>
					</Box> */}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						border: "1px solid black",
						width: "900px",
						height: "550px",
					}}>
					<Map
						id={"Draw-map"}
						initialViewState={{
							longitude: long,
							latitude: lat,
							zoom: zoom,
							bearing: bearing,
							pitch: pitch,
						}}
						mapStyle={
							mapStyle ||
							process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
						}
						// onMove={(evt) => setViewport(evt.viewState)}
						// onMoveEnd={moveEnd}
						attributionControl={false}>
						<DrawControl
							position="top-right"
							displayControlsDefault={false}
							controls={{
								polygon: polygon,
								point: marker,
								line_string: line,
								trash: true,
							}}
						/>
					</Map>
				</Box>
				<Box sx={{ display: "flex", mt: "30px" }}>
					<Button
						variant="contained"
						color="error"
						sx={{ mr: "20px" }}
						onClick={handleClose}>
						Close
					</Button>
					{isLoading ? (
						<CircularProgress sx={{}}></CircularProgress>
					) : (
						<Button
							variant="contained"
							onClick={handleSaveLayer}
							// disabled={
							//   layerName != "" && isThereFeatures == true ? false : true
							// }
						>
							Save as Layer
						</Button>
					)}
				</Box>
			</Box>
			<DrawLayerDetailsModal
				show={openModalDetails}
				setShow={setOpenModalDetails}
				setLayerName={setLayerName}
				layerName={layerName}
				done={createGeojson}
				setIsLoading={setIsLoading}
				setFeatures={setFeatures}
				setShowModal={props.setShow}
			/>
		</BettermapsModal>
	);
}
