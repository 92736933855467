import DeleteIcon from "@mui/icons-material/Delete";
import {
	Box,
	Container,
	IconButton,
	Tooltip,
	Typography,
	CircularProgress,
} from "@mui/material";
import * as turf from "@turf/turf";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import DeleteLayerModal from "../components/common/CreateMap/modals/DeleteLayerModal";
import TablePaginationActions from "../components/DataLayerPage/TablePaginationActions";
import {
	listUserLayers,
	getGeoJSONs,
	getLayerFeatures,
} from "../services/DatasourcesService";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { Auth } from "aws-amplify";
import { v4 as randomID } from "uuid";
import MapBoxSource from "../components/common/MapBox/MapBoxSource";
import { addLayer } from "../redux/slices/layers";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { setPlanInfo } from "../redux/slices/user";
import { countInfo } from "../services/StripeService";
import formatDate from "../helpers/formatDate";

export default function DataLayersPage() {
	const dispatch = useDispatch();
	const { subscription } = useSelector((state) => state.user);
	const { ownerId } = useSelector((state) => state.user);
	const { layers } = useSelector((state) => state.layers);
	const [layersCollection, setLayersCollection] = useState([]);
	const history = useHistory();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [nextToken, setNextToken] = useState();
	const [showDeleteLayerModal, setShowDeleteLayerModal] = useState(false);
	const [layerToDeleteId, setLayerToDeleteId] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoading2, setIsLoading2] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const userData = useSelector((state) => state.user);

	const columns = [
		{ id: "name", value: "Name" },
		{ id: "location", value: "Location" },
		{ id: "fileType", value: "Type" },
		{ id: "updatedAt", value: "LastUpdate" },
		{ id: "actions", value: "Actions" },
	];

	useEffect(() => {
		const handleSubscriptionCheck = async () => {
			if (subscription) {
				const allowedSubscriptions = ["standard", "explorer", "pro"];
				if (!allowedSubscriptions.includes(subscription)) {
					enqueueSnackbar(
						"To use Bettermaps you need to choose a subscription plan",
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "error",
						}
					);
					history.push("/subscriptions");
				}
			} else {
				try {
					const user = await Auth.currentAuthenticatedUser();
					const c = await countInfo(user.attributes.email);
					const allowedPlans = ["standard", "explorer", "pro"];
					if (allowedPlans.includes(c.plan)) {
						dispatch(setPlanInfo(c));
						// history.push("/dashboard");
					} else {
						history.push("/subscriptions");
					}
				} catch (error) {
					console.log(error, "handleSubscriptionCheck");
				}
			}
		};
		handleSubscriptionCheck();
	}, [subscription]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const ownerIdToUse =
					ownerId || (await Auth.currentAuthenticatedUser()).username;
				const data = await listUserLayers(ownerIdToUse);

				// const sortedLayers = formattedLayers.sort(compare);
				const sortedLayers = data.layers.sort(compare);
				const parsePropertiesObject = (obj) => {
					const parsedProperties = JSON.parse(obj.properties);
					return { ...obj, properties: parsedProperties };
				};
				const parsedArray = sortedLayers.map(parsePropertiesObject);

				let hash = {};
				const noCopyInLayerName = parsedArray.filter(function (obj) {
					const layerName = obj.properties.name;
					if (!hash[layerName]) {
						hash[layerName] = true;
						return !layerName.includes("(copy)");
					}
					return false;
				});
				setLayersCollection(noCopyInLayerName);
				// setNextToken(data.nextToken);
				setIsLoading(false);
			} catch (error) {
				console.log(error, "fetchData");
			}
		};

		fetchData();
	}, []);

	function compare(a, b) {
		if (a.updatedAt > b.updatedAt) {
			return -1;
		}
		if (a.updatedAt < b.updatedAt) {
			return 1;
		}
		return 0;
	}
	function hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}
	// const handleChangePage = (event, newPage) => {
	//   setPage(newPage);
	// };

	// const handleChangeRowsPerPage = (event) => {
	//   setRowsPerPage(+event.target.value);
	//   listUserLayers(event.target.value).then((d) => {
	//     setLayers(d.layers);
	//     setNextToken(d.nextToken);
	//   });
	//   setPage(0);
	// };

	const handleDeleteLayer = (layerId) => {
		setLayerToDeleteId(layerId);
		setShowDeleteLayerModal(true);
		analytics.identify(`Your Layers - delete layer: ${layerId}`, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	const removeLayerFromTable = async () => {
		setIsLoading2(true);
		try {
			const data = await listUserLayers(ownerId);
			const sortedLayers = data.layers.sort(compare);
			const parsePropertiesObject = (obj) => {
				const parsedProperties = JSON.parse(obj.properties);
				return { ...obj, properties: parsedProperties };
			};
			const parsedArray = sortedLayers.map(parsePropertiesObject);

			setLayersCollection(parsedArray);
			setNextToken(data.nextToken);
			setIsLoading2(false);
		} catch (error) {
			console.log(error, "removeLayerFromTable");
			setIsLoading2(false);
		}
	};

	const layerNotComplete = () => {
		setIsLoading2(false);
		enqueueSnackbar(
			"This layer is broken, Bettermaps team has been notified to solve this issue",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	};

	const handleAddLayer = async (id) => {
		setIsLoading2(true);

		const objIndex = layersCollection.findIndex((obj) => obj.id == id);
		const copyLayers = [...layersCollection];
		const chosenLayer = copyLayers[objIndex].properties;

		if (chosenLayer.type.value == "raster") {
			const newLayer = {
				id: `bettermaps-layer-${randomID()}`,
				name: chosenLayer.name,
				location: chosenLayer.location,
				fileType: chosenLayer.fileType,
				type: chosenLayer.type,
				show: chosenLayer.show,
				opacity: chosenLayer.opacity,
				source: {
					id: randomID(),
					type: chosenLayer.source.type,
					tiles: chosenLayer.source.tiles,
					tileSize: chosenLayer.source.tileSize,
				},
				boundingbox: chosenLayer.boundingbox,
			};
			const isComplete = Object.values(newLayer).every(
				(value) => value !== undefined
			);
			if (isComplete) {
				await dispatch(addLayer(newLayer));
				changePage();
			} else {
				layerNotComplete();
			}
		} else if (chosenLayer.type.value == "invert") {
			const fillColor = chosenLayer.fillColor.r
				? chosenLayer.fillColor
				: hexToRgb(chosenLayer.fillColor);
			const lineColor = chosenLayer.lineColor.r
				? chosenLayer.lineColor
				: hexToRgb(chosenLayer.lineColor);

			const newLayer = {
				id: `bettermaps-layer-${randomID()}`,
				name: chosenLayer.name,
				location: chosenLayer.location,
				fileType: chosenLayer.fileType,
				type: chosenLayer.type,
				show: chosenLayer.show,
				fillColor,
				lineColor,
				boundingbox: chosenLayer.boundingbox,
				urlSource: chosenLayer.urlSource,
				source: {
					id: randomID(),
					type: chosenLayer.source.type,
					data: chosenLayer.urlSource,
				},
			};

			dispatch(addLayer(newLayer));
			changePage();
		} else {
			const filters = { name: chosenLayer.name, location: "", geometry: "" };

			try {
				const data = await getGeoJSONs([], false, filters);

				if (data.length === 0) {
					layerNotComplete();
				} else {
					const polygon = turf.polygon(
						JSON.parse(data[0].geometry).coordinates
					);
					const bb = turf.bbox(polygon);
					const f = await getLayerFeatures(data[0].access_url);

					const selectColor = chosenLayer.selectColor
						? chosenLayer.selectColor
						: "random";
					const fillColor =
						chosenLayer.fillColor && chosenLayer.fillColor.r
							? chosenLayer.fillColor
							: hexToRgb(chosenLayer.fillColor);
					const lineColor =
						chosenLayer.lineColor && chosenLayer.lineColor.r
							? chosenLayer.lineColor
							: hexToRgb(chosenLayer.lineColor);

					const color =
						chosenLayer.type.value == "line" ? lineColor : fillColor;

					const s = await MapBoxSource(f, selectColor, color);

					const newLayer = {
						id: `bettermaps-layer-${randomID()}`,
						name: chosenLayer.name,
						location: chosenLayer.location,
						fileType: chosenLayer.fileType,
						type: chosenLayer.type,
						show: chosenLayer.show,
						fillColor,
						lineColor,
						selectColor,
						featureColors: s.featureColors,
						boundingbox: chosenLayer.boundingbox || bb,
						urlSource: data[0].access_url,
						opacity: chosenLayer.opacity,
						source: {
							id: randomID(),
							type: chosenLayer.source.type,
							data: s.data,
						},
						showOnHover: false,
						hoverProperties: [],
					};
					await dispatch(addLayer(newLayer));
					changePage();
				}
			} catch (error) {
				// Handle error
				console.error(error);
			}
		}

		analytics.identify(`Your Layers - Add Layer: ${id} `, {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	// const getFollowingEntries = () => {
	//   listUserLayers(rowsPerPage, nextToken).then((d) => {
	//     setLayers([...layers, ...d.layers]);
	//     setNextToken(d.nextToken);
	//   });
	// };
	const changePage = () => {
		history.push("/create-map");
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.secondary.main,
		},
	}));

	let auxObj = {};

	for (let i = 0; i < layersCollection.length; i++) {
		var obj = layersCollection[i];
		var name = obj.properties.name;
		var location = obj.properties.location;

		if (auxObj[name] && auxObj[name] !== location) {
		} else if (auxObj[name] && auxObj[name] === location) {
			layersCollection.splice(i, 1);
			i--;
		} else {
			auxObj[name] = location;
		}
	}

	const noDuplesLayersNameFormatted = layersCollection.map((layer) => {
		if (layer.updatedAt) {
			return {
				...layer,
				updatedAt: formatDate(layer.updatedAt),
			};
		}
		return layer;
	});

	return (
		<Container sx={{ paddingTop: "32px", width: "90%" }}>
			<Typography variant="h4" sx={{ mt: 1, mb: 5 }}>
				Your Layers
			</Typography>

			<TableContainer
				sx={{
					minHeight: 120,
					maxHeight: "75vh",
					borderStyle: "solid",
					borderCollapse: "primary.main",
					borderRadius: "6px",
				}}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										paddingLeft: column.id === "name" ? "50px" : "",
										paddingLeft: column.id === "fileType" ? "20px" : "",

										bgcolor: "primary.main",
										color: "white",
										pt: "8px",
										pb: "8px",
									}}
									key={column.id}
									align="left"
									style={{
										width: column.id == "actions" ? "140px" : "500px",
									}}>
									{column.value}
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{noDuplesLayersNameFormatted.map((layer) => {
							return (
								<StyledTableRow role="checkbox" tabIndex={-1} key={layer.id}>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align="left"
											style={{
												width: column.id == "actions" ? "140px" : "500px",
												paddingLeft: column.id === "updatedAt" ? "26px" : "",
											}}>
											{column.id == "actions" ? (
												<Box sx={{ display: "flex" }}>
													<Tooltip title="Add to Map">
														<IconButton
															color="primary"
															sx={{ pt: 0, pb: 0 }}
															onClick={() => handleAddLayer(layer.id)}>
															<AddIcon />
														</IconButton>
													</Tooltip>
													<Tooltip title="Delete layer">
														<IconButton
															color="primary"
															sx={{ pt: 0, pb: 0 }}
															onClick={() => handleDeleteLayer(layer.id)}>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</Box>
											) : (
												<Box>
													{column.id === "updatedAt" ? (
														<Box>{layer[column.id]}</Box>
													) : (
														<div>
															<Box>{layer.properties[column.id]}</Box>
														</div>
													)}
												</Box>
											)}
										</TableCell>
									))}
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>

			{isLoading && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						width: "100%",
						pl: "20px",
						mt: "5rem",
						// mr: 10,
					}}>
					<CircularProgress></CircularProgress>
				</Box>
			)}
			<Box>
				{!isLoading && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							width: "100%",
							pr: "20px",
							mt: 1,
							// mr: 10,
						}}>
						<Typography>
							Number of Layers: {noDuplesLayersNameFormatted.length}
						</Typography>
					</Box>
				)}
				{isLoading2 && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							pl: "20px",
							mt: "5rem",
							// mr: 10,
						}}>
						<CircularProgress></CircularProgress>
					</Box>
				)}
			</Box>
			{/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => (
          <TablePaginationActions
            entries={layers}
            nextToken={nextToken}
            getFollowingEntries={getFollowingEntries}
            {...props}
          />
        )}
        showFirstButton={false}
        showLastButton={false}
      /> */}
			<DeleteLayerModal
				show={showDeleteLayerModal}
				setShow={setShowDeleteLayerModal}
				layerToDeleteId={layerToDeleteId}
				removeLayerFromTable={removeLayerFromTable}></DeleteLayerModal>
		</Container>
	);
}
