import { useSelector } from 'react-redux';

import { AccountCircle } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logout from '@mui/icons-material/Logout';
import Mail from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Notifications from '@mui/icons-material/Notifications';
import {
	Avatar,
	Menu,
	MenuItem,
	Stack,
	SvgIcon,
	Tooltip,
	Typography,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Auth } from 'aws-amplify';
import { CostExplorer } from 'aws-sdk';
import React, { useEffect } from 'react';
import { Link as Route } from 'react-router-dom';
import { ReactComponent as BettermapsIconWhite } from '../../images/icons/BettermapsWhite.svg';
import logOut from '../../lib/logout';
import { pages } from '../../lib/pages';

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function Navigation({ children }) {
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const [email, setEmail] = React.useState('');
	const [givenName, setGivenName] = React.useState('');
	const [anchorEl, setAnchorEl] = React.useState(null);

	useEffect(() => {
		const fetchUserAttributes = async () => {
			try {
				const user = await Auth.currentAuthenticatedUser();
				setEmail(user.attributes.email);
				setGivenName(user.attributes.given_name);
			} catch (error) {
				console.error(error);
			}
		};
		fetchUserAttributes();
	}, []);


	const openMenu = Boolean(anchorEl);
	const userData = useSelector((state) => state.user);

	const handleDrawerOpen = () => {
		analytics.track('click open drawer', {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
		setOpen(true);
	};

	const handleDrawerClose = () => {
		analytics.track('click close drawer', {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
		setOpen(false);
	};

	const handleClick = (event) => {
		analytics.track('click on account settings');

		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
	}));

	return (
		<div>
			<CssBaseline />
			<AppBar position="fixed" open={open}>
				<Toolbar style={{ minHeight: "50px", height: "50px" }}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: "36px",
							...(open && { display: "none" }),
						}}>
						<MenuIcon />
					</IconButton>
					{/* <Typography variant="h6" noWrap component="div">
                        Bettermaps Dashboard
                    </Typography> */}
					<IconButton
						size="medium"
						sx={{ ml: 2 }}
						style={{
							width: "180px",
							marginLeft: "0",
							paddingLeft: "0",
						}}>
						<BettermapsIconWhite />
					</IconButton>
					<Box sx={{ flex: "1" }} />
					{/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}> */}
					<Stack direction="row" spacing={1}>
						<Tooltip title="Alerts">
							<IconButton
								style={{ display: "none" }}
								size="medium"
								sx={{ ml: 2 }}>
								<Notifications sx={{ color: "#fff" }} />
							</IconButton>
						</Tooltip>
						<Tooltip title="Notifications">
							<IconButton
								style={{ display: "none" }}
								size="medium"
								sx={{ ml: 2 }}>
								<Mail sx={{ color: "#fff" }} />
							</IconButton>
						</Tooltip>
						<Tooltip title="Account settings">
							<IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
								<Avatar
									sx={{
										width: 32,
										height: 32,
										backgroundColor: "#fff",
										color: "green",
									}}>
									{givenName
										? givenName.substring(0, 1).toUpperCase()
										: email.substring(0, 1).toUpperCase()}
								</Avatar>
							</IconButton>
						</Tooltip>
					</Stack>
					<Menu
						anchorEl={anchorEl}
						open={openMenu}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{ horizontal: "right", vertical: "top" }}
						anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
						<MenuItem>{email}</MenuItem>
						<MenuItem component={Route} to={"/account"}>
							<ListItemIcon>
								<AccountCircle
									fontSize="small"
									sx={{ bgcolor: theme.palette.primary }}
								/>
							</ListItemIcon>
							Account
						</MenuItem>
						<Divider />
						{/* <MenuItem>
                            <ListItemIcon>
                                <PersonAdd fontSize="small" />
                            </ListItemIcon>
                            Add another account
                        </MenuItem> */}
						<MenuItem onClick={logOut}>
							<ListItemIcon>
								<Logout fontSize="small" />
							</ListItemIcon>
							Logout
						</MenuItem>
						<Divider />
						<Box
							style={{
								display: "flex",
								justifyContent: "center",
								color: "grey",
							}}>
							<Typography
								variant="caption"
								color="grey">{`${process.env.REACT_APP_AWS_BRANCH}@${process.env.REACT_APP_AWS_COMMIT_ID}`}</Typography>
						</Box>
					</Menu>
				</Toolbar>
			</AppBar>
			<Box sx={{ display: "flex" }}>
				<Drawer variant="permanent" open={open}>
					<DrawerHeader style={{ minHeight: "50px", height: "50px" }}>
						<IconButton onClick={handleDrawerClose}>
							{theme.direction === "rtl" ? (
								<ChevronRightIcon />
							) : (
								<ChevronLeftIcon />
							)}
						</IconButton>
					</DrawerHeader>
					<Divider />
					<List>
						{/* {pages.map((text, i) =>
							text[0] == "Divider" ? (
								<Divider />
							) : (
								(process.env.REACT_APP_ENV !== "prod" || text[3] !== "dev") &&
								(text[0] == "Documentation" ? (
									process.env.REACT_APP_ENV == "dev" ? (
										<>
											<Divider />
											<ListItem
												button
												key={i}
												component="a"
												href="https://docs.bettermaps.ai"
												target="_blank"
												onClick={() =>
													analytics.identify(`click on drawer ${text[0]}`, {
														id: userData.ownerId,
														subscription: userData.subscription,
													})
												}>
												<ListItemIcon>
													<SvgIcon component={text[2]} />
												</ListItemIcon>
												<ListItemText
													primary={text[0]}
													primaryTypographyProps={{
														color: "primary",
														fontWeight: "medium",
													}}
												/>
											</ListItem>
										</>
									) : (
										<ListItem
											button
											key={i}
											component="a"
											href="https://docs.bettermaps.ai"
											target="_blank"
											onClick={() =>
												analytics.identify(`click on drawer ${text[0]}`, {
													id: userData.ownerId,
													subscription: userData.subscription,
												})
											}>
											<ListItemIcon>
												<SvgIcon component={text[2]} />
											</ListItemIcon>
											<ListItemText
												primary={text[0]}
												primaryTypographyProps={{
													color: "primary",
													fontWeight: "medium",
												}}
											/>
										</ListItem>
									)
								) : (
									<ListItem
										button
										key={i}
										component={Route}
										to={text[1]}
										onClick={() =>
											analytics.identify(`click on drawer ${text[0]}`, {
												id: userData.ownerId,
												subscription: userData.subscription,
											})
										}>
										<ListItemIcon>
											<SvgIcon component={text[2]} />
										</ListItemIcon>
										<ListItemText
											primary={text[0]}
											primaryTypographyProps={{
												color: "primary",
												fontWeight: "medium",
											}}
										/>
									</ListItem>
								))
							)
						)} */}

						{/* This is refactored code */}

						{pages.map((page, index) => {
							if (page[0] === "Divider") {
								return <Divider key={index} />;
							}

							const handleClick = () => {
								analytics.identify(`click on drawer ${page[0]}`, {
									id: userData.ownerId,
									subscription: userData.subscription,
								});
							};

							const listItemProps = {
								button: true,
								key: index,
								onClick: handleClick,
								component: page[0] === "Documentation" ? "a" : Route,
							};

							if (page[0] === "Documentation") {
								const isDev = process.env.REACT_APP_ENV === "dev";
								if (isDev) {
									return (
										<React.Fragment key={index}>
											<Divider />
											<ListItem
												{...listItemProps}
												href="https://docs.bettermaps.ai"
												target="_blank">
												<ListItemIcon>
													<SvgIcon component={page[2]} />
												</ListItemIcon>
												<ListItemText
													primary={page[0]}
													primaryTypographyProps={{
														color: "primary",
														fontWeight: "medium",
													}}
												/>
											</ListItem>
										</React.Fragment>
									);
								} else {
									return (
										<ListItem
											{...listItemProps}
											href="https://docs.bettermaps.ai"
											target="_blank">
											<ListItemIcon>
												<SvgIcon component={page[2]} />
											</ListItemIcon>
											<ListItemText
												primary={page[0]}
												primaryTypographyProps={{
													color: "primary",
													fontWeight: "medium",
												}}
											/>
										</ListItem>
									);
								}
							} else {
								return (
									<ListItem {...listItemProps} to={page[1]}>
										<ListItemIcon>
											<SvgIcon component={page[2]} />
										</ListItemIcon>
										<ListItemText
											primary={page[0]}
											primaryTypographyProps={{
												color: "primary",
												fontWeight: "medium",
											}}
										/>
									</ListItem>
								);
							}
						})}
					</List>
				</Drawer>
				<div style={{ width: "100%" }}>
					<Box sx={{ right: "0px", marginTop: "50px", width: "100%" }} />
					{children}
				</div>
			</Box>
		</div>
	);
}
