import { ThreeGMobiledataRounded } from "@mui/icons-material";
import post from "../clients/HttpClient";


// export async function createMapIntentFromDescription(
//   description,
//   ownerId,
//   email
// ) {
//   var isDev = "-dev";
//   if (process.env.REACT_APP_ENV == "prod") {
//     isDev = "";
//   }
//   var headers = {
//     authorizationToken: email,
//   };
//   var body = { text: description, ownerId: ownerId };

//   let response = await fetch(
//     `${process.env.REACT_APP_OPEN_AI_MAP_INTENT}/${process.env.REACT_APP_ENV}/map-intent-rev${isDev}`,
//     {
//       method: "POST",
//       headers: headers,
//       body: JSON.stringify(body),
//     }
//   );

//   if (response.status == 200) {
//     response = await response.json();
//     return response;
//   } else {
//     return response.status;
//   }
// }

// Refactored Code

export async function createMapIntentFromDescription(description, ownerId, email) {
	let isDev = "-dev";
	if (process.env.REACT_APP_ENV == "prod") {
		isDev = "";
	}
	const headers = {
		authorizationToken: email,
	};
	const body = { text: description, ownerId: ownerId };

	try {
		const response = await fetch(
			`${process.env.REACT_APP_OPEN_AI_MAP_INTENT}/${process.env.REACT_APP_ENV}/map-intent-rev${isDev}`,
			{
				method: "POST",
				headers: headers,
				body: JSON.stringify(body),
			}
		);

		if (response.status === 200) {
			const data = await response.json();
			return data;
		} else {
			return response.status;
		}
	} catch (error) {
		console.error(error);
		return error;
	}
}

export async function createMapFromIntent(mapIntent, ownerId) {
	var isDev = "-dev";
	if (process.env.REACT_APP_ENV == "prod") {
		isDev = "";
	}
	try {
		const response = await post(
			`${process.env.REACT_APP_OPEN_AI_MAP_INTENT}/${process.env.REACT_APP_ENV}/map-from-mapintent-rev${isDev}`,
			JSON.stringify({
				mapIntent: mapIntent,
				ownerId: ownerId,
			})
		);

		const data = await response.data;
		return data;
	} catch (error) {
		console.log(error, "Error in createMapFromIntent");
		return error;
	}
}

// export async function sendLogForAi(text, intent, answer, ownerId) {
//   const data = {
//     OwnerId: ownerId,
//     Accepted: answer,
//     MapIntentText: text,
//     MapIntentJson: intent,
//   };
//   let response = await fetch(
//     `${process.env.REACT_APP_OPEN_AI_MAP_INTENT}/${process.env.REACT_APP_ENV}/log_from_intent`,
//     {
//       method: "POST",
//       body: JSON.stringify(data),
//     }
//   );
// }

// Refactored Code

export async function sendLogForAi(text, intent, answer, ownerId) {
	const data = {
		OwnerId: ownerId,
		Accepted: answer,
		MapIntentText: text,
		MapIntentJson: intent,
	};
	try {
		const response = await fetch(
			`${process.env.REACT_APP_OPEN_AI_MAP_INTENT}/${process.env.REACT_APP_ENV}/log_from_intent`,
			{
				method: "POST",
				body: JSON.stringify(data),
			}
		);
		// handle response as needed
	} catch (error) {
		console.error("Error:", error);
	}
}