import { useState } from "react";
import { useSelector } from 'react-redux';

import { Button } from '@mui/material';
import Feedback from './Feedback';
import ChatIcon from '@mui/icons-material/Chat';

export default function MinimalFeedback({
	onChange,
	value,
	save,
	bottom,
	right,
	issueColor,
	ideaColor,
	otherColor,
}) {
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const userData = useSelector((state) => state.user);

	function toggleModal() {
		setShowFeedbackModal(!showFeedbackModal);
	}
	const handleFeedbackButton = () => {
		setShowFeedbackModal(true);
		value.feedback = "";
		analytics.identify("click on FEEDBACK", {
			id: userData.ownerId,
			subscription: userData.subscription,
		});
	};

	return (
		<>
			<Button
				startIcon={<ChatIcon />}
				sx={{ color: "primary.main" }}
				style={{
					marginTop: "9px",
					boxShadow: "none",
					position: "fixed",
					bottom: bottom,
					right: right,
					backgroundColor: "#FFF",
					borderRadius: "100px",
					boxShadow: "0px 0 1px 0.1rem rgba(94,43,250,.25)",
				}}
				onClick={handleFeedbackButton}
				variant="contained">
				Feedback
			</Button>
			<Feedback
				save={save}
				value={value}
				onChange={onChange}
				modal={showFeedbackModal}
				toggle={toggleModal}
				bottom={bottom}
				right={right}
				issueColor={issueColor}
				ideaColor={ideaColor}
				otherColor={otherColor}
			/>
		</>
	);
}
