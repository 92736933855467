import {
	Box,
	Button,
	Typography,
	Tooltip,
	Divider,
	Container,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BettermapsTextField from "../components/common/BettermapsTextField";
import { Analytics, Auth } from "aws-amplify";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { countInfo } from "../services/StripeService";
import { setPlanInfo, setEmail } from "../redux/slices/user";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import CancelSubscriptionModal from "../components/Stripe/CancelSubscriptionModal";
import { cancelSubscription } from "../services/StripeService";

export default function AccountPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { email } = useSelector((state) => state.user);
	const { subscription } = useSelector((state) => state.user);
	const { intentsAi } = useSelector((state) => state.user);
	const { intentsPrints } = useSelector((state) => state.user);
	const { intentsHtml } = useSelector((state) => state.user);
	const [name, setName] = useState("");
	// const [email, setEmail] = useState("");
	const [emailVerified, setEmailVerified] = useState(false);
	const [mapboxAPIKey, setMapboxAPIKey] = useState("");
	const [maptilerAPIKey, setMaptilerAPIKey] = useState("");
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [intentsAiLeft, setIntentsAiLeft] = useState("0");
	const [intentsHtmlLeft, setIntentsHtmlLeft] = useState("0");
	const [intentsPrintLeft, setIntentsPrintLeft] = useState("0");

	useEffect(() => {
		const fetchUser = async () => {
			try {
				const user = await Auth.currentAuthenticatedUser();
				const email = user.attributes.email;
				const name = user.attributes.given_name;
				const emailVerified = user.attributes.email_verified;
				const mapboxAPIKey = user.attributes["custom:mapboxAPIkey"];
				const maptilerAPIKey = user.attributes["custom:maptilerAPIkey"];
				setEmail(email);
				setName(name);
				setEmailVerified(emailVerified);
				if (mapboxAPIKey) setMapboxAPIKey(mapboxAPIKey);
				if (maptilerAPIKey) setMaptilerAPIKey(maptilerAPIKey);
				const c = await countInfo(email);
				dispatch(setPlanInfo(c));
				if (!subscription) {
					enqueueSnackbar(
						"To use Bettermaps you need to choose a subscription plan",
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "error",
						}
					);
					history.push("/subscriptions");
				}
			} catch (e) {
				console.error(e);
			}
		};

		if (subscription) {
			if (subscription === "standard") {
				setIntentsAiLeft(`${intentsAi}/20`);
				setIntentsHtmlLeft(`${intentsHtml}/5`);
				setIntentsPrintLeft(`${intentsPrints}/5`);
			} else if (subscription === "explorer") {
				setIntentsAiLeft(`${intentsAi}/200`);
				setIntentsHtmlLeft(`${intentsHtml}/50`);
				setIntentsPrintLeft(`${intentsPrints}/50`);
			} else if (subscription === "pro") {
				setIntentsAiLeft(`${intentsAi}/2000`);
				setIntentsHtmlLeft(`${intentsHtml}/500`);
				setIntentsPrintLeft(`${intentsPrints}/500`);
			}
		}

		fetchUser();
	}, [subscription]);

	const handleSaveUserSettings = async () => {
		try {
			const u = await Auth.currentAuthenticatedUser();
			await Auth.updateUserAttributes(u, {
				"custom:mapboxAPIkey": mapboxAPIKey,
				"custom:maptilerAPIkey": maptilerAPIKey,
			});
			enqueueSnackbar(
				"Settings were successfully saved! Redirect to Dashboard",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					autoHideDuration: 2000,
					variant: "success",
				}
			);
		} catch (e) {
			console.error(e);
			enqueueSnackbar(
				"Oups, we could not save your settings. Please try later...",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
	};

	const handleSaveButton = () => {
		setTimeout(() => {
			history.push("/dashboard");
		}, 200);
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Analyse Map Style</title>
			</Helmet>
			<Container
				disableGutters
				sx={{ bgcolor: "secondary.main" }}
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					maxWidth: "100%",
					height: "calc(100vh - 50px)",
					paddingTop: "15px",
					paddingLeft: "15px",
					overflow: "auto",
				}}>
				<Box
					disableGutters
					sx={{ bgcolor: "secondary.main" }}
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						maxWidth: "100%",
						width: "100%",
						paddingTop: "15px",
						paddingLeft: "15px",
						overflow: "auto",
					}}>
					<Box
						style={{
							backgroundColor: "white",
							borderRadius: "5px",
							padding: 30,
							width: "40%",
							maxWidth: "500px",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Typography>USER INFORMATION</Typography>
						<BettermapsTextField
							sx={{ mt: 2, backgroundColor: "#f4f4f4" }}
							label="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							readOnly></BettermapsTextField>
						<BettermapsTextField
							sx={{ mt: 2, backgroundColor: "#f4f4f4" }}
							label="User Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							endAdornment={
								emailVerified ? (
									<Tooltip title="Email verfied">
										<VerifiedIcon sx={{ color: "primary.main" }}></VerifiedIcon>
									</Tooltip>
								) : (
									<Tooltip title="Email not verified">
										<CancelIcon sx={{ color: "red" }}></CancelIcon>
									</Tooltip>
								)
							}
							readOnly></BettermapsTextField>

						<Typography sx={{ mt: 2 }}>SUBSCRIPTION</Typography>
						<BettermapsTextField
							sx={{ mt: 2, backgroundColor: "#f4f4f4" }}
							label="Subscription Plan"
							value={subscription}
							onChange={(e) => setName(e.target.value)}
							readOnly></BettermapsTextField>
						<BettermapsTextField
							sx={{ mt: 2, backgroundColor: "#f4f4f4" }}
							label="Ai intents"
							value={intentsAiLeft}
							onChange={(e) => setName(e.target.value)}
							readOnly></BettermapsTextField>
						<BettermapsTextField
							sx={{ mt: 2, backgroundColor: "#f4f4f4" }}
							label="Print intents"
							value={intentsPrintLeft}
							onChange={(e) => setName(e.target.value)}
							readOnly></BettermapsTextField>
						<BettermapsTextField
							sx={{ mt: 2, backgroundColor: "#f4f4f4" }}
							label="Standalone Website intents"
							value={intentsHtmlLeft}
							onChange={(e) => setName(e.target.value)}
							readOnly></BettermapsTextField>
						{subscription != "standard" && <CancelSubscriptionModal />}

						<Typography sx={{ mt: 2 }}>API KEYS</Typography>
						<BettermapsTextField
							sx={{ mt: 2 }}
							label="Mapbox API Key"
							value={mapboxAPIKey}
							onChange={(e) =>
								setMapboxAPIKey(e.target.value)
							}></BettermapsTextField>
						<BettermapsTextField
							sx={{ mt: 2 }}
							label="Maptiler API Key"
							value={maptilerAPIKey}
							onChange={(e) =>
								setMaptilerAPIKey(e.target.value)
							}></BettermapsTextField>
						<Button
							variant="contained"
							title="Save"
							onClick={handleSaveUserSettings}
							sx={{ borderRadius: 1, mt: 2, width: "68px" }}>
							<Typography variant="button" onClick={handleSaveButton}>
								Save
							</Typography>
						</Button>

						<Box
							style={{
								display: "flex",
								flexDirecion: "row",
								justifyContent: "space-between",
								marginTop: "16px",
								width: "100%",
							}}>
							{/* <Button
              variant="contained"
              disabled={true}
              title="Reset password"
              onClick={() => {}}
              sx={{ borderRadius: 1 }}
            >
              <Typography variant="button">Reset Password</Typography>
            </Button>
            <Button
              variant="contained"
              title="Delete user"
              onClick={() => {}}
              disabled
              sx={{ borderRadius: 1 }}
            >
              <Typography variant="button">Delete User</Typography>
            </Button> */}
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}
