import { useSelector } from "react-redux";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { sendFeedback } from "../../../services/UserFeedbackService";
import MinimalFeedback from "./MinimalFeedback";

export default function FeedbackManager(props) {
	const [feedback, setFeedback] = useState({ feedback: "" });
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const userData = useSelector((state) => state.user);

	const handleFeedbackSave = async () => {
		try {
			await sendFeedback({ ...feedback });
			enqueueSnackbar("Thanks for sharing your feedback!", {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "success",
			});
		} catch (e) {
			enqueueSnackbar(
				"There was an error when sending your feedback. Please try later",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
			console.error(e);
		}
		handleFeedBackIssue();
	};

	const handleFeedBackIssue = () => {
		analytics.identify(
			`feedback from ${feedback.type}: ${feedback.feedback} `,
			{
				id: userData.ownerId,
				subscription: userData.subscription,
			}
		);
	};

	return (
		<>
			<MinimalFeedback
				save={handleFeedbackSave}
				value={feedback}
				onChange={(e) => setFeedback((f) => ({ ...f, ...e }))}
				bottom={props.bottom}
				right={props.right}
				issueColor={props.issueColor}
				ideaColor={props.ideaColor}
				otherColor={props.otherColor}
			/>
		</>
	);
}
