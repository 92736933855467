import DeleteIcon from "@mui/icons-material/Delete";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
	Box,
	Container,
	Divider,
	IconButton,
	Tooltip,
	Typography,
	CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import DeleteStyleModal from "../components/common/CreateMap/modals/DeleteStyleModal";
import TablePaginationActions from "../components/DataLayerPage/TablePaginationActions";
import DataSourceCard from "../components/DataSources/DataSourceCard";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import formatDate from "../helpers/formatDate";

import {
	listUserStyles,
	getUserStyle,
	getLayerFeatures,
} from "../services/DatasourcesService";
import { useSelector, useDispatch } from "react-redux";
import { setStyleId, setStyleName, setMapStyle } from "../redux/slices/style";
import { resetLayers, setlayers, addLayer } from "../redux/slices/layers";
import { resetBoundingBox } from "../redux/slices/boundingBox";
import {
	resetNewMap,
	setMapBearing,
	setMapPitch,
	setMapCenter,
	setMapZoom,
} from "../redux/slices/map";
import { Auth } from "aws-amplify";
import { CreateDataSourceFromStyles } from "../components/common/MapBox/MapBoxSource";
import MapBoxSource from "../components/common/MapBox/MapBoxSource";
import { getGeoJSONs } from "../services/DatasourcesService";
import { useSnackbar } from "notistack";
import TableSortLabel from "@mui/material/TableSortLabel";
import { setPlanInfo } from "../redux/slices/user";
import { countInfo } from "../services/StripeService";
// import { DataGrid } from "@mui/x-data-grid";

export const StylesPage = () => {
	const dispatch = useDispatch();
	const { subscription, ownerId } = useSelector((state) => state.user);
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("updatedAt");
	const [styles, setStyles] = useState([]);
	const history = useHistory();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [nextToken, setNextToken] = useState();
	const [showDeleteStyleModal, setShowDeleteStyleModal] = useState(false);
	const [styleToDeleteId, setStyleToDeleteId] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [isLoading2, setIsLoading2] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [formattedStyleDate, setFormattedStyleDate] = useState("");

	useEffect(() => {
		const checkSubscription = async () => {
			if (subscription) {
				if (
					subscription != "standard" &&
					subscription != "explorer" &&
					subscription != "pro"
				) {
					enqueueSnackbar(
						"To use Bettermaps you need to choose a subscription plan",
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "error",
						}
					);
					history.push("/subscriptions");
				}
			} else {
				const user = await Auth.currentAuthenticatedUser();
				const c = await countInfo(user.attributes.email);
				if (c.plan == "standard" || c.plan == "explorer" || c.plan == "pro") {
					dispatch(setPlanInfo(c));
					// history.push("/dashboard");
				} else {
					history.push("/subscriptions");
				}
			}
		};

		checkSubscription();
	});

	useEffect(() => {
		const getStyles = async (ownerId) => {
			const data = await listUserStyles(ownerId);
			const formattedStyle = data.styles.map((style) => {
				if (style.updatedAt) {
					return {
						...style,
						updatedAt: formatDate(style.updatedAt),
						createdAt: formatDate(style.createdAt),
					};
				}
				return style;
			});
			const sortedStyles = formattedStyle.sort(compare);
			setStyles(sortedStyles);
			setIsLoading(false);
		};

		const fetchStyles = async () => {
			if (!ownerId) {
				const user = await Auth.currentAuthenticatedUser();
				await getStyles(user.username);
			} else {
				await getStyles(ownerId);
			}
		};

		fetchStyles();
	}, []);

	function compare(a, b) {
		if (a.updatedAt > b.updatedAt) {
			return -1;
		}
		if (a.updatedAt < b.updatedAt) {
			return 1;
		}
		return 0;
	}

	function hexToRgb(hex) {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result
			? {
					r: parseInt(result[1], 16),
					g: parseInt(result[2], 16),
					b: parseInt(result[3], 16),
			  }
			: null;
	}

	const handleOnOpenMap = (id) => {
		var d = styles.find((style) => style.id == id);
		setIsLoading2(true);

		if (!d.layers.layers) {
			const selectedLayers = JSON.parse(d.layers);

			const layersCollection = selectedLayers.layers;
			const uniqueLayersArray = [];
			const layerNames = {};
			layersCollection.forEach((layer) => {
				const layerName = layer.name;
				if (!layerNames[layerName]) {
					uniqueLayersArray.push(layer);
					layerNames[layerName] = true;
				}
			});

			selectedLayers.layers = uniqueLayersArray;

			d.layers = selectedLayers;
		}
		if (d.layers.layers.length == 0) {
			if (d.layers.layersWMS && d.layers.layersWMS.length > 0) {
				dispatch(resetLayers());
				remakeWMSLayers(d);
			} else {
				noLayers(d);
			}
		} else if (d.layers.layers.length > 0) {
			remakeStyle(d);
		} else {
			layerNotComplete();
		}
		analytics.identify("Your Styles - open as map ", {
			id: ownerId,
			subscription: subscription,
		});
		// history.push("/create-map");
	};

	const layerNotComplete = () => {
		setIsLoading2(false);
		enqueueSnackbar(
			"This style is broken, Bettermaps team has been notified to solve this issue",
			{
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			}
		);
	};

	const remakeStyle = (d) => {
		const newLayers = [];

		const newWMSLayers = [];

		var newLayer = {};
		var newWMSLayer = {};
		d.layers.layers.map((l) => {
			newLayer = {};
			newWMSLayer = {};
			if (l.type.value == "invert") {
				newLayer.id = l.id;
				newLayer.name = l.name;
				newLayer.location = l.location;
				newLayer.fileType = l.fileType;
				newLayer.type = l.type;
				newLayer.show = l.show;

				newLayer.boundingbox = l.boundingbox;
				newLayer.urlSource = l.urlSource;
				// newLayer.opacity = l.opacity;
				newLayer.source = {};
				newLayer.source.id = l.source.id;
				newLayer.source.type = l.source.type;
				newLayer.source.data = l.urlSource;
				newLayer.dbLayerId = l.dbLayerId;
				newLayers.push(newLayer);
				if (newLayers.length == d.layers.layers.length) {
					dispatch(setlayers(newLayers));
					uploadData(d);
				}
			} else if (l.type.value == "raster") {
				newWMSLayer.id = l.id;
				newWMSLayer.name = l.name;
				newLayer.location = l.location;
				newWMSLayer.fileType = l.fileType;
				newWMSLayer.type = l.type;
				newWMSLayer.show = l.show;
				newWMSLayer.opacity = l.opacity;
				newWMSLayer.boundingbox = l.boundingbox;
				newWMSLayer.source = {};
				newWMSLayer.source.id = l.source.id;
				newWMSLayer.source.type = l.source.type;
				newWMSLayer.source.tiles = l.source.tiles;
				newWMSLayer.source.tileSize = l.source.tileSize;
				newWMSLayer.dbLayerId = l.dbLayerId;

				if (
					newWMSLayer.id &&
					newWMSLayer.name &&
					newLayer.location &&
					newWMSLayer.fileType &&
					newWMSLayer.type &&
					newWMSLayer.show &&
					newWMSLayer.boundingbox &&
					newWMSLayer.opacity &&
					newWMSLayer.source.id &&
					newWMSLayer.source.type &&
					newWMSLayer.source.tileSize &&
					newWMSLayer.source.tiles
				) {
					newLayers.push(newWMSLayer);
					if (newLayers.length == d.layers.layers.length) {
						dispatch(setlayers(newLayers));

						uploadData(d);
					}
				} else {
					layerNotComplete();
				}
			} else if (l.featureColors && l.urlSource) {
				getLayerFeatures(l.urlSource)
					.then((f) => {
						CreateDataSourceFromStyles(f, l.featureColors)
							.then((s) => {
								newLayer.id = l.id;
								newLayer.name = l.name;
								newLayer.location = l.location;
								newLayer.fileType = l.fileType;
								newLayer.type = l.type;
								newLayer.show = l.show;
								if (l.fillColor) {
									newLayer.fillColor = l.fillColor;
								} else {
									newLayer.fillColor = "";
								}
								if (l.fillColor.r) {
									newLayer.fillColor = l.fillColor;
								} else {
									newLayer.fillColor = hexToRgb(l.fillColor);
								}
								if (l.fillColor.r) {
									newLayer.lineColor = l.lineColor;
								} else {
									newLayer.lineColor = hexToRgb(l.lineColor);
								}
								newLayer.selectColor = l.selectColor;
								newLayer.featureColors = l.featureColors;
								newLayer.boundingbox = l.boundingbox;
								newLayer.urlSource = l.urlSource;
								newLayer.opacity = l.opacity;
								newLayer.source = {};
								newLayer.source.id = l.source.id;
								newLayer.source.type = l.source.type;
								newLayer.source.data = s;
								newLayer.showOnHover = false;
								newLayer.hoverProperties = [];
								newLayers.push(newLayer);

								if (newLayers.length == d.layers.layers.length) {
									dispatch(setlayers(newLayers));

									uploadData(d);
								}
							})
							.catch((err) => {
								console.log(err, "CreateDataSourceFromStyles");
							});
					})
					.catch((err) => {
						console.log(err, "getLayerFeatures");
					});
			} else if (l.source && l.source.data) {
				var featureColors = [];
				if (l.source.data.features) {
					const filters = { name: l.name, location: "", geometry: "" };
					getGeoJSONs([], false, filters)
						.then((data) => {
							const featureColors = [];

							if (!l.featureColors) {
								l.source.data.features.map((f) => {
									featureColors.push(f.properties.color);
								});
							} else {
								featureColors = l.featureColors;
							}
							if (l.selectColor) {
								var selectColor = l.selectColor;
							} else if (featureColors[0] == featureColors[1]) {
								var selectColor = "unique";
							} else {
								var selectColor = "random";
							}

							newLayer.id = l.id;
							newLayer.name = l.name;
							newLayer.location = l.location;
							newLayer.fileType = l.fileType;
							newLayer.type = l.type;
							newLayer.show = l.show;
							if (l.fillColor.r) {
								newLayer.fillColor = l.fillColor;
							} else {
								newLayer.fillColor = hexToRgb(l.fillColor);
							}
							if (l.fillColor.r) {
								newLayer.lineColor = l.lineColor;
							} else {
								newLayer.lineColor = hexToRgb(l.lineColor);
							}
							newLayer.selectColor = selectColor;
							newLayer.featureColors = featureColors;
							newLayer.boundingbox = l.boundingbox;
							newLayer.urlSource = data[0].access_url;
							newLayer.opacity = l.opacity;
							newLayer.source = {};
							newLayer.source.id = l.source.id;
							newLayer.source.type = l.source.type;
							newLayer.source.data = l.source.data;
							newLayer.showOnHover = false;
							newLayer.hoverProperties = [];

							newLayers.push(newLayer);
							if (
								newLayers.length + newWMSLayers.length ===
								d.layers.layers.length
							) {
								dispatch(setlayers(newLayers));

								uploadData(d);
							}
						})
						.catch((err) => {
							console.log(err, "getGeoJSONs");
						});
				} else {
					const filters = { name: l.name, location: "", geometry: "" };
					getGeoJSONs([], false, filters)
						.then((data) => {
							if (data.length === 0) {
								layerNotComplete();
							} else {
								getLayerFeatures(data[0].access_url)
									.then((f) => {
										if (l.selectColor) {
											var selectColor = l.selectColor;
										} else if (featureColors[0] === featureColors[1]) {
											var selectColor = "unique";
										} else {
											var selectColor = "random";
										}

										if (l.fillColor) {
											if (l.fillColor.r) {
												var fillColor = l.fillColor;
											} else {
												fillColor = hexToRgb(l.fillColor);
											}
										} else {
											var fillColor = "rgba(205,0,0,1)";
										}
										var lineColor;

										var color;
										if (l.type.value === "line") {
											color = lineColor;
											if (l.lineColor) {
												if (l.lineColor.r) {
													lineColor = l.lineColor;
												} else {
													lineColor = hexToRgb(l.lineColor);
												}
											} else {
												lineColor = "rgba(205,0,0,1)";
											}
										} else {
											color = fillColor;
											if (l.lineColor) {
												if (l.lineColor.r) {
													lineColor = l.lineColor;
												} else {
													lineColor = hexToRgb(l.lineColor);
												}
											} else {
												lineColor = { r: 255, g: 255, b: 255, a: 1 };
											}
										}
										MapBoxSource(f, selectColor, color)
											.then((s) => {
												newLayer.id = l.id;
												newLayer.name = l.name;
												newLayer.location = l.location;
												newLayer.fileType = l.fileType;
												newLayer.type = l.type;
												newLayer.show = l.show;
												if (l.fillColor) {
													newLayer.fillColor = fillColor;
												}
												newLayer.lineColor = lineColor;
												newLayer.selectColor = selectColor;
												newLayer.featureColors = s.featureColors;
												newLayer.boundingbox = l.boundingbox;
												newLayer.urlSource = data[0].access_url;
												newLayer.opacity = l.opacity;
												newLayer.source = {};
												newLayer.source.id = l.source.id;
												newLayer.source.type = l.source.type;
												newLayer.source.data = s.data;
												newLayer.showOnHover = false;
												newLayer.hoverProperties = [];
												newLayers.push(newLayer);
												if (
													newLayers.length + newWMSLayers.length ===
													d.layers.layers.length
												) {
													dispatch(setlayers(newLayers));

													uploadData(d);
												}
											})
											.catch((err) => {
												console.log(err, "MapBoxSource error");
											});
									})
									.catch((err) => {
										console.log(err, "getLayerFeatures error");
									});
							}
						})
						.catch((err) => {
							console.log(err, "GeoJSON error");
						});
				}
			} else {
				layerNotComplete();
			}
		});
	};

	const remakeWMSLayers = (d) => {
		// var newWMSLayers = [];
		// if (d.layers.layersWMS && d.layers.layersWMS.length > 0) {
		//   d.layers.layersWMS.map((w) => {
		//     var newWMSLayer = {};
		//     newWMSLayer.id = w.id;
		//     newWMSLayer.name = w.name;
		//     newWMSLayer.fileType = w.fileType;
		//     newWMSLayer.type = w.type;
		//     newWMSLayer.show = w.show;
		//     newWMSLayer.opacity = w.opacity;
		//     newWMSLayer.boundingbox = w.boundingbox;
		//     newWMSLayer.source = {};
		//     newWMSLayer.source.id = w.source.id;
		//     newWMSLayer.source.type = w.source.type;
		//     newWMSLayer.source.tiles = w.source.tiles;
		//     newWMSLayer.source.tileSize = w.source.tileSize;
		//     newWMSLayer.dbLayerId = w.dbLayerId;
		//     dispatch(addLayer(newWMSLayer));
		//   });
		// }
		uploadData(d);
	};

	const uploadData = (d) => {
		if (d.layers.camera) {
			dispatch(setMapBearing(d.layers.camera.bearing));
			dispatch(setMapPitch(d.layers.camera.pitch));
			dispatch(setMapCenter([d.layers.camera.long, d.layers.camera.lat]));
			dispatch(setMapZoom(d.layers.camera.zoom));
		} else {
			dispatch(resetNewMap());
		}
		dispatch(setStyleId(d.id));
		dispatch(setMapStyle(d.baseStyleURL));
		dispatch(setStyleName(d.name));
		changePage(d.name);
	};

	const noLayers = (d) => {
		dispatch(setStyleId(d.id));
		// dispatch(setlayers(d.layers));
		dispatch(setStyleName(d.name));
		if (d.layers.camera) {
			dispatch(setMapBearing(d.layers.camera.bearing));
			dispatch(setMapPitch(d.layers.camera.pitch));
			dispatch(setMapCenter([d.layers.camera.long, d.layers.camera.lat]));
			dispatch(setMapZoom(d.layers.camera.zoom));
		} else {
			dispatch(resetNewMap());
		}
		dispatch(setMapStyle(d.baseStyleURL));
		dispatch(resetLayers());
		dispatch(resetBoundingBox());

		changePage(d.name);
		setIsLoading2(false);
	};

	const changePage = (name) => {
		setIsLoading2(false);
		history.push("/create-map", { data: name });
	};

	const handleDeleteStyle = (styleId) => {
		setStyleToDeleteId(styleId);
		setShowDeleteStyleModal(true);
		analytics.identify("Your Styles - delete style", {
			id: ownerId,
			subscription: subscription,
		});
	};

	const removeStyleFromTable = () => {
		setIsLoading(true);
		listUserStyles(ownerId)
			.then((d) => {
				const updateDateformat = d.styles.map((style) => {
					if (style.updatedAt) {
						return {
							...style,
							updatedAt: formatDate(style.updatedAt),
							createdAt: formatDate(style.createdAt),
						};
					}
					return style;
				});
				setStyles(updateDateformat);
				setNextToken(d.nextToken);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err, "removeStyleFromTable  error");
			});
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.secondary.main,
		},
	}));

	const columns = [
		{ id: "name", value: "Name" },
		{ id: "updatedAt", value: "Last Updated" },
		{ id: "actions", value: "Actions" },
	];

	const createSortHandler = (property) => {
		setIsLoading(true);
		var sorted = [...styles];
		setStyles([]);
		if (property === "updatedAt") {
			if (order === "asc") {
				setOrder("desc");
				setOrderBy("updatedAt");
				var d = sorted.sort(sortUpdateAtDesc);
				setStyles(d);
				setIsLoading(false);
			} else {
				setOrder("asc");
				setOrderBy("updatedAt");
				var d = sorted.sort(sortUpdateAtAsc);
				setStyles(d);
				setIsLoading(false);
			}
		} else {
			if (order === "asc") {
				setOrder("desc");
				setOrderBy("name");
				var d = sorted.sort(sortNameDesc);
				setStyles(d);
				setIsLoading(false);
			} else {
				setOrder("asc");
				setOrderBy("name");
				var d = sorted.sort(sortNameAsc);
				setStyles(d);
				setIsLoading(false);
			}
		}
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	function sortUpdateAtDesc(a, b) {
		if (a.updatedAt > b.updatedAt) {
			return -1;
		}
		if (a.updatedAt < b.updatedAt) {
			return 1;
		}
		return 0;
	}
	function sortUpdateAtAsc(a, b) {
		if (a.updatedAt < b.updatedAt) {
			return -1;
		}
		if (a.updatedAt > b.updatedAt) {
			return 1;
		}
		return 0;
	}
	function sortNameAsc(a, b) {
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	}

	function sortNameDesc(a, b) {
		if (a.name.toLowerCase() > b.name.toLowerCase()) {
			return -1;
		}
		if (a.name.toLowerCase() < b.name.toLowerCase()) {
			return 1;
		}
		return 0;
	}

	let hash = {};
	const noDupleStyleNames = styles.filter(function (obj) {
		const styleName = obj.name;
		if (!hash[styleName]) {
			hash[styleName] = true;
			return styleName;
		}
		return false;
	});

	return (
		// <div style={{ height: 400, width: "100%" }}>
		//   <DataGrid
		//     rows={rows}
		//     columns={columns}
		//     pageSize={5}
		//     rowsPerPageOptions={[5]}
		//     checkboxSelection
		//   />
		// </div>
		<Container sx={{ paddingTop: "32px", width: "90%" }}>
			<Typography variant="h4" sx={{ mt: 1, mb: 5 }}>
				Your Styles
			</Typography>
			<TableContainer
				sx={{
					minHeight: 120,
					maxHeight: 440,
					marginTop: 1,
					borderStyle: "solid",
					borderCollapse: "primary.main",
					borderRadius: "6px",
				}}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									sx={{
										bgcolor: "primary.main",
										color: "white",
										pt: "8px",
										pb: "8px",
										// display: "flex",
										// flexDirection: "row",
										// justifyContent: column.id == "actions" ? "flex-end" : "",
									}}
									key={column.id}
									align="left"
									style={{
										width: column.id === "actions" ? "140px" : "750px",
									}}>
									<Box
										sx={{
											display: "flex",
											justifyContent:
												column.id === "actions" ? "center" : "flex-start",
											alignItems: "center",
										}}>
										{column.id != "actions" ? (
											<Box
												sx={{
													display: "flex",
													justifyContent:
														column.id === "actions" ? "center" : "flex-start",
													alignItems: "center",
												}}>
												<IconButton
													size="small"
													sx={{ color: "white" }}
													onClick={() => createSortHandler(column.id)}>
													{column.id === orderBy ? (
														order === "asc" ? (
															<ArrowUpwardIcon />
														) : (
															<ArrowDownwardIcon />
														)
													) : (
														<Box>
															<ArrowUpwardIcon />
														</Box>
													)}
												</IconButton>
												{column.value}
											</Box>
										) : (
											<Box>{column.value}</Box>
										)}
									</Box>
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					<TableBody>
						{noDupleStyleNames.map((style) => {
							return (
								<StyledTableRow role="checkbox" tabIndex={-1} key={style.id}>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align="left"
											style={{
												width: column.id === "actions" ? "140px" : "750px",
												paddingLeft: column.id === "updatedAt" ? "60px" : "",
											}}>
											{column.id == "actions" ? (
												<Box sx={{ display: "flex", justifyContent: "center" }}>
													<Tooltip title="Open as map">
														<IconButton
															color="primary"
															sx={{ pt: 0, pb: 0, pl: 0 }}
															// component={Link}
															// to={`create-map?styleId=${style.id}`}
															onClick={() => handleOnOpenMap(style.id)}>
															<EditLocationIcon />
														</IconButton>
													</Tooltip>
													<Tooltip title="Download Style">
														<span>
															<IconButton
																color="primary"
																sx={{ pt: 0, pb: 0 }}
																disabled>
																<FileDownloadIcon />
															</IconButton>
														</span>
													</Tooltip>
													<Divider orientation="vertical" flexItem />
													<Tooltip title="Delete style">
														<IconButton
															color="primary"
															sx={{ pt: 0, pb: 0 }}
															onClick={() => handleDeleteStyle(style.id)}>
															<DeleteIcon />
														</IconButton>
													</Tooltip>
												</Box>
											) : (
												style[column.id]
											)}
										</TableCell>
									))}
								</StyledTableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<Box>
				{isLoading && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							pl: "20px",
							mt: "5rem",
							// mr: 10,
						}}>
						<CircularProgress></CircularProgress>
					</Box>
				)}
				{!isLoading && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							width: "100%",
							pr: "20px",
							mt: 1,
							// mr: 10,
						}}>
						<Typography>
							Number of Styles: {noDupleStyleNames.length}
						</Typography>
					</Box>
				)}
				{isLoading2 && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							pl: "20px",
							mt: "5rem",
							// mr: 10,
						}}>
						<CircularProgress></CircularProgress>
					</Box>
				)}
			</Box>
			{/* <TablePagination
        rowsPerPageOptions={[10, 25, 200]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={(props) => (
          <TablePaginationActions
            entries={styles}
            nextToken={nextToken}
            getFollowingEntries={getFollowingEntries}
            {...props}
          />
        )}
        showFirstButton={false}
        showLastButton={false}
      /> */}

			<DeleteStyleModal
				show={showDeleteStyleModal}
				setShow={setShowDeleteStyleModal}
				styleToDeleteId={styleToDeleteId}
				removeStyleFromTable={removeStyleFromTable}></DeleteStyleModal>
		</Container>
	);
};
