import DragHandleIcon from "@mui/icons-material/DragHandle";
import AttributionIcon from "@mui/icons-material/FormatColorText";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import MapIcon from "@mui/icons-material/Map";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import NearMeIcon from "@mui/icons-material/NearMe";
import RoundedCornerOutlinedIcon from "@mui/icons-material/RoundedCornerOutlined";
import ZoomOutMapOutlinedIcon from "@mui/icons-material/ZoomOutMapOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { LayerType } from "../../../lib/LayerType";
import { analyseStyle } from "../../../services/StyleAnalysisService";
import Icon from "../Icon";
import ActionsMenu from "./ActionsMenu";
import AddedLayersDisplay from "./AddedLayersDisplay";
import ControlModal from "./modals/ControlModal";
import ExportModal from "./modals/ExportModal";
import GeoJSONModal from "./modals/GeoJSONModal";
import KMLModal from "./modals/KMLModal";
import ExcelModal from "./modals/ExcelModal";
import MapExtendModal from "./modals/MapExtendModal";
import NewMapModal from "./modals/NewMapModal";
import PaperExportModal from "./modals/PaperExportModal";
import PaperSettingsModal from "./modals/PaperSettingsModal";
import RemoveLayerModal from "./modals/RemoveLayerModal";
import SaveStyleModal from "./modals/SaveStyleModal";
import TemplatesModal from "./modals/TemplatesModal";
import CSVModal from "./modals/CSVModal";
import EditGeoJSONModal from "./modals/editLayer/EditGeoJSONModal";
import WMSModal from "./modals/WMSModal";
// import UploadJSONModal from "./modals/UploadJSONModal";
import { useQueryParams } from "../../../lib/hooks";
import {
	createUserLayer,
	createUserStyle,
	updateUserLayer,
	updateUserStyle,
	listUserLayers,
} from "../../../services/DatasourcesService";
import { LayerFileType } from "../../../lib/LayerFileType";
import EditKMLModal from "./modals/editLayer/EditKMLModal";
import EditWMSModal from "./modals/editLayer/EditWMSModal";
import EditReverseLayerModal from "./modals/editLayer/EditReverseLayerModal";
import DrawLayerModal from "./modals/DrawLayerModal";
import { useDispatch, useSelector } from "react-redux";
import { saveLayer } from "../../../redux/slices/layers";
import { getlayers } from "../../../redux/slices/layers";
import { setStyleId } from "../../../redux/slices/style";
import { useHistory } from "react-router-dom";
import { getMapData } from "../../../redux/slices/map";
import { canPrintPaperMap } from "../../../services/StripeService";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { listUserStyles } from "../../../services/DatasourcesService";
import { Auth } from "aws-amplify";

export default function MapCreator(props) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { layers } = useSelector((state) => state.layers);
	const { ownerId } = useSelector((state) => state.user);
	const { styleId } = useSelector((state) => state.style);
	const { styleName } = useSelector((state) => state.style);
	const { mapStyle } = useSelector((state) => state.style);
	const { mapId } = useSelector((state) => state.maps);
	const { boundingBox } = useSelector((state) => state.boundingBox);
	const { email } = useSelector((state) => state.user);
	const myMap = useMap()[props.mapId];
	const myDrawMap = useMap()["Draw-map"];
	const { enqueueSnackbar } = useSnackbar();

	const queryParams = useQueryParams();
	const [inExport, setInExport] = useState(false);
	const [styleSaved, setStyleSaved] = useState(false);
	const [styleAnalysed, setStyleAnalysed] = useState(false);
	const [showTemplatesModal, setShowTemplatesModal] = useState(false);
	// const [showUploadJSONModal, setShowUploadJSONModal] = useState(false);
	const [showWMSModal, setShowWMSModal] = useState(false);

	const [showGeoJSONModal, setShowGeoJSONModal] = useState(false);
	const [showKMLModal, setShowKMLModal] = useState(false);
	const [showExcelModal, setShowExcelModal] = useState(false);
	const [removeLayerModal, setRemoveLayerModal] = useState({
		show: false,
		layer: null,
	});
	const [editGeoJSONLayerModal, setEditGeoJSONLayerModal] = useState({
		show: false,
		layer: null,
	});
	const [editKMLLayerModal, setEditKMLLayerModal] = useState({
		show: false,
		layer: null,
	});
	const [editReverseLayerModal, setEditReverseLayerModal] = useState({
		show: false,
		layer: null,
	});
	const [editWMSLayerModal, setEditWMSLayerModal] = useState({
		show: false,
		layer: null,
	});
	const [showNewMapModal, setShowNewMapModal] = useState(false);
	const [showMapExtendModal, setShowMapExtendModal] = useState(false);
	const [showDrawLayerModal, setShowDrawLayerModal] = useState(false);
	const [showPaperSettingsModal, setShowPaperSettingsModal] = useState(false);
	const [showPaperExportModal, setShowPaperExportModal] = useState(false);
	const [showExportModal, setShowExportModal] = useState(false);
	const [showSaveStyleModal, setShowSaveStyleModal] = useState(false);
	const [showCSVModal, setShowCSVModal] = useState(false);

	const [showScaleControlModal, setShowScaleControlModal] = useState(false);
	const [showLegendControlModal, setShowLegendControlModal] = useState(false);
	const [showNavigationControlModal, setShowNavigationControlModal] =
		useState(false);
	const [cleanMapName, setCleanMapName] = useState(false);
	const [layersCollection, setLayersCollection] = useState([]);

	const openTemplatesModal = () => setShowTemplatesModal(true);
	const openWMSModal = () => setShowWMSModal(true);
	// const openUploadJSONModal = () => setShowUploadJSONModal(true);
	const openGeoJSONModal = () => setShowGeoJSONModal(true);
	const openKMLModal = () => setShowKMLModal(true);
	const openExcelModal = () => setShowExcelModal(true);
	const openNewMapModal = () => setShowNewMapModal(true);
	const openMapExtendModal = () => setShowMapExtendModal(true);
	const openDrawLayerModal = () => setShowDrawLayerModal(true);
	const openPaperSettingsModal = () => setShowPaperSettingsModal(true);
	const openPaperExportModal = () => setShowPaperExportModal(true);
	const openExportModal = () => setShowExportModal(true);
	const openSaveStyleModal = () => setShowSaveStyleModal(true);
	const openCSVModal = () => setShowCSVModal(true);
	const [styles, setStyles] = useState([]);

	useEffect(() => {
		const getStyles = async (ownerId) => {
			const data = await listUserStyles(ownerId);

			const sortedStyles = data.styles.sort();
			setStyles(sortedStyles);
		};

		const fetchStyles = async () => {
			if (!ownerId) {
				const user = await Auth.currentAuthenticatedUser();
				await getStyles(user.username);
			} else {
				await getStyles(ownerId);
			}
		};

		fetchStyles();
	}, []);

	useEffect(() => {
		if (history.location.pathname != "/export-paper-map") {
			if (props.layers.length > 0 && myMap != undefined) {
				if (props.layers[props.layers.length - 1].boundingbox) {
					myMap
						.getMap()
						.fitBounds(props.layers[props.layers.length - 1].boundingbox);
				}
			} else if (boundingBox && boundingBox.length > 0 && myMap != undefined) {
				myMap.getMap().fitBounds(boundingBox);
			}
		}
		async function getUserLayers() {
			try {
				const ownerIdToUse =
					ownerId || (await Auth.currentAuthenticatedUser()).username;
				const data = await listUserLayers(ownerIdToUse);
				const parsePropertiesObject = (obj) => {
					const parsedProperties = JSON.parse(obj.properties);
					return { ...obj, properties: parsedProperties };
				};
				const parsedArray = data.layers.map(parsePropertiesObject);
				setLayersCollection(parsedArray);
				return data;
			} catch (error) {
				console.error("An error occurred:", error);
				throw error;
			}
		}
		getUserLayers();
	}, [myMap]);

	useEffect(() => {
		if (history.location.pathname === "/export-paper-map") {
			setInExport(true);
		}
	}, []);

	useEffect(() => {
		setStyleAnalysed(true);
		setStyleSaved(true);
	}, []);

	useEffect(() => {
		setStyleAnalysed(false);
		setStyleSaved(false);
	}, [props.layers, props.mapStyle]);

	useEffect(() => {
		setStyleSaved(false);
	}, [props.styleName]);

	const actions = [
		{
			title: "Create new map",
			type: "NON-CHECKABLE",
			onClick: () => {
				openNewMapModal(), setCleanMapName(true);
			},
			icon: <MapIcon sx={{ color: "white" }} />,
			variant: "filled",
			hidden: inExport !== true ? "" : "true",
		},
		{
			title: "Add GeoJSON",
			type: "NON-CHECKABLE",
			onClick: openGeoJSONModal,
			icon: <Icon icon="geojson" sx={{ color: "primary.main" }} />,
			variant: "contained",
			hidden: inExport !== true ? "" : "true",
		},
		{
			title: "Add KML",
			type: "NON-CHECKABLE",
			onClick: openKMLModal,
			icon: <Icon icon="kml" sx={{ color: "primary.main" }} />,
			variant: "contained",
			hidden: inExport !== true ? "" : "true",
		},
		{
			title: "Add Excel",
			type: "NON-CHECKABLE",
			onClick: openExcelModal,
			icon: <UploadFileIcon sx={{ color: "primary.main" }} />,
			variant: "contained",
			hidden:
				process.env.REACT_APP_ENV !== "prod" &&
				process.env.REACT_APP_ENV !== "staging"
					? inExport !== true
						? ""
						: "true"
					: "true",
		},
		{
			title: "Add CSV",
			type: "NON-CHECKABLE",
			onClick: openCSVModal,
			icon: <Icon icon="csv" sx={{ color: "primary.main" }} />,
			variant: "contained",
			hidden: inExport !== true ? "" : "true",
		},
		{
			title: "Add WMS",
			type: "NON-CHECKABLE",
			onClick: openWMSModal,
			icon: <Icon icon="wms" sx={{ color: "primary.main" }} />,
			variant: "contained",
			hidden: inExport !== true ? "" : "true",
		},

		{
			title: inExport !== true ? "Export" : "Print to paper",
			type: "NON-CHECKABLE",
			onClick: props.showPaperMapActions
				? beforeOpenPaperExportModal
				: beforeOpenExportModal,
			variant: "contained",
		},
		// {
		//   title: "Import JSON",
		//   type: "NON-CHECKABLE",
		//   onClick: openUploadJSONModal,
		//   icon: <Icon icon="geojson" sx={{ color: "primary.main" }} />,
		//   variant: "contained",
		// },
		{
			title: "Analyse Style",
			type: "NON-CHECKABLE",
			onClick: async () => {
				try {
					const results = await analyseStyle(myMap);
					setStyleAnalysed(true);
					results.forEach((e, i) => {
						setTimeout(
							() =>
								enqueueSnackbar(e.description, {
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left",
									},
									variant: e.type,
								}),
							i * 1000
						);
					});
				} catch (e) {
					enqueueSnackbar(e.message, {
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					});
				}
				window.analytics.track("analyseStyle.click");
			},

			disabled: styleAnalysed,
			hidden: inExport !== true ? "" : "true",
		},
		{
			type: "CHECKABLE",
			title: "Set scale",
			onClick: () => setShowScaleControlModal(!showScaleControlModal),
			onCheckChange: () =>
				props.setScale((s) => {
					return { ...s, show: !s.show };
				}),
			icon: <LinearScaleIcon sx={{ color: "white" }} />,
			checked: props.scale.show,
		},
		{
			type: "CHECKABLE",
			title: "Set north",
			// onClick: () => setShowNavigationControlModal(!showNavigationControlModal),
			onCheckChange: () =>
				props.setNavigation((n) => {
					return { ...n, show: !n.show };
				}),
			icon: <NearMeIcon sx={{ color: "white" }} />,
			checked: props.navigation.show,
		},
		{
			type: "CHECKABLE",
			title: "Legend Settings",
			onClick: () => setShowLegendControlModal(!showLegendControlModal),
			onCheckChange: () => {}, // TODO: Implement when the legen control is implemented
			icon: <DragHandleIcon sx={{ color: "white" }} />,
			checked: props.legend.show,
			disabled: true,
			hidden: true,
		},
		{
			type: "CHECKABLE",
			title: "Show attribution",
			onClick: () => {},
			onCheckChange: () =>
				props.setAttribution((a) => {
					return { ...a, show: !a.show };
				}),
			icon: <AttributionIcon sx={{ color: "white" }} />,
			checked: props.attribution.show,
		},
		{
			type: "NON-CHECKABLE",
			title: "Map Extend Settings",
			onClick: openMapExtendModal,
			icon: <ZoomOutMapOutlinedIcon sx={{ color: "white" }} />,
			variant: "filled",
		},
		{
			type: "NON-CHECKABLE",
			title: "Margin Settings",
			onClick: () => {},
			icon: <RoundedCornerOutlinedIcon sx={{ color: "white" }} />,
			hidden: true,
			variant: "filled",
		},
		{
			type: "NON-CHECKABLE",
			title: "Draw Layer",
			onClick: openDrawLayerModal,
			icon: <ModeEditOutlineIcon sx={{ color: "white" }} />,
			variant: "filled",
		},
	];

	const paperMapActions = props.showPaperMapActions && [
		{
			type: "NON-CHECKABLE",
			title: "Paper Settings",
			onClick: openPaperSettingsModal,
			icon: <InsertDriveFileOutlinedIcon sx={{ color: "white" }} />,
			variant: "filled",
		},
	];

	const backgroundLayer = {
		id: "Background",
		text: "Mapbox Bright",
		name: "Background Layer",
		type: LayerType.Background,
		show: true,
	};

	function deleteLayer(layer) {
		let newLayers = [];
		let previousLayer;
		props.layers
			.sort((a, b) => a.position - b.position)
			.every((layer) => {
				if (layer.id == layer.id) {
					if (previousLayer) previousLayer.beforeId = layer.beforeId;
					props.layers
						.slice(layer.position)
						.forEach((e) => newLayers.push({ ...e, position: e.position - 1 }));
					return false;
				}
				newLayers.push(layer);
				previousLayer = layer;
				return true;
			});
		props.setLayers(newLayers);
	}

	function editBackground() {
		openTemplatesModal();
	}
	function editReverseLayer(layer) {
		setEditReverseLayerModal({ show: true, layer: layer });
	}

	function beforeOpenExportModal() {
		dispatch(
			getMapData(
				myMap.getZoom(),
				myMap.getCenter(),
				myMap.getPitch(),
				myMap.getBearing()
			)
		);
		openExportModal();
	}

	const beforeOpenPaperExportModal = async () => {
		try {
			const u = await canPrintPaperMap(email);
			if (u == 200) {
				openPaperExportModal();
			} else if (u == 403) {
				enqueueSnackbar(
					"You have run out of prints. Change the subscription to get more prints today.",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
			} else if (u == 500) {
				enqueueSnackbar(
					"You have no subscription plan, please choose one of the subscriptions plans",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
				history.push("/subscriptions");
			} else {
				enqueueSnackbar(
					"There was an error loading the print modal. Please, try later.",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
			}
		} catch (error) {
			enqueueSnackbar(
				"There was an error loading the print modal. Please, try later.",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
	};

	const reopenDrawModal = () => {
		setShowDrawLayerModal(false);
		setTimeout(() => {
			setShowDrawLayerModal(true);
		}, 10);
	};

	function editLayer(layer) {
		if (layer.type.value === LayerType.Invert.value) {
			editReverseLayer(layer);
		} else {
			if (layer.fileType === LayerFileType.GeoJSON)
				setEditGeoJSONLayerModal({ show: true, layer: layer });
			if (layer.fileType === LayerFileType.KML)
				setEditKMLLayerModal({ show: true, layer: layer });
			if (layer.fileType === LayerFileType.WMS)
				setEditWMSLayerModal({ show: true, layer: layer });
		}

		// if (layer.type.value === LayerType.Background.value) openTemplatesModal();
	}
	async function handleCreateStyle(camera, layer, modifyWMSLayers) {
		const existStyleName = styles.some((obj) => obj.name === styleName);
		if (!existStyleName) {
			try {
				const id = await createUserStyle(
					myMap.getStyle(),
					styleName,
					layer,
					mapStyle,
					ownerId,
					camera
					// modifyWMSLayers
				);

				setStyleSaved(true);
				dispatch(setStyleId(id));
				enqueueSnackbar(`Style with name ${styleName} saved`, {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "success",
				});
			} catch (e) {
				console.error(e);
				enqueueSnackbar(
					"There was an error when saving your style. Please, try later.",
					{
						anchorOrigin: {
							vertical: "top",
							horizontal: "center",
						},
						variant: "error",
					}
				);
			}
		} else {
			enqueueSnackbar(
				`${styleName} already exists. Please, choose another name`,
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
	}
	const handleStyleSave = async () => {
		try {
			const layerCollectionProperties = layersCollection.map(
				(layer) => layer.properties
			);
			// Conjunto para almacenar combinaciones únicas de name y location del array original
			const conjuntoCombinaciones = new Set();

			// Conjunto para almacenar combinaciones repetidas de name y location del otro array
			const combinacionesRepetidas = new Set();

			// Recorrer el array original y almacenar combinaciones únicas
			for (const objeto of layerCollectionProperties) {
				const combinacion = `${objeto.name}-${objeto.location}`;
				conjuntoCombinaciones.add(combinacion);
			}

			// Recorrer el otro array y buscar combinaciones repetidas
			for (const objeto of layers) {
				const combinacion = `${objeto.name}-${objeto.location}`;
				if (conjuntoCombinaciones.has(combinacion)) {
					combinacionesRepetidas.add(combinacion);
				}
			}
			const noDuplesLayers = Array.from(combinacionesRepetidas);

			for (const layer of layers) {
				const id = layer.dbLayerId;
				if (noDuplesLayers.length <= 0) {
					await createUserLayer(layer, ownerId);
					if (layer.dbLayerId) {
						updateUserLayer(layer, ownerId);
						dispatch(saveLayer([layer.id, id]));
					}
					dispatch(setStyleId(id));
					enqueueSnackbar(
						`Style with name ${layer.name} and location ${layer.location} saved`,
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "success",
						}
					);
				} else {
					enqueueSnackbar(
						`It seems that you already have a style with name ${layer.name} and location ${layer.location} .`,
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "error",
						}
					);
				}
			}
			modiftyLayersToSave();
		} catch (error) {
			console.error(error);
			enqueueSnackbar(
				"There was an error when saving your style. Please, try later.",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
		// layers.dbLayerId
		// 	? handleUpdateStyle(cameraSettings, modifyLayers, modifyWMSLayers)
		// 	: handleCreateStyle(cameraSettings, modifyLayers, modifyWMSLayers);
	};

	const handleUpdateStyle = async (camera, layer, modifyWMSLayers) => {
		try {
			const id = await updateUserStyle(
				styleId,
				myMap.getStyle(),
				styleName,
				layer,
				mapStyle,
				ownerId,
				camera
				// modifyWMSLayers
			);
			if (id) {
				setStyleSaved(true);
				dispatch(setStyleId(id));
				enqueueSnackbar("Style updated", {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "success",
				});
			}
		} catch (e) {
			console.error(e);
			enqueueSnackbar(
				"There was an error when updating your style. Please, try later.",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
	};

	const modiftyLayersToSave = () => {
		var modifyLayers = [];
		var modifyWMSLayers = [];
		var cameraSettings = {};
		cameraSettings.long = myMap?.getCenter().lng;
		cameraSettings.lat = myMap?.getCenter().lat;
		cameraSettings.zoom = myMap?.getZoom();
		cameraSettings.pitch = myMap?.getPitch();
		cameraSettings.bearing = myMap?.getBearing();

		// modifyLayers.push(cameraSettings)

		layers.forEach((layer) => {
			if (layer.type.value == "raster") {
				// var modifyWMSLayer = {};
				// modifyWMSLayer.id = layer.id;
				// modifyWMSLayer.name = layer.name;
				// modifyWMSLayer.fileType = layer.fileType;
				// modifyWMSLayer.type = layer.type;
				// modifyWMSLayer.show = layer.show;
				// modifyWMSLayer.opacity = layer.opacity;
				// modifyWMSLayer.boundingbox = layer.boundingbox;
				// modifyWMSLayer.source = {};
				// modifyWMSLayer.source.id = layer.source.id;
				// modifyWMSLayer.source.type = layer.source.type;
				// modifyWMSLayer.source.tiles = layer.source.tiles;
				// modifyWMSLayer.source.tileSize = layer.source.tileSize;
				// modifyWMSLayer.dbLayerId = layer.dbLayerId;
				// modifyWMSLayers.push(modifyWMSLayer);
				var modifyLayer = {};
				modifyLayer.id = layer.id;
				modifyLayer.name = layer.name;
				modifyLayer.location = layer.location;
				modifyLayer.fileType = layer.fileType;
				modifyLayer.type = layer.type;
				modifyLayer.show = layer.show;
				modifyLayer.opacity = layer.opacity;
				modifyLayer.boundingbox = layer.boundingbox;
				modifyLayer.source = {};
				modifyLayer.source.id = layer.source.id;
				modifyLayer.source.type = layer.source.type;
				modifyLayer.source.tiles = layer.source.tiles;
				modifyLayer.source.tileSize = layer.source.tileSize;
				modifyLayer.dbLayerId = layer.dbLayerId;
				modifyLayers.push(modifyLayer);
			} else {
				var modifyLayer = {};
				modifyLayer.id = layer.id;
				modifyLayer.name = layer.name;
				modifyLayer.location = layer.location;

				modifyLayer.fileType = layer.fileType;
				modifyLayer.type = layer.type;
				modifyLayer.show = layer.show;
				modifyLayer.fillColor = layer.fillColor;
				modifyLayer.lineColor = layer.lineColor;
				if (layer.type != "invert") {
					modifyLayer.selectColor = layer.selectColor;
					modifyLayer.featureColors = layer.featureColors;
					modifyLayer.showOnHover = layer.showOnHover;
					modifyLayer.hoverProperties = layer.hoverProperties;
				}
				modifyLayer.boundingbox = layer.boundingbox;
				modifyLayer.urlSource = layer.urlSource;
				modifyLayer.opacity = layer.opacity;
				modifyLayer.source = {};
				modifyLayer.source.id = layer.source.id;
				modifyLayer.source.type = layer.source.type;
				modifyLayer.source.data = [];
				modifyLayer.dbLayerId = layer.dbLayerId;
				modifyLayers.push(modifyLayer);
			}
		});
		styleId
			? handleUpdateStyle(cameraSettings, modifyLayers, modifyWMSLayers)
			: handleCreateStyle(cameraSettings, modifyLayers, modifyWMSLayers);
	};

	return (
		<React.Fragment>
			<Grid
				container
				style={{
					position: "absolute",
					top: 15,
					left: 15,
					display: "flex",
					flexDirection: "column",
					width: "70%",
					pointerEvents: "none",
				}}>
				<ActionsMenu
					actions={actions}
					paperMapActions={paperMapActions}
					showPaperMapActions={props.showPaperMapActions}
					showLayerTiles={props.showLayerTiles}
					setShowLayerTiles={props.setShowLayerTiles}
					mapId={props.mapId}
					styleSaved={styleSaved}
					setStyleSaved={setStyleSaved}
					styleName={props.styleName}
					setStyleName={props.setStyleName}
					mapStyle={props.mapStyle}
					layers={props.layers}
					setMapStyleId={props.setMapStyleId}
					openSaveStyleModal={openSaveStyleModal}
					cleanMapName={cleanMapName}
				/>
				<AddedLayersDisplay
					editBackground={editBackground}
					layers={[backgroundLayer, ...props.layers]}
					setLayers={props.setLayers}
					deleteLayer={deleteLayer}
					// setLayerVisibility={setLayerVisibility}
					setRemoveLayerModal={setRemoveLayerModal}
					showLayerTiles={props.showLayerTiles}
					editLayer={editLayer}
					// handleMoveUpLayer={handleMoveUpLayer}
					// handleMoveDownLayer={handleMoveDownLayer}
					mapId={props.mapId}></AddedLayersDisplay>
			</Grid>
			<TemplatesModal
				showTemplatesModal={showTemplatesModal}
				setShowTemplatesModal={setShowTemplatesModal}
				setMapStyle={props.setMapStyle}
			/>
			{showGeoJSONModal && (
				<GeoJSONModal
					showGeoJSONModal={showGeoJSONModal}
					setShowGeoJSONModal={setShowGeoJSONModal}
					setLayers={props.setLayers}
					map={myMap}
				/>
			)}
			{showKMLModal && (
				<KMLModal
					showKMLModal={showKMLModal}
					setShowKMLModal={setShowKMLModal}
					openKML={openKMLModal}
					setLayers={props.setLayers}
					map={myMap}
				/>
			)}
			{showExcelModal && (
				<ExcelModal
					showExcelModal={showExcelModal}
					setShowExcelModal={setShowExcelModal}
					openExcel={openExcelModal}
					setLayers={props.setLayers}
					map={myMap}
				/>
			)}
			{showWMSModal && (
				<WMSModal
					showWMSModal={showWMSModal}
					setShowWMSModal={setShowWMSModal}
					setLayers={props.setLayers}
					map={myMap}
				/>
			)}
			{/* {showUploadJSONModal && <BasicModal />} */}
			{showCSVModal && (
				<CSVModal
					showCSVModal={showCSVModal}
					setShowCSVModal={setShowCSVModal}
					setLayers={props.setLayers}
					map={myMap}
				/>
			)}
			<ControlModal
				title={"Scale Settings"}
				show={showScaleControlModal}
				setShow={setShowScaleControlModal}
				position={props.scale.position}
				setPosition={(position) =>
					props.setScale((s) => {
						return { ...s, position };
					})
				}
				unit={props.scale.unit}
				setUnit={(unit) =>
					props.setScale((s) => {
						return { ...s, unit };
					})
				}></ControlModal>
			<ControlModal
				title={"Navigation Settings"}
				show={showNavigationControlModal}
				setShow={setShowNavigationControlModal}
				position={props.navigation.position}
				setPosition={(position) =>
					props.setScale((s) => {
						return { ...s, position };
					})
				}></ControlModal>
			<ControlModal
				title={"Legend Settings"}
				show={showLegendControlModal}
				setShow={setShowLegendControlModal}
				position={props.legend.position}
				setPosition={(position) =>
					props.setLegend((s) => {
						return { ...s, position };
					})
				}></ControlModal>

			{removeLayerModal && (
				<RemoveLayerModal
					removeLayerModal={removeLayerModal}
					setRemoveLayerModal={setRemoveLayerModal}
					deleteLayer={deleteLayer}></RemoveLayerModal>
			)}
			{showNewMapModal && (
				<NewMapModal
					show={showNewMapModal}
					setShow={setShowNewMapModal}
					setMapStyle={props.setMapStyle}
					setLayers={props.setLayers}
					setShowLayerTiles={props.setShowLayerTiles}
					setStyleName={props.setStyleName}
					setMapStyleId={props.setMapStyleId}
					setMapBoundingBox={props.setMapBoundingBox}
					setShowTemplatesModal={setShowTemplatesModal}></NewMapModal>
			)}
			<MapExtendModal
				show={showMapExtendModal}
				setShow={setShowMapExtendModal}
				myMap={myMap}
				scaleUnit={props.scale.unit}></MapExtendModal>
			<DrawLayerModal
				show={showDrawLayerModal}
				setShow={setShowDrawLayerModal}
				myMap={myMap}
				reopenDrawModal={reopenDrawModal}></DrawLayerModal>

			{props.showPaperMapActions && (
				<PaperSettingsModal
					show={showPaperSettingsModal}
					setShow={setShowPaperSettingsModal}
					paperSettings={props.paperSettings}
					setPaperSettings={props.setPaperSettings}></PaperSettingsModal>
			)}
			{props.showPaperMapActions && (
				<PaperExportModal
					show={showPaperExportModal}
					setShow={setShowPaperExportModal}
					exportSettings={props.exportSettings}
					setExportSettings={props.setExportSettings}
					paperSettings={props.paperSettings}
					map={myMap}></PaperExportModal>
			)}
			<ExportModal
				show={showExportModal}
				setShow={setShowExportModal}
				map={myMap}
				styleName={props.styleName}
				layers={props.layers}
				mapStyle={props.mapStyle}
				setStyleSaved={setStyleSaved}
				setMapStyleId={props.setMapStyleId}></ExportModal>
			<SaveStyleModal
				show={showSaveStyleModal}
				setShow={setShowSaveStyleModal}
				onSave={handleStyleSave}></SaveStyleModal>
			{editGeoJSONLayerModal.show && (
				<EditGeoJSONModal
					show={editGeoJSONLayerModal.show}
					setShow={(show) => setEditGeoJSONLayerModal({ show: show })}
					layer={editGeoJSONLayerModal.layer}
					setLayers={props.setLayers}></EditGeoJSONModal>
			)}
			{editReverseLayerModal.show && (
				<EditReverseLayerModal
					show={editReverseLayerModal.show}
					setShow={(show) => setEditReverseLayerModal({ show: show })}
					layer={editReverseLayerModal.layer}
					map={myMap}
				/>
			)}
			{editKMLLayerModal.show && (
				<EditKMLModal
					show={editKMLLayerModal.show}
					setShow={(show) => setEditKMLLayerModal({ show: show })}
					layer={editKMLLayerModal.layer}
					setLayers={props.setLayers}></EditKMLModal>
			)}
			{editWMSLayerModal.show && (
				<EditWMSModal
					show={editWMSLayerModal.show}
					setShow={(show) => setEditWMSLayerModal({ show: show })}
					layer={editWMSLayerModal.layer}
					setLayers={props.setLayers}></EditWMSModal>
			)}
		</React.Fragment>
	);
}
