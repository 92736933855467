import post from "../clients/HttpClient";

 export default async function getCoordinatesForLocation(location) {
		try {
			const response = await post(
				"https://maps.googleapis.com/maps/api/geocode/json?key=" +
					process.env.REACT_APP_GOOGLE_API_TOKEN +
					"&region=es&address=" +
					location
			);
			if (response.data.status == "OK") {
				const lat = response.data.results[0].geometry.location.lat;
				const lng = response.data.results[0].geometry.location.lng;
				return [lng, lat];
			}
			return [];
		} catch (error) {
			console.error(error);
		}
 }
