import * as React from "react";
import * as XLSX from "xlsx";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { v4 as randomID } from "uuid";
import { LayerType } from "../../../../lib/LayerType";
import { LayerFileType } from "../../../../lib/LayerFileType";
import { useState } from "react";
import { useColor } from "../../../../lib/hooks";
import BettermapsTextField from "../../BettermapsTextField";
import { UploadExcelToS3 } from "../../../../services/DigitalMapService";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import BettermapsModal from "../../BettermapsModal";
import BettermapsColorPicker from "../../BettermapsColorPicker";
import { getLayerFeatures } from "../../../../services/DatasourcesService";
import BettermapsSelect from "../../BettermapsSelect";
import { useSelector } from "react-redux";

export default function ExcelModal(props) {
	const [ExcelColumns, setExcelColumns] = useState([]);
	const [latitudeColumn, setLatitudeColumn] = useState("");
	const [longitudeColumn, setLongitudeColumn] = useState("");
	const [layerName, setLayerName] = useState("");
	const [fillColor, setFillColor] = useColor("#cd0000");
	const [lineColor, setLineColor] = useColor("#02c100");
	const [layerType, setLayerType] = useState(LayerType.Circle);
	const [zoomOnLoad, setZoomOnLoad] = useState(true);
	const [ExcelFileName, setExcelFileName] = useState("");
	const [ExcelFileError, setExcelFileError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [ExcelFile, setExcelFile] = useState();

	const FIVE_MEGABYTES = 5 * Math.pow(1024, 2);
	const userData = useSelector((state) => state.user);

	const zoomToBoundingBox = (boundingBox) => {
		props.map.getMap().fitBounds(boundingBox);
	};

	const theme = useTheme();
	const [layerVisibility, setLayerVisibility] = useState("public");

	const handleAddExcelLayer = async () => {
		try {
			setIsLoading(true);
			const user = await Auth.currentAuthenticatedUser();
			const ownerId = user.username;
			const response = await UploadExcelToS3(
				ExcelFile,
				ownerId,
				latitudeColumn,
				longitudeColumn
			);
			if (response.data.body === "Excel format not valid.") {
				enqueueSnackbar(`Excel format not valid`, {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				});
				setIsLoading(false);
				closeExcel();
			} else if (response.data.body.url && response.data.body.boundingBox) {
				setIsLoading(false);
				closeExcel();
				addFeatures(response.data.body?.url, response.data.body?.boundingBox);
				if (zoomOnLoad) zoomToBoundingBox(response.data.body.boundingBox);
			} else {
				enqueueSnackbar(`Error Uploading your Excel, please verify the file`, {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				});
				setIsLoading(false);
				closeExcel();
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(`Error adding Excel layer. Please try again later.`, {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			});
			setIsLoading(false);
			closeExcel();
		}
	};

	const closeExcel = () => props.setShowExcelModal(false);

	const addFeatures = async (ExcelFile, boundingBox) => {
		try {
			const d = await getLayerFeatures(ExcelFile);
			let GeometryType;
			if (d.features[0].geometry.type.toLowerCase() == "polygon") {
				GeometryType = LayerType.Fill;
				await addExcelLayer(ExcelFile, GeometryType, boundingBox);
			} else if (d.features[0].geometry.type.toLowerCase() == "point") {
				GeometryType = LayerType.Circle;
				await addExcelLayer(ExcelFile, GeometryType, boundingBox);
			} else if (d.features[0].geometry.type.toLowerCase() == "line") {
				GeometryType = LayerType.Line;
				await addExcelLayer(ExcelFile, GeometryType, boundingBox);
			}
		} catch (error) {
			setIsLoading(false);
			enqueueSnackbar(`Error adding Excel layer: ${error.message}`, {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			});
		}
	};


	function addExcelLayer(ExcelFileURL, GeometryType, boundingBox) {
		const layerID = `bettermaps-layer-${randomID()}`;
		const sourceID = randomID();
		props.setLayers((layers) => {
			const lastLayer = layers[0];
			if (lastLayer) lastLayer.beforeId = layerID;
			return [
				...layers,
				{
					id: layerID,
					name: layerName,
					fileType: LayerFileType.GeoJSON,
					type: GeometryType,
					show: true,
					position: layers.length + 1,
					fillColor: fillColor.hex,
					lineColor: lineColor.hex,
					boundingbox: boundingBox,
					opacity:
						GeometryType.value == "line" ? lineColor.rgb.a : fillColor.rgb.a,
					source: {
						id: sourceID,
						type: "geojson",
						data: ExcelFileURL,
					},
					showOnHover: false,
					hoverProperties: [],
				},
			];
		});
	}

	const handleExcelFileSelected = (e) => {
		setExcelFileError("");
		const selectedExcelFile = e.currentTarget.files[0];
		const extension = selectedExcelFile.name.split(".").pop();

		if (selectedExcelFile.size <= FIVE_MEGABYTES && extension === "excel") {
			const fileReader = new FileReader();
			fileReader.onload = (e) => {
				const bstr = fileReader.result;
				const wb = XLSX.read(bstr, { type: "binary" });
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
				const firstLine = data.split("\n").shift();

				setExcelColumns(firstLine.replace(/\r/g, "").split(","));
				setExcelFileName(selectedExcelFile.name);
				setLayerName(selectedExcelFile.name);
				setExcelFile(selectedExcelFile);
			};
			fileReader.readAsBinaryString(selectedExcelFile);

			//   fileReader.readAsText(selectedExcelFile, "UTF-8");
		} else {

			enqueueSnackbar(
				"Excel can't be greater than 5MB or type is not supported",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}

			);
		}
	};

	return (
		<BettermapsModal
			sx={{ width: "60%", maxWidth: "700px" }}
			title="Add Excel Layer"
			showModal={props.showExcelModal}
			setShowModal={props.setShowExcelModal}
			onDone={handleAddExcelLayer}
			doneText="Add"
			doneDisabled={
				isLoading || latitudeColumn === "" || longitudeColumn == ""
			}>
			<Grid container direction="column">
				<Box
					flex="1"
					display="flex"
					flexDirection="row"
					justifyContent="center"
					alignItems="center"
					sx={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "1rem",
					}}>
					<BettermapsTextField
						label="Excel Layer name"
						value={layerName}
						style={{ width: "80%" }}
						onChange={(e) => {
							setLayerName(e.target.value),
								analytics.identify(
									`click input Excel Layer: ${e.target.value}`,
									{
										id: userData.ownerId,
										subscription: userData.subscription,
									}
								);
						}}></BettermapsTextField>

					<Button
						variant="contained"
						component="label"
						sx={{ borderRadius: 1, width: 70, height: 40 }}>
						File
						<input
							type="file"
							accept=".xlsx"
							hidden
							onChange={handleExcelFileSelected}
						/>
					</Button>
				</Box>
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						flexWrap: "wrap",
					}}>
					<Box
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							marginRight: "20px",
							marginTop: "10px",
						}}>
						<Typography>
							{layerType.value == LayerType.Line.value
								? "Line color"
								: "Outline color"}
						</Typography>
						<Box style={{ marginLeft: "10px" }}>
							<BettermapsColorPicker
								color={lineColor}
								setColor={setLineColor}
								disableAlpha={layerType.value != LayerType.Line.value}
							/>
						</Box>
					</Box>
					{layerType.value != LayerType.Line.value && (
						<Box
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginRight: "40px",
								marginTop: "10px",
							}}>
							<Typography>Fill color</Typography>

							<Box style={{ marginLeft: "10px" }}>
								<BettermapsColorPicker
									color={fillColor}
									setColor={setFillColor}
									disableAlpha={layerType.value == LayerType.Line.value}
								/>
							</Box>
						</Box>
					)}
					<ToggleButtonGroup
						disabled
						style={{
							backgroundColor: theme.palette.secondary.main,
							width: "fit-content",
							marginTop: "10px",
						}}
						value={layerVisibility}
						size="small"
						exclusive
						onChange={(e, a) => setLayerVisibility(a)}>
						<ToggleButton value="owned">
							<FavoriteBorderIcon sx={{ color: "white" }} />
						</ToggleButton>
						<ToggleButton value="public">
							<LockOpenIcon sx={{ color: "white" }} />
						</ToggleButton>
						<ToggleButton value="private">
							<LockOutlinedIcon sx={{ color: "white" }} />
						</ToggleButton>
					</ToggleButtonGroup>
				</Box>
			</Grid>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				sx={{ marginTop: "10px" }}>
				<BettermapsSelect
					sx={{ width: 200 }}
					disabled={!ExcelFile}
					value={latitudeColumn}
					onChange={(e) => setLatitudeColumn(e.target.value)}
					label="Latitude">
					{ExcelColumns.map((column, index) => (
						<MenuItem key={index} value={column}>
							{column}
						</MenuItem>
					))}
				</BettermapsSelect>
				<BettermapsSelect
					sx={{ width: 200, ml: 1 }}
					disabled={!ExcelFile}
					value={longitudeColumn}
					onChange={(e) => setLongitudeColumn(e.target.value)}
					label="Longitude">
					{ExcelColumns.map((column, index) => (
						<MenuItem key={index} value={column}>
							{column}
						</MenuItem>
					))}
				</BettermapsSelect>
			</Box>

			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				marginTop="10px">
				<CircularProgress
					style={{ display: isLoading ? "block" : "none" }}
					color="primary"
				/>
			</Box>
			<FormControlLabel
				style={{
					position: "absolute",
					left: "32px",
					bottom: "32px",
				}}
				control={
					<Checkbox
						checked={zoomOnLoad}
						onChange={() => setZoomOnLoad(!zoomOnLoad)}
					/>
				}
				label="Zoom on load"
			/>
		</BettermapsModal>
	);
}
