import { fileToBase64 } from "../lib/fileConversions";
import post from "../clients/HttpClient";
import getCoordinatesForLocation from "./GoogleMapService";

export async function getGeoJSONFromLocationsOnImage(imageFile) {
  const base64Image = (await fileToBase64(imageFile)).split(";base64,")[1];
  const locations = await extractTextsFromImage(base64Image);
  return mapLocationsToGeoJSON(locations);
}
const extractTextsFromImage = async (base64Image) => {
	try {
		const response = await post(
			"https://onxtt5xble.execute-api.eu-central-1.amazonaws.com/" +
				process.env.REACT_APP_ENV +
				"/",
			{
				Image: base64Image,
			}
		);
		return response.data.body
			.filter((item) => item.BlockType == "WORD")
			.map((e) => e.Text);
	} catch (error) {
		console.error(error);
		return [];
	}
};

const mapLocationsToGeoJSON = async (locations) => {
	try {
		const coordinates = await Promise.all(
			locations.map((l) => getCoordinatesForLocation(l))
		);
		return buildGeoJSON(coordinates.filter((c) => c.length > 0));
	} catch (error) {
		console.error(error);
	}
};

function buildGeoJSON(coordinates) {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "MultiPoint",
          coordinates: coordinates,
        },
        properties: {
          prop0: "value0",
        },
      },
    ],
  };
}
