import post from "../clients/HttpClient";
import { fileToBase64 } from "../lib/fileConversions";

export const getStyleAnalysis = async () => {
	try {
		const base64Style = (await fileToBase64(file)).split(";base64,")[1];
		const response = await post(
			`${process.env.REACT_APP_GET_STYLE_ANALYSIS}/${process.env.REACT_APP_ENV}/analyze-style`,
			JSON.stringify({
				output: outputType,
				include_css: includeCSS,
				sb64: base64Style,
			})
		);
		return response.data.data;
	} catch (error) {
		console.error(error);
	}
};

export const transformCSVFileToGeojson = async (CSVFile, ownerId, columns) => {
	try {
		const base64File = await fileToBase64(CSVFile);
		const response = await post(
			`${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/upload_csv`,
			{
				file: base64File.split("base64,")[1],
				fileName: CSVFile.name,
				ownerId,
				columns,
			}
		);
		return {
			url: response.data.url,
			boundingBox: response.data.boundingBox,
		};
	} catch (error) {
		console.error(error);
	}
};

export async function UploadJSONToS3(JSONFile, ownerID) {
  const base64File = (await fileToBase64(JSONFile)).split("base64,")[1];

  const response = await post(
    `${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/upload_geojson_json`,
    {
      file: base64File,
      fileName: JSONFile.name,
      ownerId: ownerID,
    }
  );
  return response;
}
export async function UploadDrawToS3(file, ownerID, fileName) {
  const base64File = btoa(JSON.stringify(file));

  const response = await post(
    `${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/upload_geojson_json`,
    {
      file: base64File,
      fileName: fileName,
      ownerId: ownerID,
    }
  );
  return response;
}

export async function UploadKMLToS3(KMLFile, ownerID) {
  const base64File = (await fileToBase64(KMLFile)).split("base64,")[1];

  const response = await post(
    `${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/upload_kml`,
    {
      file: base64File,
      fileName: KMLFile.name,
      ownerId: ownerID,
    }
  );
  return response;
}

export async function UploadExcelToS3(ExcelFile, ownerID, lat, long) {
  const base64File = (await fileToBase64(ExcelFile)).split("base64,")[1];

  const response = await post(
    `${process.env.REACT_APP_CSV_TO_GEOJSON_LAMBDA_URL}/${process.env.REACT_APP_ENV}/upload_excel`,
    {
      file: base64File,
      fileName: ExcelFile.name,
      ownerId: ownerID,
      columns: {
        lat: lat,
        long: long,
      },
    }
  );
  return response;
}
