import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import MapCreator from "../components/common/CreateMap/MapCreator";
import PaperMapPreview from "../components/CreatePaperMap/PaperMapPreview";
import { DPI, Format } from "../lib/ExportSettings";
import { usePersistedState, useQueryParams } from "../lib/hooks";
import { LocalVariables } from "../lib/LocalStorageVariables";
import { PaperOrientation, PaperSize } from "../lib/PaperSettings";
import { getUserStyle } from "../services/DatasourcesService";
import { useSelector } from "react-redux";

export default function CreatePaperMapPage() {
  const { styleId } = useSelector((state) => state.style);
  const { boundingBox } = useSelector((state) => state.boundingBox);
  const { data } = useSelector((state) => state.localData);
  const { layers } = useSelector((state) => state.layers);
  const { styleName } = useSelector((state) => state.style);
  const { mapStyle } = useSelector((state) => state.style);
  // const [layers, setLayers] = usePersistedState(LocalVariables.Layers, []);
  // const [mapStyle, setMapStyle] = usePersistedState(
  //   LocalVariables.Style,
  //   process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
  // );
  const [showLayerTiles, setShowLayerTiles] = usePersistedState(
    LocalVariables.ShowLayerTiles,
    true
  );
  // const [styleName, setStyleName] = usePersistedState(
  //   LocalVariables.StyleName,
  //   "This is the Title of the Map"
  // );
  // const [mapStyleId, setMapStyleId] = usePersistedState(
  //   LocalVariables.StyleId,
  //   ""
  // );
  const [scale, setScale] = usePersistedState(LocalVariables.Scale, {
		show: true,
		unit: "metric",
		position: "bottom-left",
	});
  const [navigation, setNavigation] = usePersistedState(
    LocalVariables.Navigation,
    {
      show: true,
      position: "top-right",
    }
  );
  const [legend, setLegend] = usePersistedState(LocalVariables.Legend, {
    show: false,
    position: "bottom-right",
  });
  const [attribution, setAttribution] = usePersistedState(
    LocalVariables.Attribution,
    {
      show: false,
      customAttribution: "",
    }
  );
  const [paperSettings, setPaperSettings] = usePersistedState(
    LocalVariables.PaperSettings,
    {
      paperSize: PaperSize.A4.value,
      paperOrientation: PaperOrientation.Landscape,
    }
  );
  const [exportSettings, setExportSettings] = usePersistedState(
    LocalVariables.ExportSettings,
    {
      fileFormat: Format.PNG,
      dpi: DPI[300],
    }
  );

  const history = useHistory();
  const query = useQueryParams();
	const mapId = "map-preview";

	useEffect(() => {
		if (styleId !== "" && styleId) {
			history.push(history.location.pathname);
		}
		// if (query.get("styleId")) {
		//   getUserStyle(query.get("styleId"))
		//     .then((d) => {
		//       setLayers(d.layers);
		//       setMapStyle(d.baseStyleURL);
		//       setStyleName(d.name);
		//       setMapStyleId(d.id);
		//     })
		//     .catch((e) => history.push(history.location.pathname));
		// }
	}, []);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create Paper Map</title>
      </Helmet>
      <Box
        disableGutters
        sx={{ bgcolor: "secondary.main" }}
        style={{
          position: "relative",
          height: "calc(100vh - 50px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PaperMapPreview
          mapId={mapId}
          mapStyle={mapStyle}
          layers={layers}
          scale={scale}
          navigation={navigation}
          legend={legend}
          attribution={attribution}
          paperSettings={paperSettings}
          exportSettings={exportSettings}
        />
        <MapCreator
          mapId={mapId}
          layers={layers}
          showPaperMapActions
          setScale={setScale}
          scale={scale}
          navigation={navigation}
          setNavigation={setNavigation}
          legend={legend}
          setLegend={setLegend}
          attribution={attribution}
          setAttribution={setAttribution}
          paperSettings={paperSettings}
          setPaperSettings={setPaperSettings}
          exportSettings={exportSettings}
          setExportSettings={setExportSettings}
          showLayerTiles={showLayerTiles}
          setShowLayerTiles={setShowLayerTiles}
          styleName={styleName}
          // setLayers={setLayers}
          // setMapStyle={setMapStyle}
          // setStyleName={setStyleName}
          // setMapStyleId={setMapStyleId}
        />
      </Box>
    </React.Fragment>
  );
}
