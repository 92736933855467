/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://wmmclsedlrgchg5qkcnv6xdetm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:4c955508-6393-4d6f-a488-d41c46c2f3ab",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_vYQKwRdly",
    "aws_user_pools_web_client_id": "1mh5odjve4om3iok8a7j4re3jm",
    "oauth": {
        "domain": "mapscogndomname.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000/,https://admin.bettermaps.ai/,https://dev.admin.bettermaps.ai/,https://dev.bettermaps.ai/,https://dev.d1nbwhm6rm1eks.amplifyapp.com/,https://devadmin.bettermaps.ai/,https://staging.bettermaps.ai/",
        "redirectSignOut": "http://localhost:3000/,https://admin.bettermaps.ai/,https://dev.admin.bettermaps.ai/,https://dev.bettermaps.ai/,https://dev.d1nbwhm6rm1eks.amplifyapp.com/,https://devadmin.bettermaps.ai/,https://staging.bettermaps.ai/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
