import { Box, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { DPI, Format } from "../../../../lib/ExportSettings";
import { exportMap } from "../../../../lib/MapExporter";
import BettermapsModal from "../../BettermapsModal";
import BettermapsSelect from "../../BettermapsSelect";
import { useState } from "react";

export default function PaperExportModal(props) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [position, setPosition] = useState("bottomleft");

	const handleExport = async () => {
		try {
			props.setShow(false);
			await exportMap(
				props.exportSettings.fileFormat,
				props.paperSettings,
				props.exportSettings,
				props.map,
				position
			);
			enqueueSnackbar("Map successfully exported", {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "success",
			});
		} catch (e) {
			enqueueSnackbar(`Error: ${e.message ? e.message : e}`, {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "error",
			});
		}
	};

return (
	<BettermapsModal
		sx={{ width: "30%", minWidth: "450px" }}
		title="Export Settings"
		showModal={props.show}
		setShowModal={props.setShow}
		onDone={handleExport}
		doneText="Export">
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "center",
				mt: 2,
			}}>
			<BettermapsSelect
				sx={{ m: 1, minWidth: 120, maxWidth: 120 }}
				label="File Format"
				value={props.exportSettings.fileFormat}
				onChange={(e) =>
					props.setExportSettings((f) => {
						return { ...f, fileFormat: e.target.value };
					})
				}>
				{Object.keys(Format).map((k, i) => (
					<MenuItem key={i} value={Format[k]}>
						{Format[k].toUpperCase()}
					</MenuItem>
				))}
			</BettermapsSelect>
			<BettermapsSelect
				sx={{ m: 1, minWidth: 120, maxWidth: 120 }}
				label="DPI"
				value={props.exportSettings.dpi}
				onChange={(e) =>
					props.setExportSettings((f) => {
						return { ...f, dpi: e.target.value };
					})
				}>
				{Object.keys(DPI).map((k, i) => (
					<MenuItem key={i} value={DPI[k]}>
						{DPI[k]}
					</MenuItem>
				))}
			</BettermapsSelect>
			<BettermapsSelect
				sx={{ m: 1, minWidth: 120, maxWidth: 150 }}
				label="Logo position"
				value={position}
				onChange={(e) => setPosition(e.target.value)}>
				<MenuItem value="bottomleft">Bottom Left</MenuItem>
				<MenuItem value="bottomright">Bottom Right</MenuItem>
				<MenuItem value="topleft">Top Left</MenuItem>
				<MenuItem value="topright">Top Right</MenuItem>
			</BettermapsSelect>
		</Box>
	</BettermapsModal>
);
}
