import * as React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { v4 as randomID } from "uuid";
import { LayerType } from "../../../../lib/LayerType";
import { LayerFileType } from "../../../../lib/LayerFileType";
import { useState, useEffect } from "react";
import { useColor } from "../../../../lib/hooks";
import BettermapsTextField from "../../BettermapsTextField";
import { UploadKMLToS3 } from "../../../../services/DigitalMapService";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import BettermapsModal from "../../BettermapsModal";
import BettermapsColorPicker from "../../BettermapsColorPicker";
import { getLayerFeatures } from "../../../../services/DatasourcesService";
import { useDispatch } from "react-redux";
import { addLayer } from "../../../../redux/slices/layers";
import MapBoxSource from "../../MapBox/MapBoxSource";
import { useSelector } from "react-redux";

export default function KMLModal(props) {
	const dispatch = useDispatch();

	const [layerName, setLayerName] = useState("");
	const [fillColor, setFillColor] = useColor("#cd0000");
	const [lineColor, setLineColor] = useColor("#02c100");
	const [layerType, setLayerType] = useState(LayerType.Circle);
	const [zoomOnLoad, setZoomOnLoad] = useState(true);
	const [KMLFileName, setKMLFileName] = useState("");
	const [KMLFileError, setKMLFileError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [KMLFile, setKMLFile] = useState();
	const [selectColor, setSelectColor] = useState("random");
	const [isChosen, setIsChosen] = useState(false);
	const [collection, setCollection] = useState([]);
	const userData = useSelector((state) => state.user);

	var boundingBox = [];

	const FIVE_MEGABYTES = 5 * Math.pow(1024, 2);

	const zoomToBoundingBox = (boundingBox) => {
		props.map.getMap().fitBounds(boundingBox);
	};

	const theme = useTheme();
	const [layerVisibility, setLayerVisibility] = useState("public");

	const handleChange = (event) => {
		setSelectColor(event.target.value);
	};


	const handleAddKMLLayer = async () => {
		try {
			setIsLoading(true);
			window.analytics.track("addKML.click");
			const user = await Auth.currentAuthenticatedUser();
			const ownerId = user.username;
			const response = await UploadKMLToS3(KMLFile, ownerId);
			if (response.data.body === "KML format not valid.") {
				enqueueSnackbar(`KML format not valid`, {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				});
				closeKML();
				return;
			}
			if (response.data.url && response.data.boundingBox) {
				addFeatures(response.data.url);
				if (zoomOnLoad) zoomToBoundingBox(response.data.boundingBox);
				boundingBox = response.data.boundingBox;
			} else {
				enqueueSnackbar(`Error Uploading your KML, please verify the file`, {
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				});
			}
			closeKML();
		} catch (error) {
			// handle error
		} finally {
			setIsLoading(false);
		}
	};

	const closeKML = () => props.setShowKMLModal(false);

	const addFeatures = async (KMLFileURL) => {
		const layerFeatures = await getLayerFeatures(KMLFileURL);
		const geometryType = getGeometryType(layerFeatures);

		addMoreFeatures(KMLFileURL, geometryType);
	};

	const getGeometryType = (layerFeatures) => {
		const geometry = layerFeatures.features[0].geometry;

		if (
			geometry.type.toLowerCase() === "polygon" ||
			geometry.type.toLowerCase() === "multipolygon"
		) {
			return LayerType.Fill;
		} else if (geometry.type.toLowerCase() === "point") {
			return LayerType.Circle;
		} else if (geometry.type.toLowerCase() === "line") {
			return LayerType.Line;
		}
	};

	const addMoreFeatures = async (KMLFileURL, geometryType) => {
		const typeColor =
			geometryType.value === LayerType.Line.value
				? `rgba(${lineColor.rgb.r},${lineColor.rgb.g},${lineColor.rgb.b},${lineColor.rgb.a})`
				: `rgba(${fillColor.rgb.r},${fillColor.rgb.g},${fillColor.rgb.b},${fillColor.rgb.a})`;

		const layerFeatures = await getLayerFeatures(KMLFileURL);
		const mapboxSource = await MapBoxSource(
			layerFeatures,
			selectColor,
			typeColor
		);
		addKMLLayer(mapboxSource, geometryType, KMLFileURL);
	};

	function addKMLLayer(data, geometryType, URL) {
		const layerID = `bettermaps-layer-${randomID()}`;
		const sourceID = randomID();
		const newLayer = {
			id: layerID,
			name: layerName,
			fileType: LayerFileType.GeoJSON,
			type: geometryType,
			show: true,
			// position: layers.length + 1,
			fillColor: fillColor.rgb,
			lineColor: lineColor.rgb,
			boundingbox: boundingBox,
			selectColor: selectColor,
			featureColors: data.featureColors,
			urlSource: URL,
			opacity: geometryType.value == "line" ? lineColor.rgb.a : fillColor.rgb.a,
			source: {
				id: sourceID,
				type: "geojson",
				data: data.data,
			},
			showOnHover: false,
			hoverProperties: [],
		};
		dispatch(addLayer(newLayer));
		// props.setLayers((layers) => {
		//   const lastLayer = layers[0];
		//   if (lastLayer) lastLayer.beforeId = layerID;
		//   return [
		//     ...layers,
		//     {
		//       id: layerID,
		//       name: layerName,
		//       fileType: LayerFileType.GeoJSON,
		//       type: GeometryType,
		//       show: true,
		//       position: layers.length + 1,
		//       fillColor: fillColor.hex,
		//       lineColor: lineColor.hex,
		//       boundingbox: boundingBox,
		//       opacity:
		//         GeometryType.value == "line" ? lineColor.rgb.a : fillColor.rgb.a,
		//       source: {
		//         id: sourceID,
		//         type: "geojson",
		//         data: KMLFileURL,
		//       },
		//       showOnHover: false,
		//       hoverProperties: [],
		//     },
		//   ];
		// });
	}

	const handleKMLFileSelected = (e) => {
		setKMLFileError("");
		const selectedKMLFile = e.currentTarget.files[0];
		const extension = selectedKMLFile.name.split(".").pop();

		if (selectedKMLFile.size <= FIVE_MEGABYTES && extension === "kml") {
			const fileReader = new FileReader();

			fileReader.onload = (e) => {
				setKMLFileName(selectedKMLFile.name);
				setLayerName(selectedKMLFile.name);
				setKMLFile(selectedKMLFile);
			};

			fileReader.readAsText(selectedKMLFile, "UTF-8");

		} else {


			enqueueSnackbar(
				"KML can't be greater than 5MB or type is not supported",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}

			);
		}
	};

	return (
		<BettermapsModal
			accept=".kml"
			sx={{ width: "60%", maxWidth: "700px" }}
			title="Add KML Layer"
			showModal={props.showKMLModal}
			setShowModal={props.setShowKMLModal}
			onDone={handleAddKMLLayer}
			doneText="Add"
			doneDisabled={isLoading || KMLFile == undefined}>
			<Grid container direction="column">
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
					sx={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: "1rem",
					}}>
					<BettermapsTextField
						style={{ width: "80%" }}
						label="KML Layer name"
						value={layerName}
						onChange={(e) => {
							setLayerName(e.target.value),
								analytics.identify(`click input KML Layer: ${e.target.value}`, {
									id: userData.ownerId,
									subscription: userData.subscription,
								});
						}}
						accept="application/vnd.google-earth.kml+xml"></BettermapsTextField>
					<Button
						variant="contained"
						component="label"
						sx={{ borderRadius: 1, width: 70, height: 40 }}>
						File
						<input
							type="file"
							hidden
							onChange={handleKMLFileSelected}
							onClick={() =>
								analytics.identify("click on save KML as file", {
									id: userData.ownerId,
									subscription: userData.subscription,
								})
							}
						/>
					</Button>
				</Box>
				<Box
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						flexWrap: "wrap",
					}}>
					<FormControl>
						<RadioGroup
							aria-labelledby="demo-controlled-radio-buttons-group"
							name="controlled-radio-buttons-group"
							value={selectColor}
							onChange={handleChange}>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									flexWrap: "wrap",
								}}>
								<Box>
									<FormControlLabel
										value="unique"
										control={<Radio />}
										label="Unique Color"
									/>
								</Box>

								<Box>
									<FormControlLabel
										value="random"
										control={<Radio />}
										label="Random Color"
									/>
								</Box>
							</Box>
						</RadioGroup>
					</FormControl>
				</Box>

				{selectColor == "unique" && (
					<Box
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							// marginRight: "40px",
							// marginTop: "10px",
						}}>
						<Box
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								marginRight: "20px",
								marginTop: "10px",
							}}>
							<Typography>
								{layerType.value == LayerType.Line.value
									? "Line color"
									: "Outline color"}
							</Typography>
							<Box style={{ marginLeft: "10px" }}>
								<BettermapsColorPicker
									color={lineColor}
									setColor={setLineColor}
									disableAlpha={layerType.value != LayerType.Line.value}
								/>
							</Box>
						</Box>
						{layerType.value != LayerType.Line.value && (
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									marginRight: "40px",
									marginTop: "10px",
								}}>
								<Typography>Fill color</Typography>

								<Box style={{ marginLeft: "10px" }}>
									<BettermapsColorPicker
										color={fillColor}
										setColor={setFillColor}
										disableAlpha={layerType.value == LayerType.Line.value}
									/>
								</Box>
							</Box>
						)}
					</Box>
				)}

				{/* <ToggleButtonGroup
            disabled
            style={{
              backgroundColor: theme.palette.secondary.main,
              width: "fit-content",
              marginTop: "10px",
            }}
            value={layerVisibility}
            size="small"
            exclusive
            onChange={(e, a) => setLayerVisibility(a)}
          > */}
				{/* <ToggleButton value="owned">
              <FavoriteBorderIcon sx={{ color: "white" }} />
            </ToggleButton>
            <ToggleButton value="public">
              <LockOpenIcon sx={{ color: "white" }} />
            </ToggleButton>
            <ToggleButton value="private">
              <LockOutlinedIcon sx={{ color: "white" }} />
            </ToggleButton>
          </ToggleButtonGroup> */}
			</Grid>

			<Box
				display="flex"
				flexDirection="row"
				justifyContent="center"
				marginTop="10px">
				<CircularProgress
					style={{ display: isLoading ? "block" : "none" }}
					color="primary"
				/>
			</Box>
			<FormControlLabel
				style={{
					position: "absolute",
					left: "32px",
					bottom: "32px",
				}}
				control={
					<Checkbox
						checked={zoomOnLoad}
						onChange={() => setZoomOnLoad(!zoomOnLoad)}
					/>
				}
				label="Zoom on load"
			/>
		</BettermapsModal>
	);
}
