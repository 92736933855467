import { Box } from "@mui/material";
import CurrentZoomLevel from "../common/MapBox/CurrentZoomLevel";
import MapboxMap from "../common/MapboxMap";
import ZoomViews from "../common/ZoomViews";
import EditFeatureColorModal from "../common/CreateMap/modals/editLayer/EditFeatureColorModal";

import {
  AttributionControl,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import BettermapsOverlay from "../common/MapBox/BettermapsOverlay";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

export default function DigitalMapPreview(props) {
  const [showEditFeatureColorModal, setShowEditFeatureColorModal] = useState({
    show: false,
    layer: null,
    feature: null,
  });

  const openEditFeature = (layer, feature) => {
    setShowEditFeatureColorModal({
      show: true,
      layer: layer,
      feature: feature,
    });
  };

  return (
		<Box
			data-testid="create_map_id"
			style={{
				position: "relative",
				height: "calc(100vh - 50px)",
				width: "100%",
				borderStyle: "solid",
				borderColor: props.onEditMode ? "red" : "white",
			}}>
			<ZoomViews
				onEditMode={props.onEditMode}
				mapStyle={props.mapStyle}
				layers={props.layers}
				right={50}
				top={62}
			/>
			{showEditFeatureColorModal.show && (
				<EditFeatureColorModal
					show={showEditFeatureColorModal}
					setShow={setShowEditFeatureColorModal}
				/>
			)}

			<MapboxMap
				id={props.mapId}
				openEditFeature={openEditFeature}
				layers={props.layers}
				latitude="40.0"
				longitude="-3"
				zoom="5"
				height="calc(100vh - 54px)"
				width="100%"
				mapStyle={props.mapStyle}
				content={
					<React.Fragment>
						<BettermapsOverlay
							top={"calc(100vh - 120px)"}
							right={"calc(100vw - 227px)"}></BettermapsOverlay>
						<CurrentZoomLevel
							mapId={props.mapId}
							top={10}
							right={props.onEditMode ? 235 : 50}
							decimals={2}></CurrentZoomLevel>
						{props.scale && props.scale.show && (
							<ScaleControl
								key={props.scaleKey}
								unit={props.scale.unit}
								position={props.scale.position}
							/>
						)}
						{props.navigation && props.navigation.show && (
							<NavigationControl position={props.navigation.position} />
						)}
						{props.attribution && props.attribution.show && (
							<AttributionControl
								customAttribution={props.attribution.customAttribution}
								compact={true}
							/>
						)}
					</React.Fragment>
				}
			/>
		</Box>
	);
}
