import { useState, useEffect } from "react";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ActionButton from "../ActionButton";
import BettermapsTextField from "../BettermapsTextField";
import CheckableActionButton from "../CheckableActionButton";
import { useSelector, useDispatch } from "react-redux";
import { setStyleName } from "../../../redux/slices/style";
import { useLocation } from "react-router-dom";

export default function ActionsMenu(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const data = location.state?.data;
	const theme = useTheme();
	const { styleName } = useSelector((state) => state.style);
	const [inputValue, setInputValue] = useState("");
	const userData = useSelector((state) => state.user);

	const resetInputValue = () => {
		setInputValue("");
	};

	useEffect(() => {
		if (props.cleanMapName) {
			setInputValue("");
		}
	}, [props.cleanMapName]);

	useEffect(() => {
		setInputValue(styleName || data);
	}, []);

	const toButton = (action) => {
		if (action.type == "CHECKABLE") {
			return (
				<CheckableActionButton
					disabled={action.disabled}
					title={action.title}
					icon={action.icon}
					onClick={action.onClick}
					onCheckChange={action.onCheckChange}
					checked={action.checked}
				/>
			);
		} else if (action.type == "NON-CHECKABLE") {
			if (action.icon) {
				return (
					<ActionButton
						title={action.title}
						disabled={action.disabled}
						icon={action.icon}
						onClick={action.onClick}
						variant={action.variant}
					/>
				);
			} else {
				return (
					<Button
						variant="contained"
						disabled={action.disabled}
						title={action.title}
						onClick={action.onClick}
						sx={{ borderRadius: 1, pointerEvents: "fill" }}>
						<Typography variant="button">{action.title}</Typography>
					</Button>
				);
			}
		}
	};

	return (
		<Stack spacing={1}>
			<Box direction="row" display="flex" flexWrap="wrap">
				{props.actions.map((action, index) => (
					<Box
						hidden={action.hidden}
						key={index}
						sx={{
							mr: action.icon ? 1 : 2,
							mb: "6px",
						}}
						onClick={() =>
							analytics.identify(`click on Create map view: ${action.title}`, {
								id: userData.ownerId,
								subscription: userData.subscription,
							})
						}>
						{toButton(action)}
					</Box>
				))}

				{props.showPaperMapActions &&
					props.paperMapActions &&
					props.paperMapActions.map((action, index) => (
						<Box hidden={action.hidden} key={index} sx={{ mr: 1, mb: "6px" }}>
							{toButton(action)}
						</Box>
					))}
			</Box>
			<Grid
				item
				xl={3}
				lg={3}
				md={4}
				sm={5}
				xs={5}
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-end",
					marginTop: "6px",
					marginBottom: "5px",
				}}>
				<BettermapsTextField
					data-testid="input_map_title_id"
					label="This is the Title of the Map"
					value={inputValue}
					onChange={(e) => {
						setInputValue(e.target.value);
						dispatch(setStyleName(e.target.value));
						analytics.identify(`input Map Title: ${e.target.value}`, {
							id: userData.ownerId,
							subscription: userData.subscription,
						});
					}}
					style={{ backgroundColor: "white" }}></BettermapsTextField>
				<ActionButton
					sx={{ ml: 1 }}
					variant={props.showLayerTiles ? "filled" : "contained"}
					title={
						props.showLayerTiles ? "Hide Added Layers" : "Show Added Layers"
					}
					onClick={() => {
						props.setShowLayerTiles(!props.showLayerTiles),
							analytics.identify("click on Hide Added Layers", {
								id: userData.ownerId,
								subscription: userData.subscription,
							});
					}}
					icon={
						props.showLayerTiles ? (
							<MenuOpenIcon sx={{ color: "white" }} />
						) : (
							<MenuIcon sx={{ color: theme.palette.primary.main }} />
						)
					}></ActionButton>
				<ActionButton
					sx={{ ml: 1 }}
					title={props.styleSaved ? "Saved style" : "Save style"}
					onClick={() => {
						props.openSaveStyleModal(), resetInputValue();
					}}
					disabled={props.styleSaved}
					icon={
						props.styleSaved ? (
							<CloudDoneIcon sx={{ color: "white" }} />
						) : (
							<SaveIcon sx={{ color: "white" }} />
						)
					}
					variant="filled"></ActionButton>
			</Grid>
		</Stack>
	);
}
