import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { deleteUserLayer } from "../../../../services/DatasourcesService";
import { useSnackbar } from "notistack";

export default function DeleteLayerModal(props) {
	const userData = useSelector((state) => state.user);

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const handleDeleteLayer = async () => {
		try {
			await deleteUserLayer(props.layerToDeleteId);
			props.removeLayerFromTable();
			enqueueSnackbar("Layer deleted", {
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
				variant: "success",
			});
			props.setShow(false);
		} catch (e) {
			console.error(e);
			enqueueSnackbar(
				"There was an error when deleting your layer. Please try later.",
				{
					anchorOrigin: {
						vertical: "top",
						horizontal: "center",
					},
					variant: "error",
				}
			);
		}
	};

	return (
		<Dialog
			open={props.show}
			onClose={() => props.setShow(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">
				{"Are you sure you want to delete the layer?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Upon confirming, the layer will be deleted. This action cannot be
					undo.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						props.setShow(false),
							analytics.identify("click on Create Map cancel", {
								id: userData?.ownerId,
								subscription: userData?.subscription,
							});
					}}
					autoFocus>
					Cancel
				</Button>
				<Button color="error" onClick={handleDeleteLayer}>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
