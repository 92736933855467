import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import ActionButton from "../ActionButton";
import LongMenu from "./DataLayerTileMenu";
import { dynamicBeforeId } from "../MapBox/MapBoxSource";
import { useDispatch, useSelector } from "react-redux";
import {
  setlayers,
  showLayer,
  addBeforeId,
  resetLayers,
  eraseLayer,
} from "../../../redux/slices/layers";

export default function DataLayerTile(props) {
  const { layers } = useSelector((state) => state.layers);
  const userData = useSelector((state) => state.user);
  if (layers.length > 1) {
    if (layers[0].id == props.layer.id) {
      var canMoveUp = false;
      var canMoveDown = true;
    } else if (layers[layers.length - 1].id == props.layer.id) {
      var canMoveUp = true;
      var canMoveDown = false;
    } else {
      var canMoveUp = true;
      var canMoveDown = true;
    }
  } else {
    var canMoveUp = false;
    var canMoveDown = false;
  }

  const dispatch = useDispatch();
  const handleShowLayer = (id) => {
    const objIndex = layers.findIndex((obj) => obj.id == id);
    dispatch(showLayer(objIndex));
  };

  const handleMoveUp = (id) => {
    const objIndex = layers.findIndex((obj) => obj.id == id);
    var newLayers = [...layers];
    const currentBeforeId = newLayers[objIndex].beforeId;
    const aboveBeforeId = newLayers[objIndex - 1].beforeId;
    var movingObject = [];
    // newLayers[objIndex].beforeId = newLayers[objIndex - 1].beforeId;
    movingObject = newLayers.splice(objIndex, 1);
    newLayers.splice(objIndex - 1, 0, movingObject[0]);
    dispatch(resetLayers());
    function deletetimer() {
      dispatch(setlayers(newLayers));
    }
    setTimeout(deletetimer, 1);
  };

  const handleMoveDown = (id) => {
    var newLayers = [...layers];
    const objIndex = newLayers.findIndex((obj) => obj.id == id);
    var movingObject = [];
    movingObject = newLayers.splice(objIndex, 1);
    newLayers.splice(objIndex + 1, 0, movingObject[0]);
    dispatch(resetLayers());
    function deletetimer() {
      dispatch(setlayers(newLayers));
    }
    setTimeout(deletetimer, 1);
  };
  return (
		<Grid
			container
			display="flex"
			direction="row"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				bgcolor: "white",
				borderStyle: "solid",
				borderColor: props.layer.type ? props.layer.type.color : "",
				borderRadius: 1,
				px: "4px",
				mt: "3px",
				mb: "3px",
			}}
			style={{ pointerEvents: "fill" }}>
			{props.layer.id != "Background" ? (
				<Stack spacing="2px" sx={{ pr: "5px", pl: "2px" }}>
					<Button
						disabled={!canMoveUp}
						sx={{
							height: 15,
							bgcolor: canMoveUp ? "primary.main" : "secondary.main",
							maxWidth: 30,
							minWidth: 30,
							"&:hover": {
								backgroundColor: "primary.main",
							},
						}}
						onClick={() => handleMoveUp(props.layer.id)}>
						<KeyboardArrowUpIcon sx={{ color: "white" }} />
					</Button>
					<Button
						disabled={!canMoveDown}
						sx={{
							height: 15,
							bgcolor: canMoveDown ? "primary.main" : "secondary.main",
							maxWidth: 30,
							minWidth: 30,
							"&:hover": {
								backgroundColor: "primary.main",
							},
						}}
						onClick={() => handleMoveDown(props.layer.id)}>
						<KeyboardArrowDownIcon sx={{ color: "white" }} />
					</Button>
				</Stack>
			) : null}
			{props.layer.id != "Background" ? (
				<ActionButton
					title="Show Layer"
					disabled={props.layer.id === "Background"}
					onClick={() => {
						handleShowLayer(props.layer.id);
					}}
					sx={{ pr: "5px", pl: "2px" }}
					icon={
						<VisibilityIcon
							sx={{
								color:
									props.layer.show && props.layer.id !== "Background"
										? "primary.main"
										: "secondary.main",
							}}
						/>
					}></ActionButton>
			) : null}
			<Tooltip
				sx={{ pr: "2px", pl: "2px", ml: "2px" }}
				title={props.layer.name}
				enterDelay={1000}>
				<Typography
					align={props.layer.id != "Background" ? "left" : "center"}
					noWrap
					style={{ flex: 1 }}
					sx={{ color: "text.secondary", maxWidth: "328px", ml: "5px" }}>
					{props.layer.name === "Background Layer"
						? "Background"
						: props.layer.name + " | " + props.layer.location >= 10
						? props.layer.location.slice(0, 10) + "..."
						: props.layer.name + " | " + props.layer.location}
				</Typography>
			</Tooltip>

			{props.layer.id != "Background" ? (
				<LongMenu props={props} />
			) : (
				<div>
					<ActionButton
						title="Edit Layer"
						disabled={!props.canEdit}
						onClick={() => {
							props.onEdit(),
								analytics.identify("click on edit layer", {
									id: userData.ownerId,
									subscription: userData.subscription,
								});
						}}
						sx={{ pr: "2px", pl: "2px" }}
						icon={
							<EditIcon
								sx={{
									color: props.canEdit ? "primary.main" : "secondary.main",
								}}
							/>
						}></ActionButton>
				</div>
			)}
		</Grid>
	);
}
