import {
  Box,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { countInfo } from "../services/StripeService";
import { Auth } from "aws-amplify";
import { setPlanInfo } from "../redux/slices/user";

export default function StripeLandingPage() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { email } = useSelector((state) => state.user);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getCountInfo = async (email) => {
			const c = await countInfo(email);
			dispatch(setPlanInfo(c));
		};

		const fetchCountInfo = async () => {
			if (email) {
				await getCountInfo(email);
			} else {
				const user = await Auth.currentAuthenticatedUser();
				await getCountInfo(user.attributes.email);
			}
		};

		fetchCountInfo();

		setTimeout(() => {
			history.push("dashboard");
		}, 6000);
	});

return (
	<Container sx={{ paddingTop: "32px", width: "90%", height: "90%" }}>
		<Box sx={{ display: "flex", justifyContent: "center" }}>
			{loading && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<CircularProgress></CircularProgress>
					<Typography>Redirecting</Typography>
				</Box>
			)}
		</Box>
	</Container>
);
}
