import { useState } from "react";
import {
  AttributionControl,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import CurrentZoomLevel from "../components/common/MapBox/CurrentZoomLevel";
import MapboxMap from "../components/common/MapboxMap";
import MAP_STYLE from "../data/map-style-basic-v8.json";
import generateColorRandom from "../helpers/generateColorRandom";

export default function ExploreMapPage() {
  const mapStyleInit = {
    ...MAP_STYLE,
    sources: { ...MAP_STYLE.sources },
    layers: MAP_STYLE.layers.slice(),
  };

  const [mapstyle, setMapstyle] = useState(mapStyleInit);

  const mapId = "explore-map";

  mapStyleInit.sources["sf-neighborhoods"] = {
    type: "geojson",
    data: "https://raw.githubusercontent.com/uber/react-map-gl/master/examples/.data/feature-example-sf.json",
  };

  mapStyleInit.sources["spain"] = {
    type: "geojson",
    data: "/data/geojson/spain-communities.geojson",
  };

  mapStyleInit.layers.push(
		{
			id: "sf-neighborhoods-fill",
			source: "spain",
			type: "fill",
			paint: {
				"fill-outline-color": "#0040c8" || generateColorRandom(),
				"fill-color": "#fff" || generateColorRandom(),
				"fill-opacity": 0 || 1,
			},
		},
		{
			id: "sf-neighborhoods-outline",
			source: "spain",
			type: "line",
			paint: {
				"line-width": 2,
				"line-color": "#0080ef" || generateColorRandom(),
			},
		}
	);

  const navControlStyle = {
    right: 10,
    top: 10,
  };

  const attributionStyle = {
    left: 0,
    top: 0,
  };

  const geolocateControlStyle = {
    right: 10,
    top: 100,
  };

  const scaleControlStyle = {
    right: 20,
    bottom: 10,
  };

  const buttonControlStyle = {
    left: 10,
    top: 100,
  };

  function addGeojson() {
    const name = "italy-provinces";
    const mydata = "/data/geojson/" + name + ".geojson";
    mapStyleInit.sources[name] = {
      type: "geojson",
      data: mydata,
    };
    mapStyleInit.layers.push(
      {
        id: "france-fill",
        source: name,
        type: "fill",
        paint: {
          "fill-outline-color": "#00ffff" || generateColorRandom(),
          "fill-color": "#fff" || generateColorRandom(),
          "fill-opacity": 0.5 || 1,
        },
      },
      {
        id: "france-outline",
        source: name,
        type: "line",
        paint: {
          "line-width": 2,
          "line-color": "#ff0000" || generateColorRandom(),
        },
      }
    );
    setMapstyle(mapStyleInit);
  }

  return (
    <MapboxMap
      id={mapId}
      latitude="40.0"
      longitude="-3"
      zoom="5"
      height="calc(100vh - 50px)"
      mapStyle={mapstyle}
      content={
        <React.Fragment>
          <CurrentZoomLevel mapId={mapId} right={20} bottom={20} />

          <NavigationControl style={navControlStyle} />
          <AttributionControl compact={true} style={attributionStyle} />
          {/* <BettermapsOverlay /> */}
          {/* <ButtonMapControl style={buttonControlStyle} onClick={addGeojson} /> */}
          <ScaleControl
            maxWidth={100}
            unit="metric"
            style={scaleControlStyle}
          />
        </React.Fragment>
      }
    />
  );
}
