import {
  Container,
  FormControlLabel,
  Switch,
  Box,
  TextField,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useMap } from "react-map-gl";
import { useHistory } from "react-router-dom";
import MapCreator from "../components/common/CreateMap/MapCreator";
import DigitalMapPreview from "../components/CreateDigitalMap/DigitalMapPreview";
import { usePersistedState, useQueryParams } from "../lib/hooks";
import { LocalVariables } from "../lib/LocalStorageVariables";
import { getUserStyle } from "../services/DatasourcesService";
import { useSelector, useDispatch } from "react-redux";
import { setMapId } from "../redux/slices/map";
import { resetBoundingBox } from "../redux/slices/boundingBox";
import { useSnackbar } from "notistack";
import { Auth } from "aws-amplify";
import { setPlanInfo } from "../redux/slices/user";
import { countInfo } from "../services/StripeService";
import Geocoder from "../components/CreateDigitalMap/Geocoder";


export default function CreateDigitalMapPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { subscription } = useSelector((state) => state.user);
	const { styleId } = useSelector((state) => state.style);
	const { boundingBox } = useSelector((state) => state.boundingBox);
	const { data } = useSelector((state) => state.localData);
	const { layers } = useSelector((state) => state.layers);
	// const { styleName } = useSelector((state) => state.style);
	const { mapStyle } = useSelector((state) => state.style);
	const { styleName } = useSelector((state) => state.style);
	const userData = useSelector((state) => state.user);
	// const [layers, setLayers] = usePersistedState(state, []);
	// const [mapStyle, setMapStyle] = usePersistedState(
	//   LocalVariables.Style,
	//   process.env.REACT_APP_S3_PUBLIC_STYLES_PREFIX_URL + "basic.json"
	// );
	const [showLayerTiles, setShowLayerTiles] = usePersistedState(
		data.ShowLayerTiles,
		true
	);
	// const [styleName, setStyleName] = usePersistedState(
	//   data.StyleName,
	//   "This is the Title of the Map"
	// );
	// const [mapStyleId, setMapStyleId] = usePersistedState(data.StyleId, "");

	// const [mapBoundingBox, setMapBoundingBox] = usePersistedState(
	//   data.MapBoundingBox,
	//   null
	// );

	const [scale, setScale] = usePersistedState(data.Scale, {
		show: true,
		unit: "metric",
		position: "bottom-right",
	});
	const [navigation, setNavigation] = usePersistedState(data.Navigation, {
		show: true,
		position: "top-right",
	});
	const [legend, setLegend] = usePersistedState(data.Legend, {
		show: false,
		position: "bottom-right",
	});
	const [attribution, setAttribution] = usePersistedState(data.Attribution, {
		show: false,
		customAttribution: "",
	});

	const [onEditMode, setOnEditMode] = useState(true);

	useEffect(() => {
		const checkSubscription = async () => {
			if (subscription) {
				if (
					subscription != "standard" &&
					subscription != "explorer" &&
					subscription != "pro"
				) {
					enqueueSnackbar(
						"To use Bettermaps you need to choose a subscription plan",
						{
							anchorOrigin: {
								vertical: "top",
								horizontal: "center",
							},
							variant: "error",
						}
					);
					history.push("/subscriptions");
				}
			} else {
				try {
					const user = await Auth.currentAuthenticatedUser();
					const c = await countInfo(user.attributes.email);
					if (c.plan == "standard" || c.plan == "explorer" || c.plan == "pro") {
						dispatch(setPlanInfo(c));
						// history.push("/dashboard");
					} else {
						history.push("/subscriptions");
					}
				} catch (error) {
					console.log(error), "Error	from CheckSubscriptions";
				}
			}
		};

		checkSubscription();
	}, []);

	const queryParams = useQueryParams();
	const maps = useMap();

	const mapId = "map-preview";

	useEffect(() => {
		if (mapId in maps && styleId && boundingBox && boundingBox.length > 0) {
			maps[mapId].getMap().fitBounds(boundingBox);
		}
	}, [maps]);

	const flyTo = (s) => {
		if (mapId in maps) {
			if (s.bbox) {
				maps[mapId].getMap().fitBounds(s.bbox);
			} else if (s.center) {
				maps[mapId].getMap().flyTo({ center: s.center, zoom: 16 });
			}
		}
	};

	return (
		<React.Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Create Paper Map</title>
			</Helmet>
			<Container
				disableGutters
				style={{ maxWidth: "100%", position: "relative" }}>
				{/* <FormControl
          style={{
            position: "absolute",
            top: "-45px",
            right: "300px",
            zIndex: 9999,
            color: "white",
          }}
          // value="end"
        > */}
				<Geocoder flyTo={flyTo} onEditMode={onEditMode} />
				{/* </FormControl> */}
				<FormControlLabel
					style={{
						position: "absolute",
						top: "-45px",
						right: "80px",
						zIndex: 9999,
						color: "white",
					}}
					value="end"
					control={
						<Switch
							color="error"
							checked={onEditMode}
							onChange={() => {
								setOnEditMode(!onEditMode),
									analytics.identify("click on Edit mode", {
										id: userData.ownerId,
										subscription: userData.subscription,
									});
							}}
						/>
					}
					label={onEditMode ? "Edit" : "Presentation"}
					labelPlacement="start"
				/>
				<DigitalMapPreview
					mapId={mapId}
					mapStyle={mapStyle}
					layers={layers}
					scale={scale}
					navigation={navigation}
					legend={legend}
					attribution={attribution}
					onEditMode={onEditMode}
				/>
				{onEditMode && (
					<MapCreator
						mapId={mapId}
						layers={layers}
						// setLayers={setLayers}
						mapStyle={mapStyle}
						// setMapStyle={setMapStyle}
						showLayerTiles={showLayerTiles}
						setShowLayerTiles={setShowLayerTiles}
						styleName={styleName}
						// setStyleName={setStyleName}
						// setMapStyleId={setMapStyleId}
						// setMapBoundingBox={setMapBoundingBox}
						setScale={setScale}
						scale={scale}
						navigation={navigation}
						setNavigation={setNavigation}
						legend={legend}
						setLegend={setLegend}
						attribution={attribution}
						setAttribution={setAttribution}
					/>
				)}
			</Container>
		</React.Fragment>
	);
}
